import React, { useEffect, useState } from 'react'

function OtpCard({ otpValue, setOtpValue }) {
    const array = Array(4).fill(null)
    const ref = Array.from({ length: 4 }, () => React.createRef())

    const handleOtp = (e, index) => {
        let value = e.target.value
        if (!(new RegExp('^[0-9]$').test(value))) value = "";
        setOtpValue((prev) => {
            let updateOtpValue = [...prev]
            updateOtpValue[index] = value
            if (value && index < array.length - 1) {
                ref[index + 1].current?.focus();
            }
            return updateOtpValue.join('')
        })
    }

    const handleBackSpace = (e, index) => {
        if (["e", "E", "+", "-"].includes(e.key)) e.preventDefault()
        if (e.key === 'Backspace' && index > 0 && !otpValue[index]) {
            const focusInputRef = ref[index - 1]
            if (focusInputRef && focusInputRef.current) {
                focusInputRef.current.focus()
            }
        }
    }

    useEffect(() => {
        if (!otpValue[0] && ref[0].current) {
            ref[0].current.focus();
        }
    }, [otpValue]);

    return (
        <div className='otp-validate'>
            {array.map((_, index) => {
                return (
                    <input
                        onChange={(e) => handleOtp(e, index)}
                        onKeyDown={(e) => handleBackSpace(e, index)}
                        key={index}
                        type='tel'
                        inputMode='numeric'
                        value={otpValue?.[index] || ''}
                        ref={ref[index]}
                        maxLength={1}
                        className='form-control'
                    />
                )
            })}
        </div>
    )
}

export default OtpCard