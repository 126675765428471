/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
// import epaper from '../../assets/epaper.jpg'
import epaper from '../../../assets/sandeshEPaper.png';
import { useSelector, useDispatch } from 'react-redux';
import HomeTaboola from '../../subcomponents/HomeTaboola';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { ePaperImageFetchData } from '../../../redux/actions/EPaperImageAction';
import { Link } from 'react-router-dom';
import HelperScript from '../../../utils/helper';
import ReactGA from 'react-ga4';
import InjectScript from '../../CustomScript';
import { epaperCategoryClear } from '../../../redux/actions/EPaperCategory';

const Epaper = () => {
  const ePaperMenuData = useSelector(
    (state) => state?.ePaperMenuReducer?.data?.data
  );
  const ePaperImageData = useSelector(
    (state) => state?.ePaperImageReducer?.data
  );

  const { 21: assetbaseUrl } = useSelector(
    (state) => state?.SettingReducer?.data
  );

  // const { 103: epaperleft } = useSelector((state) => state?.widgetReducer?.data);
  // const { 104: epaperRight } = useSelector((state) => state?.widgetReducer?.data);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(ePaperImageFetchData());
    dispatch(epaperCategoryClear());
  }, []);

  const aftercity =
    '' +
    "<div id='div-gpt-ad-1657960450250-0' style='min-width: 300px; min-height: 250px;'>" +
    '<script>' +
    'window.googletag = window.googletag || {cmd: []};' +
    'googletag.cmd.push(function() {' +
    "googletag.defineSlot('/22678428348/Mweb_Epaper_Aftereverycity_300X250', [300, 250], 'div-gpt-ad-1657960450250-0').addService(googletag.pubads());" +
    'googletag.pubads().enableSingleRequest();' +
    'googletag.enableServices();' +
    '});' +
    '</script>' +
    '' +
    '<script>' +
    "googletag.cmd.push(function() {googletag.display('div-gpt-ad-1657960450250-0'); });" +
    '</script>' +
    '</div>' +
    '' +
    '';
  const supplementafter3 =
    '' +
    "<div id='div-gpt-ad-1657962041463-0' style='min-width: 300px; min-height: 250px;'>" +
    '<script>' +
    'window.googletag = window.googletag || {cmd: []};' +
    'googletag.cmd.push(function() {' +
    "googletag.defineSlot('/22678428348/Mweb_Epaper_Insupplement_300x250', [300, 250], 'div-gpt-ad-1657962041463-0').addService(googletag.pubads());" +
    'googletag.pubads().enableSingleRequest();' +
    'googletag.enableServices();' +
    '});' +
    ' </script>' +
    '' +
    '<script>' +
    "googletag.cmd.push(function() {googletag.display('div-gpt-ad-1657962041463-0'); });" +
    '</script>' +
    '</div>' +
    '' +
    '';
  const ahmedabadafter2 =
    '' +
    "<div id='div-gpt-ad-1657962640252-0' style='min-width: 300px; min-height: 250px;'>" +
    '<script>' +
    'window.googletag = window.googletag || { cmd: [] };' +
    'googletag.cmd.push(function () {' +
    "googletag.defineSlot('/22678428348/Mweb_Epaper_InAhmedabad_300X250', [300, 250], 'div-gpt-ad-1657962640252-0').addService(googletag.pubads());" +
    'googletag.pubads().enableSingleRequest();' +
    'googletag.enableServices();' +
    '});' +
    ' </script>' +
    '' +
    '<script>' +
    "googletag.cmd.push(function() { googletag.display('div-gpt-ad-1657962640252-0'); });" +
    '</script>' +
    '</div>' +
    '' +
    '';

  const epaper103 =
    '' +
    "<div id='div-gpt-ad-1646038593543-0' style='min-width: 160px; min-height: 600px;'>" +
    '<script>' +
    'window.googletag = window.googletag || { cmd: [] };' +
    'googletag.cmd.push(function () {' +
    "googletag.defineSlot('/22678428348/SDPL_Epaper_Left_160X600', [160, 600], 'div-gpt-ad-1646038593543-0').addService(googletag.pubads());" +
    'googletag.pubads().enableSingleRequest();' +
    'googletag.pubads().disableInitialLoad();' +
    'googletag.enableServices();' +
    '});' +
    '</script>' +
    '' +
    '<script>' +
    "   googletag.cmd.push(function() { googletag.display('div-gpt-ad-1646038593543-0'); });" +
    '</script>' +
    '</div>' +
    // '<div id="gpt-passback">'+
    // '<script>'+
    // 'window.googletag = window.googletag || {cmd: []};'+
    // 'googletag.cmd.push(function() {'+
    // "googletag.defineSlot('/21928950349,22624158342/sandesh_backfill_160x600', [160, 600], 'gpt-passback').addService(googletag.pubads());"+
    // 'googletag.enableServices();'+
    // "googletag.display('gpt-passback');"+
    // '});'+
    // '</script>'+
    // '</div>'+
    '' +
    '';
  const epaper104 =
    '' +
    "<div id='div-gpt-ad-1646038617823-0' style='min-width: 160px; min-height: 600px;'>" +
    '<script>' +
    'window.googletag = window.googletag || { cmd: [] };' +
    'googletag.cmd.push(function () {' +
    "googletag.defineSlot('/22678428348/SDPL_Epaper_Right_160X600', [160, 600], 'div-gpt-ad-1646038617823-0').addService(googletag.pubads());" +
    'googletag.pubads().enableSingleRequest();' +
    'googletag.pubads().disableInitialLoad();' +
    'googletag.enableServices();' +
    '});' +
    '</script>' +
    '' +
    '<script>' +
    "   googletag.cmd.push(function() { googletag.display('div-gpt-ad-1646038617823-0'); });" +
    '</script>' +
    '</div>' +
    // '<div id="gpt-passback">'+
    // '<script>'+
    // 'window.googletag = window.googletag || {cmd: []};'+
    // 'googletag.cmd.push(function() {'+
    // "googletag.defineSlot('/21928950349,22624158342/sandesh_backfill_160x600', [160, 600], 'gpt-passback').addService(googletag.pubads());"+
    // 'googletag.enableServices();'+
    // "googletag.display('gpt-passback');"+
    // '});'+
    // '</script>'+
    // '</div>'+
    '' +
    '';
  const epaper207 =
    '' +
    // "<div id='div-gpt-ad-1646892452854-0' style='min-width: 300px; min-height: 50px;'>" +
    // '<script>' +
    // 'window.googletag = window.googletag || { cmd: [] };' +
    // 'googletag.cmd.push(function () {' +
    // "googletag.defineSlot('/22678428348/Mweb_Epaper_Left_300x250', [[300, 100], [320, 50]], 'div-gpt-ad-1646892452854-0').addService(googletag.pubads());" +
    // 'googletag.pubads().enableSingleRequest();' +
    // 'googletag.pubads().disableInitialLoad();' +
    // 'googletag.enableServices();' +
    // '});' +
    // '</script>' +
    // '' +
    // '<script>' +
    // "   googletag.cmd.push(function() { googletag.display('div-gpt-ad-1646892452854-0'); });" +
    // '</script>' +
    // '</div>' +
    '' +
    '';
  const epaper208 =
    '' +
    "<div id='div-gpt-ad-1646388837706-0' style='min-width: 300px; min-height: 250px;'>" +
    '<script>' +
    'window.googletag = window.googletag || { cmd: [] };' +
    'googletag.cmd.push(function () {' +
    "googletag.defineSlot('/22678428348/Mweb_Epaper_Right_300x250', [300, 250], 'div-gpt-ad-1646388837706-0').addService(googletag.pubads());" +
    'googletag.pubads().enableSingleRequest();' +
    'googletag.pubads().disableInitialLoad();' +
    'googletag.enableServices();' +
    '});' +
    '</script>' +
    '' +
    '<script>' +
    " googletag.cmd.push(function() { googletag.display('div-gpt-ad-1646388837706-0'); });" +
    '</script>' +
    '</div>' +
    '' +
    '';

    
    const rajkotcityads = ''+
    // 'after surat ads'+
    "<div id='div-gpt-ad-1677757000770-0' style='min-width: 300px; min-height: 100px;'>" +
    '<script>' +
    'window.googletag = window.googletag || { cmd: [] };' +
    'googletag.cmd.push(function () {' +
    "googletag.defineSlot('/22678428348/Mweb_Epaper_AfterSurat_300x100', [300, 100], 'div-gpt-ad-1677757000770-0').addService(googletag.pubads());" +
    'googletag.pubads().enableSingleRequest();' +
    'googletag.pubads().disableInitialLoad();' +
    'googletag.enableServices();' +
    '});' +
    '</script>' +
    '' +
    '<script>' +
    " googletag.cmd.push(function() { googletag.display('div-gpt-ad-1677757000770-0'); });" +
    '</script>' +
    '</div>' +
    '' +
    '';

    const vadodaracityads = ''+    
    // 'after rajkot ads'+
    "<div id='div-gpt-ad-1677757288216-0' style='min-width: 300px; min-height: 100px;'>" +
    '<script>' +
    'window.googletag = window.googletag || { cmd: [] };' +
    'googletag.cmd.push(function () {' +
    "googletag.defineSlot('/22678428348/Mweb_Epaper_AfterRajkot_300x100', [300, 100], 'div-gpt-ad-1677757288216-0').addService(googletag.pubads());" +
    'googletag.pubads().enableSingleRequest();' +
    'googletag.pubads().disableInitialLoad();' +
    'googletag.enableServices();' +
    '});' +
    '</script>' +
    '' +
    '<script>' +
    "googletag.cmd.push(function() { googletag.display('div-gpt-ad-1677757288216-0'); });" +
    '</script>' +
    '</div>' +
    '' +
    '';

    const bhujcityads = ''+
    // 'after vadodara ads'+
    "<div id='div-gpt-ad-1677758558946-0' style='min-width: 300px; min-height: 100px;'>" +
    '<script>' +
    'window.googletag = window.googletag || { cmd: [] };' +
    'googletag.cmd.push(function () {' +
    "googletag.defineSlot('/22678428348/Mweb_Epaper_AfterVadodara_300x100', [300, 100], 'div-gpt-ad-1677758558946-0').addService(googletag.pubads());" +
    'googletag.pubads().enableSingleRequest();' +
    'googletag.pubads().disableInitialLoad();' +
    'googletag.enableServices();' +
    '});' +
    '</script>' +
    '' +
    '<script>' +
    "googletag.cmd.push(function() { googletag.display('div-gpt-ad-1677758558946-0'); });" +
    '</script>' +
    '</div>' +
    '' +
    '';

    const bhavnagarcityads = ''+
    // 'after bhuj ads'+
    "<div id='div-gpt-ad-1677759217763-0' style='min-width: 300px; min-height: 100px;'>" +
    '<script>' +
    'window.googletag = window.googletag || { cmd: [] };' +
    'googletag.cmd.push(function () {' +
    "googletag.defineSlot('/22678428348/Mweb_Epaper_AfterBhuj_300x100', [300, 100], 'div-gpt-ad-1677759217763-0').addService(googletag.pubads());" +
    'googletag.pubads().enableSingleRequest();' +
    'googletag.pubads().disableInitialLoad();' +
    'googletag.enableServices();' +
    '});' +
    '</script>' +
    '' +
    '<script>' +
    "googletag.cmd.push(function() { googletag.display('div-gpt-ad-1677759217763-0'); });" +
    '</script>' +
    '</div>' +
    '' +
    '';

    
    const suratunibotsads = ""+
    // '<script async src="https://cdn.unibotscdn.com/ubplayer/player.js"></script>'+
    // '<style>div#unibots-video { text-align: center; margin: auto; }</style>'+
    // '<div id="div-ub-sandeshepaper_category">'+
    // '<script>'+
    // 'window.unibots = window.unibots || { cmd: [] };'+
    // 'unibots.cmd.push(()=>{ unibotsPlayer("sandeshepaper_category"); });'+
    // '</script>'+
    // '</div>'+
    ''
  return (
    <>
      <Helmet>
        <title>Gujarat E-Paper | Gujarati E-Paper | E-Paper news in Gujarati | Sandesh</title>
      </Helmet>
      <div className="container">
        <div className="row e-paper e-paper_new">
          <div className="col-md-2">
            <div className="add-fix-top">
              <div className="ad-side">
                {/* {epaperleft?.[0]?.script &&  */}
                <div className="ad-section">
                  {/* <HelperScript argSmall={207} argLarge={103} /> */}
                  {
                    window.innerWidth < 991 ?
                      <InjectScript
                        key={window.location.pathname}
                        script={epaper207}
                      /> :
                      <InjectScript
                        key={window.location.pathname}
                        script={epaper103}
                      />
                  }
                  {/* <InjectScript script={epaperleft?.[0]?.script}/ /> */}
                </div>
                {/* } */}
              </div>
            </div>
          </div>
          <div className="col-md-8 new_data">
            {ePaperMenuData &&
              Object.values(ePaperMenuData)?.map((els) => (
                <>
                  {els?.open !== 1 && (
                    <div className="epaper-category e-paper_new">
                      {window.innerWidth < 991 && els?.name == 'Rajkot City' && <InjectScript script={rajkotcityads} />}
                      {window.innerWidth < 991 && els?.name == 'Vadodara' && <InjectScript script={vadodaracityads} />}
                      {window.innerWidth < 991 && els?.name == 'Bhuj' && <InjectScript script={bhujcityads} />}
                      {window.innerWidth < 991 && els?.name == 'Bhavnagar' && <InjectScript script={bhavnagarcityads} />}  
                      {window.innerWidth > 991 && els?.name == 'Surat City' && <InjectScript script={suratunibotsads} key={Math.random()} />}

                      <h5 className="my-3 text-uppercase city_name">
                        {els?.name}
                      </h5>
                      {els?.name === "Supplements" ?
                        <>
                          <div className=" row city_paper">
                            <>
                              {els?.submenu &&
                                Object.values(els?.submenu).map((el, idx) => (
                                  <>
                                    {el?.open !== 1 && (
                                      <>
                                        {
                                          idx >= 0 && idx < 6 ?
                                            <div className="col-md-4 mb-3">
                                              <Link target='_parent'
                                                to={`${el?.category}`}
                                                onClickCapture={() =>
                                                  ReactGA.event({
                                                    category: 'E-Paper',
                                                    action: els?.name,
                                                    label: el?.name,
                                                  })
                                                }
                                              >
                                                <div className="img-in-txt margin-bottom-guj">
                                                  <img loading="lazy"
                                                    className="epaper-news-img"
                                                    src={
                                                      ePaperImageData[el?.category]
                                                        ? assetbaseUrl?.value +
                                                        // ? 'https://esandesh.gumlet.io/' +
                                                        ePaperImageData[el?.category]
                                                        : epaper
                                                    }
                                                    alt=""
                                                  />
                                                  <div className=" content-epaper blog-details content-epaper1 ">
                                                    <p className="epaper-bottom-text row px-3">
                                                      {el?.name}
                                                      <a>
                                                        <i className=" far fa-angle-double-right"></i>
                                                      </a>
                                                    </p>
                                                  </div>
                                                </div>
                                              </Link>
                                            </div> : ''}
                                      </>
                                    )}
                                  </>
                                ))}
                            </>
                            {window.innerWidth < 767 ?
                              <div className="text-center">
                                <InjectScript script={supplementafter3} key={supplementafter3} />
                              </div>
                              : ''}
                            <>
                              {els?.submenu &&
                                Object.values(els?.submenu).map((el, idx) => (
                                  <>
                                    {el?.open !== 1 && (
                                      <>
                                        {
                                          idx >= 6 && idx < 12 ?
                                            <div className="col-md-4 mb-3">
                                              <Link target='_parent'
                                                to={`${el?.category}`}
                                                onClickCapture={() =>
                                                  ReactGA.event({
                                                    category: 'E-Paper',
                                                    action: els?.name,
                                                    label: el?.name,
                                                  })
                                                }
                                              >
                                                <div className="img-in-txt margin-bottom-guj">
                                                  <img loading="lazy"
                                                    className="epaper-news-img"
                                                    src={
                                                      ePaperImageData[el?.category]
                                                        ? assetbaseUrl?.value +
                                                        // ? 'https://esandesh.gumlet.io/' +
                                                        ePaperImageData[el?.category]
                                                        : epaper
                                                    }
                                                    alt=""
                                                  />
                                                  <div className=" content-epaper blog-details content-epaper1 ">
                                                    <p className="epaper-bottom-text row px-3">
                                                      {el?.name}
                                                      <a>
                                                        <i className=" far fa-angle-double-right"></i>
                                                      </a>
                                                    </p>
                                                  </div>
                                                </div>
                                              </Link>
                                            </div> : ''}
                                      </>
                                    )}
                                  </>
                                ))}
                            </>
                            {window.innerWidth < 767 ?
                              <div className="text-center">
                                <InjectScript script={supplementafter3} key={supplementafter3} />
                              </div>
                              : ''}
                          </div>
                        </>
                        : ""}
                      {els?.name === "Ahmedabad" ?
                        <>
                          <div className="row city_paper">
                            <>
                              {els?.submenu &&
                                Object.values(els?.submenu).map((el, idx) => (
                                  <>
                                    {el?.open !== 1 && (
                                      <>
                                        {
                                          idx >= 0 && idx < 4 ?
                                            <div className="col-md-4 mb-3">
                                              <Link target='_parent'
                                                to={`${el?.category}`}
                                                onClickCapture={() =>
                                                  ReactGA.event({
                                                    category: 'E-Paper',
                                                    action: els?.name,
                                                    label: el?.name,
                                                  })
                                                }
                                              >
                                                <div className="img-in-txt margin-bottom-guj">
                                                  <img loading="lazy"
                                                    className="epaper-news-img"
                                                    src={
                                                      ePaperImageData[el?.category]
                                                        ? assetbaseUrl?.value +
                                                        // ? 'https://esandesh.gumlet.io/' +
                                                        ePaperImageData[el?.category]
                                                        : epaper
                                                    }
                                                    alt=""
                                                  />
                                                  <div className=" content-epaper blog-details content-epaper1 ">
                                                    <p className="epaper-bottom-text row px-3">
                                                      {el?.name}
                                                      <a>
                                                        <i className=" far fa-angle-double-right"></i>
                                                      </a>
                                                    </p>
                                                  </div>
                                                </div>
                                              </Link>
                                            </div> : ''}
                                      </>
                                    )}
                                  </>
                                ))}
                            </>
                            {window.innerWidth < 767 ?
                              <div className="text-center">
                                <InjectScript script={ahmedabadafter2} key={ahmedabadafter2} />
                              </div>
                              : ''}
                            <>
                              {els?.submenu &&
                                Object.values(els?.submenu).map((el, idx) => (
                                  <>
                                    {el?.open !== 1 && (
                                      <>
                                        {
                                          idx >= 4 && idx < 8 ?
                                            <div className="col-md-4 mb-3">
                                              <Link target='_parent'
                                                to={`${el?.category}`}
                                                onClickCapture={() =>
                                                  ReactGA.event({
                                                    category: 'E-Paper',
                                                    action: els?.name,
                                                    label: el?.name,
                                                  })
                                                }
                                              >
                                                <div className="img-in-txt margin-bottom-guj">
                                                  <img loading="lazy"
                                                    className="epaper-news-img"
                                                    src={
                                                      ePaperImageData[el?.category]
                                                        ? assetbaseUrl?.value +
                                                        // ? 'https://esandesh.gumlet.io/' +
                                                        ePaperImageData[el?.category]
                                                        : epaper
                                                    }
                                                    alt=""
                                                  />
                                                  <div className=" content-epaper blog-details content-epaper1 ">
                                                    <p className="epaper-bottom-text row px-3">
                                                      {el?.name}
                                                      <a>
                                                        <i className=" far fa-angle-double-right"></i>
                                                      </a>
                                                    </p>
                                                  </div>
                                                </div>
                                              </Link>
                                            </div> : ''}
                                      </>
                                    )}
                                  </>
                                ))}
                            </>
                            {/* {window.innerWidth < 767 ?
                              <div className="text-center">
                                <InjectScript script={ahmedabadafter2} key={ahmedabadafter2} />
                              </div>
                              : ''} */}
                            <>
                              {els?.submenu &&
                                Object.values(els?.submenu).map((el, idx) => (
                                  <>
                                    {el?.open !== 1 && (
                                      <>
                                        {
                                          idx >= 8 && idx < 12 ?
                                            <div className="col-md-4 mb-3">
                                              <Link target='_parent'
                                                to={`${el?.category}`}
                                                onClickCapture={() =>
                                                  ReactGA.event({
                                                    category: 'E-Paper',
                                                    action: els?.name,
                                                    label: el?.name,
                                                  })
                                                }
                                              >
                                                <div className="img-in-txt margin-bottom-guj">
                                                  <img loading="lazy"
                                                    className="epaper-news-img"
                                                    src={
                                                      ePaperImageData[el?.category]
                                                        ? assetbaseUrl?.value +
                                                        // ? 'https://esandesh.gumlet.io/' +
                                                        ePaperImageData[el?.category]
                                                        : epaper
                                                    }
                                                    alt=""
                                                  />
                                                  <div className=" content-epaper blog-details content-epaper1 ">
                                                    <p className="epaper-bottom-text row px-3">
                                                      {el?.name}
                                                      <a>
                                                        <i className=" far fa-angle-double-right"></i>
                                                      </a>
                                                    </p>
                                                  </div>
                                                </div>
                                              </Link>
                                            </div> : ''}
                                      </>
                                    )}
                                  </>
                                ))}
                            </>
                            {/* {window.innerWidth < 767 ?
                              <div className="text-center">
                                <InjectScript script={ahmedabadafter2} key={ahmedabadafter2} />
                              </div>
                              : ''} */}
                          </div>
                    <div id="epaper_clipped" class="epaper_clipped"></div>
                        </>
                        : ""}
                      {els?.name !== "Supplements" && els?.name !== "Ahmedabad" ? <div className="row city_paper">
                        {els?.submenu &&
                          Object.values(els?.submenu).map((el, idx) => (
                            <>
                              {el?.open !== 1 && (
                                <div className="col-md-4 mb-3">
                                  <Link target='_parent'
                                    to={`${el?.category}`}
                                    onClickCapture={() =>
                                      ReactGA.event({
                                        category: 'E-Paper',
                                        action: els?.name,
                                        label: el?.name,
                                      })
                                    }
                                  >
                                    <div className="img-in-txt margin-bottom-guj">
                                      <img loading="lazy"
                                        className="epaper-news-img"
                                        src={
                                          ePaperImageData[el?.category]
                                            ? assetbaseUrl?.value +
                                            // ? 'https://esandesh.gumlet.io/' +
                                            ePaperImageData[el?.category]
                                            : epaper
                                        }
                                        alt=""
                                      />
                                      <div className=" content-epaper blog-details content-epaper1 ">
                                        <p className="epaper-bottom-text row px-3">
                                          {el?.name}
                                          <a>
                                            <i className=" far fa-angle-double-right"></i>
                                          </a>
                                        </p>
                                      </div>
                                    </div>
                                  </Link>
                                </div>
                              )}
                            </>
                          ))}
                      </div> : ''}
                    </div>
                  )}
                  {
                    window.innerWidth < 767 && els?.name == 'ahmedabad' ?
                      <div className="text-center">
                        <InjectScript script={aftercity} key={aftercity} />
                      </div>
                      :
                      ""
                  }
                </>
              ))}{' '}
            <HomeTaboola key={window.location.href} />
          </div>
          <div className="col-md-2">
            <div className="add-fix-top">
              <div className="ad-side">
                {/* {epaperRight?.[0]?.script &&  */}
                <div className="ad-section">
                  {/* <HelperScript argSmall={208} argLarge={104} /> */}
                  {
                    window.innerWidth < 991 ?
                      <InjectScript
                        key={window.location.pathname}
                        script={epaper208}
                      /> :
                      <InjectScript
                        key={window.location.pathname}
                        script={epaper104}
                      />
                  }
                  {/* <InjectScript script={epaperRight?.[0]?.script} /> */}
                </div>
                {/* } */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Epaper;
