import { HOMEPRIMARY_FETCH_DATA_SUCCESS, HOMEPRIMARY_HAVE_ERROR, HOMEPRIMARY_ARE_LOADING } from '../actionType';
import HttpService from '../../utils/httpService';
import { Get } from '../../services/api.service'

export function homePrimaryAction(action, payload) {
    switch (action) {
        case HOMEPRIMARY_HAVE_ERROR:
            return {
                type: action,
                hasError: payload
            };

        case HOMEPRIMARY_ARE_LOADING:
            return {
                type: action,
                isLoading: payload
            };

        case HOMEPRIMARY_FETCH_DATA_SUCCESS:
            return {
                type: action,
                homePrimary: payload,
            };

        default:
            return;
    }
}

export function homePrimaryFetchData() {
    return (dispatch) => {
        try {
            return new Promise((resolve, reject) => {
                dispatch(homePrimaryAction(HOMEPRIMARY_ARE_LOADING, true));
                let callAPI = true;
                if (localStorage.getItem('SandeshHome')) {
                    const data = JSON.parse(localStorage.getItem('SandeshHome'));
                    dispatch(homePrimaryAction(HOMEPRIMARY_FETCH_DATA_SUCCESS, data));
                    if ((new Date().getTime() - (data?.time || 0)) < 60000) callAPI = false;
                }
                if (callAPI) {
                    Get(HttpService.homePrimary).then((result) => {
                        localStorage.setItem('SandeshHome', JSON.stringify({ ...result.data, time: new Date().getTime() }));
                        dispatch(homePrimaryAction(HOMEPRIMARY_FETCH_DATA_SUCCESS, { ...result.data, time: new Date().getTime() }));
                        resolve(result);
                    }, (error) => {
                        reject(error);
                    });
                }
            });
        } catch (error) {
            dispatch(homePrimaryAction(HOMEPRIMARY_HAVE_ERROR, true));
        }
    }
}
