/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
import React from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux';
function BreadCrumb() {

  const innerData = useSelector((state) => state?.PostReducer?.data);
  const breadcrumbManage = (params) => {
    const urlPath = window.location.href;
    const breadCrumb = urlPath?.split('/');
    if (breadCrumb?.[3] === 'page') {
      return false;
    } else if (breadCrumb?.[3] === 'tag') {
      return false;
    } else {
      return (breadCrumb?.[params]) ? (breadCrumb?.[params]).replace('-', '-') : "";
    }
  }

  return (
    <>
      {
        (breadcrumbManage(4) && innerData?.title) &&
        <div className={`container article-${innerData?.id}`}>
          <nav aria-label="breadcrumb" className='breadcrumbNav'>
            <ol className="breadcrumb breadcrumb-bg m-0">
              <li className="breadcrumb-item text-capitalize "><Link target="_parent" to={`/`} className="text-secondary">Home</Link></li>
              {breadcrumbManage(3) && <li className="breadcrumb-item text-capitalize"><Link target="_parent" className="text-secondary" to={`/${breadcrumbManage(3)}`}>{breadcrumbManage(3)}</Link></li>}
              {breadcrumbManage(4) && <li className="breadcrumb-item text-capitalize active" aria-current="page"><a className=" breadcrumb-item active" href="javascript:void(0)">{innerData?.title}</a></li>}
            </ol>
          </nav>
        </div>
      }
    </>
  )
}

export default BreadCrumb