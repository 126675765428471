/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import $ from 'jquery';
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LogingFetchData, SignupFetchData, signInSaveData } from '../../redux/actions/loginAction';
import { useNavigate } from 'react-router-dom';
import OtpCard from '../component/OtpCard';

export const Timer = ({ api }) => {
  const otpTime = 60;
  const [time, setTime] = useState(otpTime);
  useEffect(() => {
    let timer;
    if (time) {
      timer = setTimeout(() => {
        setTime(time - 1);
      }, 1000);
    }
    return () => {
      clearTimeout(timer);
    }
  }, [time]);

  const resendOTP = () => {
    if (!time) {
      setTime(otpTime);
      api();
    }
  }

  return <p className={time ? 'disabled' : ''} onClick={resendOTP}> OTP ફરીથી મોકલો {time ? `(00:${("0" + time).slice(-2)})` : ''}</p >;
}

const Login = (props) => {

  const dispatch = useDispatch();

  const [error, setError] = useState('');
  const [screen, setScreen] = useState('login');
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [focus, setFocus] = useState(false);
  const [value, setValue] = useState('');
  const [otp, setOTP] = useState('');
  const [selectOption, setSelectOption] = useState(false);
  const [gender, setGender] = useState(1);
  const [formData, setFormData] = useState({});
  const navigate = useNavigate();
  const options = useSelector(s => s.MarqueeReducer.city);
  const { 74: loginImage } = useSelector((state) => state?.SettingReducer?.data);
  const [type, selectType] = useState(options[0]);

  const Loading = () => <span className='loading-round' />

  useEffect(() => {
    setTimeout(() => {
      document.body.classList.add('login-box-v2');
      setShow(true);
    }, 10);
  }, []);

  useEffect(() => {
    let timer;
    if (error) {
      timer = setTimeout(() => {
        setError('');
      }, 3000);
    }
    return () => {
      clearTimeout(timer);
    }
  }, [error]);

  useEffect(() => {
    if (focus && document.querySelector("#is-date")) {
      document.querySelector("#is-date").click();
    }
  }, [focus]);

  const closeModal = () => {
    if (document.body.classList.contains('hide-login-close')) {
      $('.login-main-box').removeClass('active');
      document.body.classList.remove('hide-login-close');
      document.body.classList.remove('login-box-v2');
      navigate('/epaper');
    } else {
      $('.login-main-box').removeClass('active');
      document.body.classList.remove('login-box-v2');
    }
  }

  const setSelectType = (e) => {
    selectType(e);
    setSelectOption(false);
    setValue('');
  }

  const loginSuccess = (id, message, data) => {
    dispatch(signInSaveData(id, data));
    setError(message);
    setTimeout(() => {
      // closeModal();
      window.location.reload();
      props.success('success');
    }, 2000);
  }

  const checkUserIsExist = async (e) => {
    if (e) e.preventDefault();
    setLoading(true);
    try {
      const response = await dispatch(LogingFetchData({ master: value, type: type.id == 103 ? 'mobile' : 'email' }));
      if (response?.status) {
        setScreen('otp');
      } else {
        setError(response.message);
      }
    } catch (error) {
      setError(error.message);
    }
    setLoading(false);
  }

  const OTPHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await dispatch(LogingFetchData({ master: value, otp: otp, type: type.id == 103 ? 'mobile' : 'email' }, 'otp'));
      if (response?.status && response?.type == 'not registered') {
        setScreen('signup');
        setFocus(false);
        setLoading(false);
      } else if (response?.status && response?.type == 'registered') {
        loginSuccess(response?.user?.id, response?.message, response?.user);
      } else {
        setError(response.message);
        setOTP('');
        setLoading(false);
      }
    } catch (error) {
      setError(error.message);
      setOTP('');
      setLoading(false);
    }
  }

  const signupHandler = async (e) => {
    if (e) e.preventDefault();
    setLoading(true);
    try {
      const obj = { ...formData, gender, mobile: '', email: '', country: type.id };
      obj[type.id == 103 ? 'mobile' : 'email'] = value;
      const response = await dispatch(SignupFetchData(obj));
      if (response?.status && response?.user?.id) {
        loginSuccess(response?.user?.id, response?.message, response?.user);
      } else {
        setError(response.message);
        setLoading(false);
      }
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  }

  function validate(val, inputType) {
    let regex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    if (type.id != 103) regex = /\S+@\S+\.\S+/;
    if (inputType) regex = /^([0-9]{4})$/;
    return regex.test(val) || false;
  }

  return (
    <section className="login">
      <div className={`login-main-box ${show ? `active` : ``}`}>

        <div className='shadow' />

        <span onClick={closeModal} className="closebtn">
          <i className="fa fa-times" />
        </span>

        <div className='forms'>
          <div className='inner-forms'>
            <div className='img-popup'>
              <img src={'/assets/logo-white.png'} alt='' />
              <img src={loginImage?.value || '/assets/paper.png'} alt='' className='paper' />
            </div>

            <div className='form'>
              {error && <p className='errors'>{error}</p>}

              {screen == 'login' ?
                <form onSubmit={checkUserIsExist} autoComplete="off">
                  <div className={`form-content ${focus} ${type.id == 103 ? 'mobile' : 'email'} ${validate(value) ? `validate` : ``}`}>
                    <div className='flags' onClick={() => setSelectOption(!selectOption)}>
                      <img src={type.icon} alt='' />
                      <p>{type.id == 103 ? type.code : type.label}</p>
                    </div>

                    <div className={`flags-popup ${selectOption}`}>
                      <div className='white-popup'>
                        {options.map((e) => {
                          return <div key={e.label} onClick={() => setSelectType(e)}>
                            <img src={e.icon} alt='' />
                            <p>{e.label}</p>
                          </div>
                        })}
                      </div>
                    </div>

                    <input
                      autoFocus
                      onKeyDown={(evt) => (type.id == 103 && ["e", "E", "+", "-"].includes(evt.key)) && evt.preventDefault()}
                      onFocus={() => setFocus(true)}
                      onBlur={() => setFocus(false)}
                      onChange={(e) => setValue(e.target.value)}
                      value={value}
                      type={type.id == 103 ? 'number' : 'email'}
                      inputMode={type.id == 103 ? 'numeric' : null}
                      placeholder={type.id == 103 ? 'Mobile Number' : 'Email address'}
                      required
                    />
                  </div>

                  <div className='security'>
                    <i className='fa fa-lock'></i>
                    <p><strong>તમારી પર્સનલ માહિતી સુરક્ષિત છે.</strong> તમારો {type.id == 103 ? "મોબાઇલ નંબર" : 'ઇમેઇલ'} માત્ર અકાઉન્ટ વેરિફાય કરવા માટે જ લઈ રહ્યા છીએ.</p>
                  </div>

                  <button
                    type='submit'
                    disabled={loading || !validate(value)}
                    className='btn'
                  >{loading ? <Loading /> : 'Continue'}</button>
                </form> : ''}



              {screen == 'otp' ?
                <form onSubmit={OTPHandler} autoComplete="off" className='otp-page'>

                  <p className='info'><strong>{type.id == 103 ? "+91 " : ''}{value}</strong> પર મોકલેલો 4 આંકડાનો કોડ એન્ટર કરો.</p>

                  <div className={`form-content- ${focus} ${type.id == 103 ? 'mobile' : 'email'} ${validate(otp, 1) ? `validate` : ``}`}>
                    <OtpCard setOtpValue={setOTP} otpValue={otp} />
                    {/* 
                    <input
                      autoFocus
                      onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                      onFocus={() => setFocus(true)}
                      onBlur={() => setFocus(false)}
                      onChange={(e) => setOTP(e.target.value)}
                      value={otp}
                      type={'number'}
                      inputMode='numeric'
                      maxLength={4}
                      placeholder='OTP'
                      required
                    /> */}
                  </div>

                  <div className='time-info'>
                    <p onClick={() => setScreen('login')}>{type.id == 103 ? "મોબાઇલ નંબર" : 'ઇમેઇલ'} બદલવો છે?</p>
                    <Timer
                      api={checkUserIsExist}
                    />
                  </div>

                  <button
                    type='submit'
                    disabled={loading || !validate(otp, 1)}
                    className='btn'
                  >{loading ? <Loading /> : 'Continue'}</button>
                </form> : ''}


              {screen == 'signup' ?
                <form onSubmit={signupHandler} autoComplete="off" className='otp-page signup-page'>
                  <p className='info text-center'>સાઇનઅપ માટે નીચે વિગતો દાખલ કરો.</p>

                  <div className='form-content'>
                    <i className='fa fa-user fa-icons-input' />
                    <input
                      type='text'
                      placeholder='Name'
                      onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                      required
                    />
                  </div>

                  <div className='form-content'>
                    <i className='fa fa-calendar fa-icons-input' />
                    <input
                      id="is-date"
                      max={new Date(new Date().setFullYear(new Date().getFullYear() - 18)).toISOString().split('T')[0]}
                      type={focus ? 'date' : 'text'}
                      placeholder='Date of Birth'
                      onFocus={() => setFocus(true)}
                      onBlur={() => setFocus(false)}
                      onChange={(e) => setFormData({ ...formData, dob: e.target.value })}
                      required
                    />
                  </div>

                  <div className='gender-options'>
                    {['', 'male', 'female', 'other'].map((e, i) => {
                      return !i ? '' : <div onClick={() => setGender(i)} className={i == gender ? `selected` : ``}>{e}</div>;
                    })}
                  </div>

                  <button
                    type='submit'
                    disabled={loading || !validate(otp, 1)}
                    className='btn'
                  >{loading ? <Loading /> : 'Continue'}</button>

                  {loading ? '' :
                    <button
                      type='button'
                      onClick={() => signupHandler()}
                      className='btn btn-invert'
                    >Skip</button>}


                </form> : ''}

            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Login
