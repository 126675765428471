import { LOGIN_CITY, MARQUEE_FETCH_DATA_SUCCESS, MARQUEE_HAVE_ERROR, MARQUEE_ARE_LOADING } from '../actionType';

// const defaultData = localStorage.getItem('SandeshHomeMarquee') || [];
const defaultData = [];
const initialState = {
    data: defaultData,
    loading: true,
    error: [],
    city: [
        {
            id: 103,
            label: "India",
            code: "+91",
            icon: "https://epapercdn.sandesh.com/flag/in.svg"
        }
    ],
};

export function MarqueeReducer(state = initialState, action) {
    switch (action.type) {
        case MARQUEE_HAVE_ERROR:
            return {
                ...state,
                error: action.hasError,
                loading: false,
            }

        case MARQUEE_ARE_LOADING:
            return {
                ...state,
                loading: action.isLoading
            }

        case LOGIN_CITY:
            return {
                ...state,
                city: action.city
            }

        case MARQUEE_FETCH_DATA_SUCCESS:
            return {
                ...state,
                data: action.marquee,
                loading: false,
            }

        default:
            return state;
    }
}
