const baseUrl = 'https://new-wapi.sandesh.com/api/v1/'
const baseUrlMenu = 'https://new-wapi.sandesh.com/api/v1/menu/'
const baseUrlPage = 'https://new-wapi.sandesh.com/api/v1/'
const PbaseUrl = 'https://new-wapi.sandesh.com/api/v1/';

const apiV2Epaper1 = 'https://staging-node.sandesh.com/api/v1/';
const apiV2Epaper = 'https://staging-sdplcms.sandesh.com/api/v2/';

export const assetbaseUrl = 'https://epapercdn.sandesh.com/'
 
const HttpService = {
    ganesh: baseUrlPage + 'ganesh/',
    baseurl: baseUrlPage + 'page/',
    rssURL: baseUrlPage + 'rss',
    metro: baseUrl + 'menu/gujarat-metro',
    menu: baseUrlMenu + 'web-menu',
    footer: baseUrlMenu + 'footer-menu',
    setting: baseUrl + 'setting',
    invrel: baseUrl + 'investor',
    gallary: PbaseUrl + 'gallerypage',
    gallaryInner: PbaseUrl + 'gallery/',
    homeSecondary: PbaseUrl + 'home/secondary',
    homePrimary: PbaseUrl + 'home/primary',
    post: PbaseUrl + 'post-details',
    videoInner: PbaseUrl + 'video/',
    category: PbaseUrl + 'category/',
    categoryLocation: PbaseUrl + 'location/',
    tag: PbaseUrl + 'tag/',
    forgetPassword: PbaseUrl + 'forgot',
    updatepassword: PbaseUrl + 'updatepassword',
    categoryAstro: PbaseUrl + 'category/astrology',
    gujaratSamagraCity: PbaseUrl + 'city',
    video: PbaseUrl + 'videopage',
    loginCheck: PbaseUrl + 'logincheck',
    otpCheck: PbaseUrl + 'checkotp',
    signup: PbaseUrl + 'register',
    login: PbaseUrl + 'login',
    city: PbaseUrl + 'city',
    cityResult: PbaseUrl + 'cityresult/',
    tithi: PbaseUrl + 'tithi',
    childMenu: PbaseUrl + 'childmenu',
    horoscope: PbaseUrl + 'horoscope',
    gameZop: PbaseUrl + 'gamezop',
    widget: PbaseUrl + 'widget',
    epaperCategory: PbaseUrl + 'e-paper?slug=',
    ePaperMenu: baseUrl + 'menu/e-paper-menu',
    searchResult: PbaseUrl + 'search',
    ePaperImage: PbaseUrl + 'epaper-latest',
    googleResult: PbaseUrl + 'sociallogin',
    ProfileResult: PbaseUrl + 'profile',
    profileUpdateResult: PbaseUrl + 'profileupdate',
    analytics: PbaseUrl + 'analytics',
    openapp: PbaseUrl + 'openapp',
    postComment: PbaseUrl + 'post-comment',
    reportcomment: PbaseUrl + 'report-comment',
    comment: PbaseUrl + 'comments',
    videoCategory: PbaseUrl + 'videocategory/',
    sportScore: PbaseUrl + 'sportspage',
    marquee: PbaseUrl + 'marquee',

    loginCheck_v2: apiV2Epaper + 'logincheck',
    otpCheck_v2: apiV2Epaper + 'checkotp',
    profileUpdateResult_V2: apiV2Epaper + 'profileupdate',
    signup_V2: apiV2Epaper + 'signup',
    ProfileResult_V2: apiV2Epaper + 'profile',
    epaperCategory_V2: apiV2Epaper + 'e-paper?slug=',
    setting_V2: apiV2Epaper1 + 'setting',
    paymentVerify_V2: apiV2Epaper + 'payment-verify',
    epaperSubscription_V2: apiV2Epaper + 'subscription-verify',
    epaperSubscriptionList_V2: apiV2Epaper + 'payment-history',
    epaperSubscriptionCancel_V2: apiV2Epaper + 'subscription-cancel',
    epaperTrial_V2: apiV2Epaper + 'trial',

    country_v2: apiV2Epaper + 'country',
    download_v2: apiV2Epaper + 'download',
};

export default HttpService;