import React from 'react'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { useSelector } from 'react-redux';
import CommonCardContent from '../CommonCardContent';

const Trending = () => {
    const { 21: assetbaseUrl } = useSelector((state) => state?.SettingReducer?.data);
    const Trending = useSelector((state) => state?.HomeSecondaryReducer?.data?.Trending);

    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 1200 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 1200, min: 991 },
            items: 4
        },
        tablet: {
            breakpoint: { max: 991, min: 515 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 515, min: 0 },
            items: 1
        }
    };
    return (
        <>
            <section className="trending bg-gray trendingheight">
                <div className="container">
                    <span className="badge badge-danger">Trending News</span>
                    {/* <Link target="_parent" to="/trending" className="readmore">Read More</Link> */}

                    <div className="row">
                        <div className="col-md-12">
                            <Carousel
                                swipeable={true}
                                draggable={false}
                                autoPlaySpeed={5000000}
                                // transitionDuration={10}
                                infinite={false}
                                showDots={false}
                                responsive={responsive}
                                ssr={true}
                                renderArrowsWhenDisabled={true}
                                customTransition="transform 500ms ease"
                                // removeArrowOnDeviceType={["tablet", "mobile"]}
                                className="gallery-sec"
                            >
                                {Trending?.length > 0 && Trending?.map((els) => {
                                    return (
                                        <CommonCardContent url={els?.url} src={`${assetbaseUrl?.value}${els?.media}`} post_type={els?.post_type} time={els?.time} tagline={els?.tagline} publish_date={els?.publish_date} category={els?.category} title={els?.title} />
                                        // <div key={els?.id} className="single-blog mx-2">
                                        //     <Link target="_parent" to={`/${els?.url}`} className="main-blogs">
                                        //         <img loading="lazy" src={!els?.media ? sandeshske : `${assetbaseUrl?.value}${els?.media}`} alt="" className="img-responsive" />
                                        //         {els?.tagline ? <span className="button-hover colorspan-top-trending">{els?.tagline}</span> : ""}
                                        //         <div className="blog-details">
                                        //             <p className="ellipsis">{els?.title}</p>
                                        //             <span className="blog-time"><i className="far fa-clock"></i>{els?.publish_date}</span>
                                        //         </div>
                                        //     </Link>
                                        // </div>

                                    )
                                })}
                            </Carousel>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Trending