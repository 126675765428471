import axios from 'axios';

function maintenance() {
  window.location.href = '/maintenance'
}

function maintenanceClose() {
  window.location.href = '/'
}

function errorRedirect(msg) {
  if (msg === 'Network Error') {
    // window.location.reload();
  }
}

export function Post(url, payload, config) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, payload, config)
      .then((response) => {
        resolve(response.data);
        // console.log('response.headers  ==>', response.headers)
      })
      .catch((e) => {
        errorRedirect(e.message);
        reject(e);
      });
  });
}

export function Put(url, payload, config) {
  return new Promise((resolve, reject) => {
    axios
      .put(url, payload, config)
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => {
        errorRedirect(e.message);
        reject(e);
      });
  });
}

export function Get(url, config) {
  return new Promise((resolve, reject) => {
    axios.get(url, config)
      .then((response) => {
        if (url.includes('setting')) {
          if (response.data) {
            if (parseInt(response.data?.data?.[1]?.value) === 1) {
              if ('/maintenance' !== window.location.pathname) {
                maintenance();
              }
            } else if (parseInt(response.data?.data?.[1]?.value) === 0) {
              if ('/maintenance' === window.location.pathname) {
                maintenanceClose();
              }
            }
          }
        }
        resolve(response.data);
      })
      .catch((e) => {
        errorRedirect(e.message);
        reject(e)
      });
  });
}

export function Delete(url, config) {
  return new Promise((resolve, reject) => {
    axios
      .delete(url, config)
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => {
        errorRedirect(e.message);
        reject(e);
      });
  });
}
