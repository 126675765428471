import { useEffect, useState } from 'react'
import { useSelector } from "react-redux";
import logo from '../../assets/logoog.jpg';

const Maintenance = () => {
    const [maintenanceMessage, setMaintenanceMessage] = useState('');
    const setting = useSelector((state) => state?.SettingReducer?.data);

    useEffect(() => {
        setTimeout(() => {
            window.location.reload();
        }, 10000);
    }, []);

    useEffect(() => {
        setMaintenanceMessage(setting[2]?.value);
    }, [setting]);

    return (
        <div className='container'>
            <div className='row'>
                <div className='col-md-12 py-5'>
                    <div
                        className="maintenanceMessage">
                        <img style={{ filter: "none" }} src={logo} alt="" />
                        <br />
                        <br />
                        
                        {!maintenanceMessage && <span className='loading-round' />}

                        <div
                            dangerouslySetInnerHTML={{ __html: maintenanceMessage ? maintenanceMessage.replace(/(?:\r\n|\r|\n)/g, '<br>') : 'Please wait...' }}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Maintenance