/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux';
import sandeshsvg from '../../assets/sandesh-svg.svg';
import HelperScript from '../../utils/helper';
import ReactGA from "react-ga4";
import InjectScript from '../CustomScript';

const Footer = (props) => {
    const Footerlist = useSelector((state) => state?.FooterReducer);
    const menuList = useSelector((state) => state?.MenuReducer);
    // const { 44: GlobalFooter } = useSelector((state) => state?.widgetReducer?.data);

    const { 28: Facebook } = useSelector((state) => state?.SettingReducer?.data);
    const { 29: Twitter } = useSelector((state) => state?.SettingReducer?.data);
    const { 30: Instagram } = useSelector((state) => state?.SettingReducer?.data);
    const { 31: Youtube } = useSelector((state) => state?.SettingReducer?.data);
    const { 26: sharerMsg } = useSelector((state) => state?.SettingReducer?.data);
    const { 24: Android } = useSelector((state) => state?.SettingReducer?.data);
    const { 25: Apple } = useSelector((state) => state?.SettingReducer?.data);

    const [footer, setFooterItems] = React.useState('');
    const [menu, setMenuItems] = React.useState('');

    useEffect(() => {
        setFooterItems(Footerlist.data)
        setMenuItems(menuList.data)
    }, [Footerlist, menuList]);


    // if (pathname === '/') {
    //     document.addEventListener("DOMContentLoaded", function () {
    //         window.addEventListener('scroll', function () {
    //             if (window.scrollY > 8210) {
    //                 document.getElementById('footer_bottom').classList.add('fixed');
    //             } else {
    //                 document.getElementById('footer_bottom').classList.remove('fixed');
    //             }
    //         });
    //     });
    // }
    // else{

    // }
    // if (pathname === '/post') {
    //     document.addEventListener("DOMContentLoaded", function () {
    //         window.addEventListener('scroll', function () {
    //             if (window.scrollY > 2050) {
    //                 document.getElementById('footer_bottom').classList.add('fixed');
    //             } else {
    //                 document.getElementById('footer_bottom').classList.remove('fixed');
    //             }
    //         });
    //     });
    // }
    // else{

    // }
    // if (pathname === '/videos') {
    //     document.addEventListener("DOMContentLoaded", function () {
    //         window.addEventListener('scroll', function () {
    //             if (window.scrollY > 3600) {
    //                 document.getElementById('footer_bottom').classList.add('fixed');
    //             } else {
    //                 document.getElementById('footer_bottom').classList.remove('fixed');
    //             }
    //         });
    //     });
    // }
    // else{

    // }
    const footer_bottom_homepage = "" +
    '<script>'+
    'window.googletag = window.googletag || { cmd: [] };'+
    'var ub_interstitialSlot;'+
    'googletag.cmd.push(function () {'+
      'ub_interstitialSlot = googletag.defineOutOfPageSlot("/22678428348/InterstiatialUS_Median_Sep",googletag.enums.OutOfPageFormat.INTERSTITIAL)'+
      '.addService(googletag.pubads());'+
      'googletag.enableServices();'+
    '});'+
    'googletag.cmd.push(function () {'+
      'googletag.pubads().refresh([ub_interstitialSlot]);'+
    '});'+
  '</script>'+
    ''

    return (
        <>
            {/* {GlobalFooter?.[0]?.script &&  */}
            <div className="ad-section">
                <HelperScript argSmall={191} argLarge={44} />
                {/* <InjectScript script={GlobalFooter?.[0]?.script} /> */}
            </div>
            {/* } */}
            <section className="ft_section">
                {/* {window.location.pathname === "/" ? <InjectScript script={footer_bottom_homepage} /> : ''} */}
                <div className="row">
                    <div id="footer_bottom">
                        <div className="col-md-12 p-0">
                            <div className="ft-sticky" style={{ transform: "translateY(0%)" }}>
                                <div className="ft-sticky--mid">
                                    <div className="ft-sticky_wrap">
                                        <div className="ft-sticky--collapse">
                                            <div className="footer_group">
                                                {/* <!--<h2 className="section_head"> <span>SANDESH Group Sites</span></h2>--> */}
                                                <ul>
                                                    <li className="more highlighted"><a href="#">OUR CATEGORIES</a></li>
                                                    {
                                                        menu && Object.values(menu).map((els, idx) => (
                                                            <li className="nav-item" onClickCapture={() => ReactGA.event({ category: 'Footer', action: 'Category', label: els?.name })}>
                                                                {
                                                                    els?.open === 1 ?
                                                                        <a key={Math.random()} className="dropdown" target="_blank" href={`${els?.url}`} rel="noreferrer" >{els?.name}</a> :
                                                                        <a>
                                                                            <Link key={Math.random()} className="dropdown" to={`/${els?.category}`} >{els?.name}</Link>
                                                                        </a>
                                                                }
                                                            </li>
                                                        ))
                                                    }
                                                </ul>
                                            </div>
                                            <div className="footer_nav">
                                                <ul>
                                                    {
                                                        footer && Object.values(footer).map((els, idx) => (
                                                            <li className="list-inline-item" onClickCapture={() => ReactGA.event({ category: 'Footer', action: 'Page', label: els?.name })}>
                                                                {
                                                                    els?.open === 1 ?
                                                                        <a key={Math.random()} target="_blank" href={`${els?.url}`} rel="noreferrer" >{els?.name} </a> :
                                                                        els?.page === 'investor-relations' ?
                                                                            <Link key={Math.random()} to={`/investor-relations`} >{els?.name} </Link> :
                                                                            <Link key={Math.random()} to={`/page/${els?.page}`} >{els?.name} </Link>
                                                                }
                                                            </li>
                                                        ))
                                                    }
                                                </ul>
                                            </div>
                                            <div className="footer_copy">
                                                © COPYRIGHT SANDESH DIGITAL PRIVATE LIMITED {new Date().getFullYear()}. ALL RIGHTS RESERVED.

                                                <input type='hidden' value={sharerMsg?.value} className='sharerMsg' />
                                            </div>
                                        </div>
                                        <div className="ft-sticky_lft">
                                            <div className="ft-sticky_logo">
                                                <Link target="_parent" to="/" onClickCapture={() => ReactGA.event({ category: 'Footer', action: 'Logo', label: 'Logo' })}>
                                                    < img style={{ filter: 'none' }} className="sandesh_logo" src={sandeshsvg} alt="" />
                                                </Link>
                                            </div>
                                            <div className="follow-us">
                                                <span>Follow us on</span>
                                                <div className="footer_socials">
                                                    <ul className="social-icone-center-footer">
                                                        <li><a target="_blank" onClickCapture={() => ReactGA.event({ category: 'Footer', action: 'Icon', label: 'Android' })} className='menu-page-social-icon-android' href={Android?.value} rel="noreferrer"><i className="fa-size-footer fab fa-android"></i></a></li>
                                                        <li><a target="_blank" onClickCapture={() => ReactGA.event({ category: 'Footer', action: 'Icon', label: 'Apple' })} className='menu-page-social-icon-apple' href={Apple?.value} rel="noreferrer"><i className="fa-size-footer fab fa-apple"></i></a></li>
                                                        <li><a target="_blank" onClickCapture={() => ReactGA.event({ category: 'Footer', action: 'Icon', label: 'Facebook' })} className='menu-page-social-icon-facebook' href={Facebook?.value} rel="noreferrer"><i className="fa-size-footer fab fa-facebook"></i></a></li>
                                                        <li><a target="_blank" onClickCapture={() => ReactGA.event({ category: 'Footer', action: 'Icon', label: 'Twitter' })} className='menu-page-social-icon-twitter' href={Twitter?.value} rel="noreferrer"><i className="fa-size-footer fab fa-twitter"></i></a></li>
                                                        <li><a target="_blank" onClickCapture={() => ReactGA.event({ category: 'Footer', action: 'Icon', label: 'Instagram' })} className='menu-page-social-icon-instagram-footer' href={Instagram?.value} rel="noreferrer"><i className="fa-size-footer fab fa-instagram"></i></a></li>
                                                        <li><a target="_blank" onClickCapture={() => ReactGA.event({ category: 'Footer', action: 'Icon', label: 'Youtube' })} className='menu-page-social-icon-yt' href={Youtube?.value} rel="noreferrer"><i className="fa-size-footer fab fa-youtube"></i></a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                {/* {pathname === "/" ? <HomeTaboola key={window.location.href} /> : ''} */}

            </section>
            {/* <section className="footer">
                <footer>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-lg-12 col-xl-7">
                                <div className="row">
                                    <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                                        <div className="footer-menu">
                                            <h6><span className='footer-after-half-border'>TRENDING</span> <b>TOPICS</b></h6>
                                            <ul>
                                                <li><a href="#.">પશ્ચિમ બંગાળ ચૂંટણી</a></li>
                                                <li><a href="#.">T20 વર્લ્ડ કપ</a></li>
                                                <li><a href="#.">પુડ્ડુચેરી ચૂંટણી</a></li>
                                                <li><a href="#.">ખેલ</a></li>
                                                <li><a href="#.">તમિલનાડુની ચૂંટણી</a></li>
                                                <li><a href="#.">અન્ય રમતો</a></li>
                                                <li><a href="#.">પશ્ચિમ બંગાળ ચૂંટણી</a></li>
                                                <li><a href="#.">T20 વર્લ્ડ કપ</a></li>
                                                <li><a href="#.">પુડ્ડુચેરી ચૂંટણી</a></li>
                                                <li><a href="#.">ખેલ</a></li>
                                                <li><a href="#.">તમિલનાડુની ચૂંટણી</a></li>
                                                <li><a href="#.">અન્ય રમતો</a></li>
                                                <li><a href="#.">પશ્ચિમ બંગાળ ચૂંટણી</a></li>
                                                <li><a href="#.">T20 વર્લ્ડ કપ</a></li>
                                                <li><a href="#.">પુડ્ડુચેરી ચૂંટણી</a></li>
                                                <li><a href="#.">ખેલ</a></li>
                                                <li><a href="#.">તમિલનાડુની ચૂંટણી</a></li>
                                                <li><a href="#.">અન્ય રમતો</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                                        <div className="footer-menu">
                                            <h6><span className='footer-after-half-border'>HOT</span> <b>ON SOCIAL</b></h6>
                                            <ul>
                                                <li><a href="#.">ગુજરાતી સિનેમા</a></li>
                                                <li><a href="#.">કેરળ ચૂંટણી</a></li>
                                                <li><a href="#.">વેબ સિરીઝ</a></li>
                                                <li><a href="#.">ટેલિવિઝન</a></li>
                                                <li><a href="#.">બોલિવૂડ</a></li>
                                                <li><a href="#.">ગુજરાતી સિનેમા</a></li>
                                                <li><a href="#.">કેરળ ચૂંટણી</a></li>
                                                <li><a href="#.">વેબ સિરીઝ</a></li>
                                                <li><a href="#.">ટેલિવિઝન</a></li>
                                                <li><a href="#.">બોલિવૂડ</a></li>
                                                <li><a href="#.">ગુજરાતી સિનેમા</a></li>
                                                <li><a href="#.">કેરળ ચૂંટણી</a></li>
                                                <li><a href="#.">વેબ સિરીઝ</a></li>
                                                <li><a href="#.">ટેલિવિઝન</a></li>
                                                <li><a href="#.">બોલિવૂડ</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                        <div className="footer-menu">
                                            <h6><span className='footer-after-half-border'>POPULAR</span> <b>CATEGORIES</b></h6>
                                            <ul>
                                                <li><a href="#.">અમદાવાદ</a></li>
                                                <li><a href="#.">ભાવનગર</a></li>
                                                <li><a href="#.">સુરત</a></li>
                                                <li><a href="#.">વડોદરા</a></li>
                                                <li><a href="#.">રાજકોટ</a></li>
                                                <li><a href="#.">અમદાવાદ</a></li>
                                                <li><a href="#.">ભાવનગર</a></li>
                                                <li><a href="#.">સુરત</a></li>
                                                <li><a href="#.">વડોદરા</a></li>
                                                <li><a href="#.">રાજકોટ</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-12 col-lg-12 col-xl-5 footer-right">
                                <div className="row">
                                    <div className="col col-12 col-md-6 col-sm-6">
                                        <div className="footer-menu">
                                            <h6><span className='footer-after-half-border'>QUICK</span> <b>LINKS</b></h6>

                                            <ul>
                                                <li><a href="#.">રાષ્ટ્રીય</a></li>
                                                <li><a href="#.">ટેકનોલોજી</a></li>
                                                <li><a href="#.">બિઝનેસ</a></li>
                                                <li><a href="#.">ફોટો ગેલેરી</a></li>
                                                <li><a href="#.">ધરતીપુત્ર-કૃષિ</a></li>
                                                <li><a href="#.">કારકિર્દી</a></li>
                                                <li><a href="#.">રાષ્ટ્રીય</a></li>
                                                <li><a href="#.">ટેકનોલોજી</a></li>
                                                <li><a href="#.">બિઝનેસ</a></li>
                                                <li><a href="#.">ફોટો ગેલેરી</a></li>
                                                <li><a href="#.">ધરતીપુત્ર-કૃષિ</a></li>
                                                <li><a href="#.">કારકિર્દી</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col col-12 col-md-6 col-sm-6">
                                        <div className="footer-menu">
                                            <h6><span className='footer-after-half-border'>NETWORK</span> <b>GROUP</b></h6>

                                            <ul>
                                                <li><a href="#.">વેબ સિરીઝ</a></li>
                                                <li><a href="#.">ફોટો ગેલેરી</a></li>
                                                <li><a href="#.">ટેલિવિઝન</a></li>
                                                <li><a href="#.">તમિલનાડુની ચૂંટણી</a></li>
                                                <li><a href="#.">વેબ સિરીઝ</a></li>
                                                <li><a href="#.">ફોટો ગેલેરી</a></li>
                                                <li><a href="#.">ટેલિવિઝન</a></li>
                                                <li><a href="#.">તમિલનાડુની ચૂંટણી</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="footer-livetv text-center">
                                    <h4>LIVE TV <a className="btn btn-primary" href="#."><i className="fas fa-tv"></i>VIEW</a></h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>

                <div id="footer_bottom">
                    <div className='bg-black w-100vw'>
                        <div className="container">
                            <div className="footer-bottom">
                                <div className="footer-bottom-responsive">
                                    <div className="row">
                                        <div className="footer-logo col-4 col-md-2 col-lg-2 col-xl-2">
                                            <div>
                                                {
                                                    props.mode === 'light' ?
                                                        <img loading="lazy" src="https://i0.wp.com/assets.sandesh.com/im/logo-b.png?w=260" alt="" /> :
                                                        <img loading="lazy" src="https://assets.sandesh.com/im/logo.png" alt="" />
                                                }
                                            </div>
                                        </div>
                                        <div className="col-8 col-md-10 col-lg-10 col-xl-5 mx-width-footer">
                                            {
                                                footer && Object.values(footer).map((els, idx) => (
                                                    <li className="list-inline-item">
                                                        {
                                                            els?.open === 1 ?
                                                                <a key={Math.random()} target="_blank" href={`${els?.url}`} rel="noreferrer" >{els?.name} </a> :
                                                                els?.page === 'investor-relations' ? 
                                                                <Link key={Math.random()} to={`/investor-relations`} >{els?.name} </Link> :
                                                                <Link key={Math.random()} to={`/page/${els?.page}`} >{els?.name} </Link> 
                                                        }
                                                    </li>
                                                ))
                                            }
                                        </div>
                                        <div className="col-6 col-md-6 col-lg-6 col-xl-3 footer-bottom-responsive-socials">
                                            <div className="follow-us">
                                                <p>Follow us on <br /><a href="#."><i className="fab fa-facebook"></i></a><a href="#."><i className="fab fa-twitter"></i></a><a href="#."><i className="fab fa-instagram"></i></a><a href="#."><i className="fab fa-youtube"></i></a></p>
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-6 col-lg-6 col-xl-2 footer-bottom-responsive-socials">
                                            <div className="download-app">
                                                <p>Download app <br /><a href="#."><i className="fab fa-android"></i></a><a href="#."><i className="fab fa-apple"></i></a></p>
                                            </div>
                                        </div>
                                    </div>
                                    <hr className='footer-hr' />
                                    <div className="row">
                                        <div className="col-md-10">
                                            Sandesh is a leading daily newspaper in Gujarat. It is published in Gujarati and was founded in 1923. The Ahmedabad-based Gujarati newspaper is also published from Mumbai, Vadodara, Bhuj, Surat, Rajkot and Bhavnagar. Sandesh has 7 supplements published: Sanskar, Nari, Ardha Saptahik, Nakshatra, Cine Sandesh, Kids World, Shraddha, Action Replay, and Business@sandesh. The Sandesh also publishes Gujarati weekly and Sandesh International from Chicago, Illinois, United States.
                                        </div>
                                        <div className="col-md-2">
                                            <img loading="lazy" src="https://assets.sandesh.com/im/astro/certificate.png" alt="" className="img-responsive certi" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {pathname === "/" ? <HomeTaboola key={window.location.href} /> : ''}
            </section> */}
        </>
    )
}

export default Footer
