import { LOGING_FETCH_DATA_SUCCESS, LOGING_HAVE_ERROR, LOGING_ARE_LOADING } from '../actionType';
import HttpService from '../../utils/httpService';
import { Post } from '../../services/api.service'

export function LogingAction(action, payload) {
    switch (action) {
        case LOGING_HAVE_ERROR:
            return {
                type: action,
                hasError: payload
            };

        case LOGING_ARE_LOADING:
            return {
                type: action,
                isLoading: payload
            };

        case LOGING_FETCH_DATA_SUCCESS:
            return {
                type: action,
                Login: payload,
            };

        default:
            return;
    }
}

export function LogingFetchData(userData, otp = undefined) {
    return (dispatch) => {
        try {
            if (!otp) {
                return new Promise((resolve, reject) => {
                    dispatch(LogingAction(LOGING_ARE_LOADING, true));

                    Post(HttpService.loginCheck_v2, userData).then((result) => {
                        dispatch(LogingAction(LOGING_FETCH_DATA_SUCCESS, result));
                        resolve(result);
                    }, (error) => {
                        reject(error);
                    });
                });
            } else {
                return new Promise((resolve, reject) => {
                    dispatch(LogingAction(LOGING_ARE_LOADING, true));

                    Post(HttpService.otpCheck_v2, userData).then((result) => {
                        dispatch(LogingAction(LOGING_FETCH_DATA_SUCCESS, result));
                        resolve(result);
                    }, (error) => {
                        reject(error);
                    });
                });
            }
        } catch (error) {
            dispatch(LogingAction(LOGING_HAVE_ERROR, true));
        }
    }
}

export function SignupFetchData(userSignup) {
    return (dispatch) => {
        try {
            return new Promise((resolve, reject) => {
                dispatch(LogingAction(LOGING_ARE_LOADING, true));
                Post(HttpService.signup_V2, userSignup).then((result) => {
                    dispatch(LogingAction(LOGING_FETCH_DATA_SUCCESS, result));
                    resolve(result);
                }, (error) => {
                    reject(error);
                });
            });
        } catch (error) {
            dispatch(LogingAction(LOGING_HAVE_ERROR, true));
        }
    }
}

export function SigninFetchData(userSignin) {
    return (dispatch) => {
        try {
            return new Promise((resolve, reject) => {
                dispatch(LogingAction(LOGING_ARE_LOADING, true));
                Post(HttpService.login, userSignin).then((result) => {
                    dispatch(LogingAction(LOGING_FETCH_DATA_SUCCESS, result));
                    resolve(result);
                    if (result?.user_data) {
                        localStorage.setItem('access_token', result?.user_data?.token);
                        localStorage.setItem('userInfo', JSON.stringify(result));
                        localStorage.setItem('userID', JSON.stringify(result?.user_data?.id));
                    }
                }, (error) => {
                    reject(error);
                });
            });
        } catch (error) {
            dispatch(LogingAction(LOGING_HAVE_ERROR, true));
        }
    }
}

export function signInSaveData(token, userSignin) {
    return (dispatch) => {
        try {
            return new Promise((resolve, reject) => {
                if (token) localStorage.setItem('access_token', token);
                dispatch(LogingAction(LOGING_FETCH_DATA_SUCCESS, userSignin));
                resolve(userSignin);
            });
        } catch (error) {
            dispatch(LogingAction(LOGING_HAVE_ERROR, true));
        }
    }
}