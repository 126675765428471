/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState } from 'react'
import Dailymotion from 'react-dailymotion';
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux';
import ReactGA from "react-ga4";
import CommonvideoContent from '../CommonvideoContent';
import YouTube from 'react-youtube';
import InjectScript from '../CustomScript';


const homevidcrunch = ''+
'<script async id="AV65a444a5fc8f9843480d85c8" type="text/javascript" src=" https://tg1.vidcrunch.com/api/adserver/spt?AV_TAGID=65a444a5fc8f9843480d85c8&AV_PUBLISHERID=5b8cff1a28a06149a014c4b0"></script>'+
''

const Videos = () => {
    const { 21: assetbaseUrl } = useSelector((state) => state?.SettingReducer?.data);
    const Videoslist = useSelector((state) => state?.HomeSecondaryReducer?.data?.Videos);

    const [vidId, setvidId] = useState('');
    const [vidTitle, setvidTitle] = useState('');
    const [vidTime, setvidTime] = useState('');

    const VideoModalOpen = (VidDataId, VidDataTitle, VidDataTime) => {
        setvidId(VidDataId);
        setvidTitle(VidDataTitle);
        setvidTime(VidDataTime);
        document.getElementById("videoPoPup1").classList.add('active-modal');
        document.body.style.overflow = 'hidden';
    }

    const VideoModalClose = () => {
        setvidId('');
        document.body.style.overflow = 'auto';
        document.getElementById("videoPoPup1").classList.remove('active-modal');
    }


    const opts = {
        height: '100%',
        width: '100%',
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 0,
            modestbranding: 1,
        },
    };
    const optsModal = {
        height: '100%',
        width: '100%',
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 0,
            modestbranding: 1,
        },
    };

    return (
        <>
            <section className="videos bg-gray">
                <div className="container mb-3-half">
                    <div className="">
                        <span className="badge badge-info">Videos</span>
                        <Link onClickCapture={() => ReactGA.event({ category: 'Homepage', action: 'Videos', label: 'Read More' })} to="/videos" className="readmore">View All</Link>
                    </div>
                    <div className="row">
                        <div className="col-md-3 relateds borderVideoBottom gujarat-responsive-space-sm ">
                            {/* <a href="#" className="readmore">View All</a> */}
                            {
                                Videoslist?.length > 0 && Videoslist?.map((els, idx) => (
                                    <>
                                        {(idx === 1 || idx === 2) ?
                                            <>
                                                {els?.post_type === 4 ?
                                                    <div className='mb-3 cursor-pointer' onClickCapture={() => { VideoModalOpen(els?.video_yt, els?.title, els?.publish_date); }}>
                                                        <CommonvideoContent dataclass2 dataclass='top10' src={`${assetbaseUrl?.value}${els?.media}`} post_type={els?.post_type} time={els?.time} publish_date={els?.publish_date} category={els?.category} title={els?.title} />
                                                    </div>
                                                    :
                                                    <div className='mb-3'>
                                                        <CommonvideoContent dataclass2 url={els?.url} dataclass='top10' src={`${assetbaseUrl?.value}${els?.media}`} post_type={els?.post_type} time={els?.time} publish_date={els?.publish_date} category={els?.category} title={els?.title} />
                                                    </div>
                                                }
                                            </>
                                            : ""}
                                    </>
                                ))
                            }


                        </div>
                        <div className="col-md-6 mt-04 borderoverlap">
                            {
                                Videoslist?.length > 0 && Videoslist?.map((els, idx) => (
                                    <>
                                        {idx === 0 ?
                                            <>
                                                <div className='bordersLeftRight'>
                                                    <div className='border-video'>
                                                        <div className='iframePlay'>

                                                            {/* <div id="home_outstream" className='home_outstream' />
                                                            HOmepage Adsolut tags
                                                            */}

                                                            <InjectScript script={homevidcrunch} />

                                                            {/* <iframe
                                                                defer
                                                                loading="lazy"
                                                                style={{ width: '100%', height: '100%' }}
                                                                src={`https://www.youtube.com/embed/${els?.video_yt}?autoplay=true&mute=1`}
                                                                allow="fullscreen; picture-in-picture" allowfullscreen frameborder="0"
                                                                title={els?.title}>
                                                            </iframe> */}
                                                            {/* <iframe
                                                                src={`https://geo.dailymotion.com/player/x81oh.html?mute=true&video=${els?.video?.split('/')?.reverse()[0]}`}
                                                                low="autoplay; fullscreen; picture-in-picture"
                                                                allowFullScreen
                                                                frameBorder="0"
                                                                style={{ width: '100%', height: '100%' }}
                                                            /> */}
                                                            {/* <YouTube videoId="CVxjMPK8sFU" opts={opts} /> */}

                                                        </div>
                                                    </div>
                                                    <div className="video-content-main">
                                                        {/* <p className=" font-size-video-title mt-1 font-weight-bold pt-1 ">{els?.title}</p> */}
                                                    </div>
                                                </div>
                                            </>
                                            : ""}
                                    </>
                                ))
                            }



                            {/* <Dailymotion
                                    className="dailymotion-home-page"
                                    video="x878rx1"
                                    showEndScreen={true}
                                    uiTheme="light"
                                    // onPlay={() => setPlay(true)}
                                    // onPause={() => setPlay(false)}
                                    autoplay="off"
                                    uiShowLogo="false"
                                /> */}

                            {/* {!play && <div className="video-content">
                                    <div className="blog-details font-size-gujrat-title">
                                        <h6 className="m-0 py-1">જાન્યુઆરીના સૌથી મોટા ટેકવેઝ જાન્યુઆરીના સૌથી મોટા ટેકવેઝ જાન્યુઆરીના સૌથી મોટા ટેકવેઝ જાન્યુઆરીના સૌથી મોટા ટેકવેઝ જાન્યુઆરીના સૌથી મોટા ટેકવેઝ જાન્યુઆરીના સૌથી મોટા ટેકવેઝ...</h6>
                                    </div>
                                </div>
                                } */}

                        </div>
                        <div className="col-md-3 relateds borderVideoBottom gujarat-responsive-space-sm ">
                            {
                                Videoslist?.length > 0 && Videoslist?.map((els, idx) => (
                                    <>
                                        {(idx === 3 || idx === 4) ?
                                            <>
                                                {els?.post_type === 4 ?
                                                    <div className='mb-3' onClickCapture={() => { VideoModalOpen(els?.video_yt, els?.title, els?.publish_date); }}>
                                                        <CommonvideoContent dataclass2 dataclass='top10' src={`${assetbaseUrl?.value}${els?.media}`} post_type={els?.post_type} time={els?.time} publish_date={els?.publish_date} category={els?.category} title={els?.title} />
                                                    </div>
                                                    :
                                                    <div className='mb-3'>
                                                        <CommonvideoContent dataclass2 url={els?.url} dataclass='top10' src={`${assetbaseUrl?.value}${els?.media}`} post_type={els?.post_type} time={els?.time} publish_date={els?.publish_date} category={els?.category} title={els?.title} />
                                                    </div>
                                                }
                                            </>
                                            : ""}
                                    </>
                                ))
                            }

                        </div>
                    </div>
                </div>
                <div id="videoPoPup1" className="videoSidebar">
                    <div className="closebtn" onClick={() => VideoModalClose()} >
                        <i className="fas fa-times"></i>
                    </div>
                    {/* <Dailymotion
                        className="dailymotion-videoPoPup-page"
                        video={`${vidId}`}
                        showEndScreen={true}
                        uiTheme="light"
                        autoplay
                        uiShowLogo="false"
                        origin=""
                    /> */}
                    <YouTube className="dailymotion-videoPoPup-page" videoId={vidId} opts={optsModal} />

                    {/* <div className="videoPoPup-details">
                        <p>{vidTitle}</p>
                        <span><i className="far fa-clock"></i>&nbsp;{vidTime}</span>
                    </div> */}
                </div>
            </section>
        </>
    )
}

export default Videos
