/* eslint-disable no-restricted-globals */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import { useDispatch } from 'react-redux';
import { profileFetchData, profileUpdateData } from '../../redux/actions/commonAction';
import { Post } from '../../services/api.service';
import HttpService from '../../utils/httpService';
import { useNavigate } from 'react-router-dom';

function Profile() {
    const currentDate = new Date().toISOString().slice(0, 10).replace(/-/g, "-");
    const token = document.body.getAttribute("data-token");
    const [user, setUser] = useState({
        country: 103,
        userName: '',
        userEmail: '',
        userAddress: '',
        userMobileNumber: '',
        userDob: '',
        userGender: '',
        Residencetype: '',
        FatherName: '',
        FatherNumber: '',
        spousename: '',
        spousemobile: '',
        Relationshipstatus: '',
        FamilyMemberCount: '',
        anniversarydate: '',
        token: localStorage.getItem("SandeshToken"),
    });
    const dispatch = useDispatch();
    const [message, setMessage] = useState()
    const [loading, setLoading] = useState(true);
    const [paymentLoading, setPaymentLoading] = useState(true);
    const [paymentData, setPaymentData] = useState({});
    let navigate = useNavigate();

    const fetchPayments = async () => {
        setPaymentLoading(true);
        try {
            const data = await Post(HttpService.epaperSubscriptionList_V2, { user_id: localStorage.getItem('access_token') });
            setPaymentData(data);
            setPaymentLoading(false);
        } catch (error) {
            setTimeout(() => {
                fetchPayments();
            }, 1000);
        }
    };

    const cancelPayments = async () => {
        if (confirm('Are you sure you want to cancel?')) {
            setPaymentLoading(true);
            try {
                await Post(HttpService.epaperSubscriptionCancel_V2, { user_id: localStorage.getItem('access_token') });
                fetchPayments();
            } catch (error) {
                setTimeout(() => {
                    cancelPayments();
                }, 1000);
            }
        }
    };

    const buyPackage = () => {
        navigate('/epaper/ahmedabad', { state: { is_buy: true } });
    }

    React.useEffect(() => {
        fetchPayments();
        $("#rstatus").change(function () {
            if (this.value === '3') {
                $('#infoshow').show();
            } else {
                $('#infoshow').hide();

            }
        })
        if (localStorage.getItem('access_token') !== null) {
            dispatch(profileFetchData()).then((res) => {
                if (res?.user_data) {
                    setLoading(false);
                    setUser({
                        ...res?.user_data,
                        userName: res?.user_data?.name,
                        userEmail: res?.user_data?.email,
                        userAddress: res?.user_data?.address,
                        userMobileNumber: res?.user_data?.mobile,
                        userDob: res?.user_data?.dob,
                        userGender: +res?.user_data?.gender,
                        Residencetype: res?.user_data?.residence_type,
                        FatherName: res?.user_data?.father_name,
                        FatherNumber: res?.user_data?.father_mobile,
                        spousename: res?.user_data?.spouse_name,
                        spousemobile: res?.user_data?.spouse_mobile,
                        Relationshipstatus: res?.user_data?.relationship_status,
                        FamilyMemberCount: res?.user_data?.family_members,
                        anniversarydate: res?.user_data?.anniversary_date,
                        gender: +res?.user_data?.gender,
                        token: localStorage.getItem("SandeshToken"),
                    })
                } else {
                    window.location.href = '/';
                }
            }).catch(() => {
                window.location.href = '/';
            });
        }
    }, [])

    const updateProfile = async (e) => {
        e.preventDefault();
        const response = await dispatch(profileUpdateData(user))
        // dispatch(profileUpdateData(user));
        if (response) {
            setMessage(response?.message)
            window.location.reload();
        }
        setTimeout(() => {
            setMessage(undefined)
        }, 5000);
    }

    const uncheck = () => {
        document.getElementById("rent").checked = false;
        document.getElementById("own").checked = false;
        setUser({ Residencetype: 'none' });
    }

    const maxLengthCheck = (e) => {
        if (e.target.value.length > e.target.maxLength) {
            e.target.value = e.target.value.slice(0, e.target.maxLength)
        }
    }
    return (
        <>
            <section className={`profile ${loading === true ? 'profile_opacity' : ''}`}>
                <div className="container">
                    <div className="row my-5">
                        <div className="col-md-12">
                            <div className="sidebar">
                                <ul className="nav nav-tabs">
                                    <li className="nav-item">
                                        <a className="nav-link active" href="#pro" data-toggle="tab">Profile Details</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="#detail" data-toggle="tab">Subscription Details</a>
                                    </li>
                                </ul>
                                <div className="tab-content clearfix">
                                    <div className="tab-pane active" id="pro">
                                        <div className="row bb">
                                            <div className="col-lg-10">
                                                <div className="mainhd">
                                                    <span className="color">Profile Details</span>
                                                </div>
                                                <form>
                                                    <div className="form-group">
                                                        <input type="text" value={user?.userName} onChange={(e) => setUser({ ...user, userName: e.target.value })} className="form-control txtfield" id="txtuser123" placeholder="Name" required />
                                                    </div>
                                                    <div className="form-group">
                                                        {/* {(localStorage.getItem("socialLogin") === 'Facebook' || localStorage.getItem("socialLogin") === 'Google') ? ( */}
                                                        {/* <input type="email" value={user?.userEmail} disabled onChange={(e) => setUser({ ...user, userEmail: e.target.value })} className="form-control txtfield" id="email" placeholder="Email" required /> */}
                                                        {/* ) : ( */}
                                                        <input type="email" value={user?.userEmail} disabled={user?.country != 103} onChange={(e) => setUser({ ...user, userEmail: e.target.value })} className="form-control txtfield" id="email" placeholder="Email" required />
                                                        {/* )} */}

                                                    </div>
                                                    <div className="form-group">
                                                        <input type="number" maxLength={11} disabled={user?.country == 103} onInput={maxLengthCheck} value={user?.userMobileNumber} onChange={(e) => setUser({ ...user, userMobileNumber: e.target.value })} className="form-control txtfield" id="mobile" placeholder="Mobile" required />
                                                    </div>
                                                    <div className="form-group">
                                                        {/* <input type="text" className="form-control txtfield textbox-n" id="txtbirthday" placeholder="Birthdate" onFocus="(this.type='date')" onblur="if(this.value==''){this.type='text'}" id="date" /> */}

                                                        <input max={new Date(new Date().setFullYear(new Date().getFullYear() - 18)).toISOString().split('T')[0]} placeholder="Birthdate" value={user?.userDob} onChange={(e) => setUser({ ...user, userDob: e.target.value })} style={{ boxShadow: "none" }} type="date" id="date-picker-example" className="form-control datepicker date_input"></input>

                                                    </div>
                                                    <div className="form-group txtfield" onChange={(e) => setUser({ ...user, userGender: e.target.value })}>
                                                        <label className="form-check-label">Gender :</label>
                                                        <div className="custom-control custom-radio">
                                                            {user?.userGender === 1 ? (
                                                                <input type="radio" id="customRadio1" checked value={1} name="gender" className="custom-control-input" />
                                                            ) : (
                                                                <input type="radio" id="customRadio1" value={1} name="gender" className="custom-control-input" />
                                                            )}
                                                            <label className="custom-control-label" for="customRadio1">Male</label>
                                                        </div>

                                                        <div className="custom-control custom-radio">
                                                            {user?.userGender === 2 ? (
                                                                <input type="radio" id="customRadio2" checked value={2} name="gender" className="custom-control-input" />
                                                            ) : (
                                                                <input type="radio" id="customRadio2" value={2} name="gender" className="custom-control-input" />
                                                            )}
                                                            <label className="custom-control-label" for="customRadio2">Female</label>
                                                        </div>
                                                        {/* {console.log('first ghg', typeof user?.gender)} */}
                                                        <div className="custom-control custom-radio">
                                                            {user?.userGender === 3 ? (
                                                                <input type="radio" id="customRadio3" checked value={3} name="gender" className="custom-control-input" />
                                                            ) : (
                                                                <input type="radio" id="customRadio3" value={3} name="gender" className="custom-control-input" />
                                                            )}
                                                            <label className="custom-control-label" for="customRadio3">Other</label>
                                                        </div>
                                                    </div>

                                                    <button type="button" onClick={(e) => { updateProfile(e) }} className="btn btn-primary btn-lg btn-block BlueButton">Complete Profile</button>
                                                    {message && <span class="msg-error error" style={{ color: 'green', display: 'block', textAlign: 'center' }}>{message}</span>}
                                                    {/* <button onClick={(e) => {updateProfile(e)}} type="submit" className="btn btn-primary btn-lg btn-block BlueButton">Complete Profile</button> */}
                                                    {/* <div><br />
                                                        <label className="TxtFeildBottomLeft form-check-label mt-2" for="exampleCheck1"> Fill in the fields above for seamless access</label>
                                                    </div> */}
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane" id="detail">
                                        <div className="row bb">
                                            <div className="col-lg-10">
                                                <div className="mainhd">
                                                    <span className="color">Subscription Details</span>
                                                </div>
                                                <div className='payments'>
                                                    {paymentLoading ? <span className='loading-round' /> :
                                                        <>
                                                            {paymentData?.main?.plan ?
                                                                <>
                                                                    <div className={`cancel-subscription ${paymentData?.main?.is_subscribed == "pending" ? `pending` : ``}`}>
                                                                        <div>
                                                                            <h3>Subscription</h3>
                                                                            <p>Package: {paymentData?.main?.plan}</p>
                                                                            <p>Expiry Date: {paymentData?.main?.exp_date}</p>
                                                                        </div>
                                                                        {paymentData?.main?.is_subscribed == "pending" ?
                                                                            <span>{paymentData?.main?.pending_text}</span>
                                                                            :
                                                                            (paymentData?.main?.is_subscribed) ?
                                                                                <button type='button' onClick={cancelPayments} className='btn btn-lg btn-primary'>Cancel</button>
                                                                                : <button type='button' onClick={buyPackage} className='btn btn-lg btn-primary'>Buy</button>}
                                                                    </div>

                                                                    <div className='subscription-history'>
                                                                        <h3>History</h3>
                                                                        {(paymentData?.history && paymentData?.history.length) ?
                                                                            paymentData?.history.map(x => {
                                                                                return <div className='payment-package'>
                                                                                    <div><b>Package</b>{x.package}</div>
                                                                                    <div><b>Start Date</b>{x.start_date}</div>
                                                                                    <div><b>End Date</b>{x.end_date}</div>
                                                                                </div>
                                                                            }) :
                                                                            <p className='alert-msg'>No history found...!</p>}
                                                                    </div>
                                                                </>
                                                                : <p className='alert-msg'>No subscription found...!</p>}
                                                        </>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Profile;
