import { CATEGORY_FETCH_DATA_SUCCESS, CATEGORY_HAVE_ERROR, CATEGORY_ARE_LOADING } from '../actionType';

import HttpService from '../../utils/httpService';
import { Post, Get } from '../../services/api.service'

export function CategoryAction(action, payload) {
    switch (action) {
        case CATEGORY_HAVE_ERROR:
            return {
                type: action,
                hasError: payload
            };

        case CATEGORY_ARE_LOADING:
            return {
                type: action,
                isLoading: payload
            };

        case CATEGORY_FETCH_DATA_SUCCESS:
            return {
                type: action,
                Category: payload,
            };

        default:
            return;
    }
}

export function CategoryFetchData(type = "", payload = {}, params = '') {
    return (dispatch) => {
        let myurl = HttpService.category + params
        if (type === "tag") {
            myurl = HttpService.tag;
        }
        if (type === "location") {
            myurl = HttpService.categoryLocation;
        }
        if (type === "category") {
            myurl = HttpService.category;
        }
        if (type === "ganesh") {
            myurl = HttpService.ganesh;
        }
        try {
            return new Promise((resolve, reject) => {
                dispatch(CategoryAction(CATEGORY_ARE_LOADING, true));
                if (type === 'location') {
                    Post(myurl, payload).then((result) => {
                        const obje = {
                            ...result,
                            ...result?.data,
                            count: result?.count,
                            name: result?.name,
                            message: result?.message,
                        }
                        dispatch(CategoryAction(CATEGORY_FETCH_DATA_SUCCESS, obje));
                        resolve(result);
                    }, (error) => {
                        reject(error);
                    });
                } else {
                    Get(myurl + params).then((result) => {
                        const obje = {
                            ...result,
                            ...result?.data,
                            count: result?.count,
                            name: result?.name,
                            message: result?.message,
                        }
                        dispatch(CategoryAction(CATEGORY_FETCH_DATA_SUCCESS, obje));
                        resolve(result);
                    }, (error) => {
                        reject(error);
                    });
                }
            });
        } catch (error) {
            dispatch(CategoryAction(CATEGORY_HAVE_ERROR, true));
        }
    }
}