/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import Dailymotion from 'react-dailymotion';
// import CategoryTaboola from '../subcomponents/CategoryTaboola';
import HomeTaboola from '../subcomponents/HomeTaboola';
import { Helmet } from "react-helmet";
import HelperScript from '../../utils/helper';
import InjectScript from '../CustomScript';
import { homePrimaryFetchData } from '../../redux/actions/homePrimaryAction';
import { videoFetchData } from '../../redux/actions/videoAction';
import CommonContent from "../CommonContent";
import YouTube from 'react-youtube';

const VideosComponent = () => {
    const Livetv = useSelector((state) => state?.SettingReducer?.data?.[57]?.value);
    const { 21: assetbaseUrl } = useSelector((state) => state?.SettingReducer?.data);
    const video = useSelector((state) => state.VideoReducer?.data);
    // const { 161: firstright } = useSelector((state) => state?.widgetReducer?.data);
    // const { 162: secondright } = useSelector((state) => state?.widgetReducer?.data);
    // const { 39: thirdright } = useSelector((state) => state?.widgetReducer?.data);

    const [iframeSrc, setiframeSrc] = useState('');
    const [, setHomeGallaryDetails] = useState([]);
    let dispatch = useDispatch();
    useEffect(() => {
        dispatch(videoFetchData());
        dispatch(homePrimaryFetchData());
    }, [])
    useEffect(() => {
        setHomeGallaryDetails(video)
    }, [video])
    useEffect(() => {
        // setiframeSrc(`https://geo.dailymotion.com/player/x7jsd.html?video=${Livetv}`);
        setiframeSrc(`https://www.youtube.com/embed/${Livetv}?autoplay=true&mute=1`);
    }, [Livetv])

    const homePrimary = useSelector((state) => state?.HomePrimaryReducer.data);

    const [vidId, setvidId] = useState('');
    const [vidtype, setvidtype] = useState(false);
    const [vidTitle, setvidTitle] = useState('');
    const [vidTime, setvidTime] = useState('');

    const VideoModalOpen = (vid_id, VidDataId, VidDataTitle, VidDataTime) => {
        setvidtype(vid_id ? true : false);
        setvidId(vid_id || VidDataId);
        setvidTitle(VidDataTitle);
        setvidTime(VidDataTime);
        // setiframeSrc(`https://geo.dailymotion.com/player/x7jsd.html?video=${Livetv}&mute=true`);
        setiframeSrc(`https://www.youtube.com/embed/${Livetv}?autoplay=true&mute=1`);
        document.getElementById("videoPoPup").classList.add('active-modal');
        document.body.style.overflow = 'hidden';
    }
    const VideoModalClose = () => {
        setvidId('');
        document.body.style.overflow = 'auto';
        document.getElementById("videoPoPup").classList.remove('active-modal');
    }

    const opts = {
        height: '500px',
        width: '100%',
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 1,
            mute: 1,
            modestbranding: 1,
        },
    };
    const optsModal = {
        height: '100%',
        width: '100%',
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 1,
            modestbranding: 1,
        },
    };
    const taboolacontent = '' +
        '' +
        '<div id="taboola-right-rail-thumbnails"></div>' +
        '<script type="text/javascript">' +
        'window._taboola = window._taboola || [];' +
        '_taboola.push({' +
        "mode: 'thumbnails-rr'," +
        "container: 'taboola-right-rail-thumbnails'," +
        "placement: 'Right Rail Thumbnails'," +
        "target_type: 'mix'" +
        '});' +
        '</script>' +
        '' +
        '';

        const videoUnibot = '<div class="d-s-sub-news clearfix" style="text-align:center"> <div id="div-ub-sandesh"><div id="div-ub-sandesh"><script> window.unibots = window.unibots || { cmd: [] }; unibots.cmd.push(()=>{ unibotsPlayer("sandesh"); }); </script> </div> </div> </div>'

    return (
        <>
            <Helmet>
                <title>Videos in Gujarati | Viral Videos in Gujarat | Sandesh</title>
            </Helmet>

            <section className="video-sub isCategoryPage" style={{ overflowX: 'hidden' }}>
                <div className="mb-3">
                    <div className="container-fluid p-0">
                        <div className="row">
                            <div className="col-md-9  pr-0 marginright-video">
                                <a href="#." className="story ">
                                    {/* <Dailymotion
                                    className="dailymotion-home-page"
                                    video={`${mainVidId}`}
                                    showEndScreen={true}
                                    uiTheme="light"
                                    autoplay="off"
                                    uiShowLogo="false"
                                    origin="યુએસ આરોગ્ય સંભાળ વહીવટી ખર્ચ પર વ્યક્તિ દીઠ $2,500 ખર્ચે છે"
                                /> */}
                                    {iframeSrc ?
                                        <iframe loading="lazy"
                                            // key={Math.random()}
                                            key={iframeSrc}
                                            style={{ width: '100%', height: '100%' }}
                                            // src={`https://geo.dailymotion.com/player/x8q4n.html?video=${homePrimary?.Livetv}`} 
                                            src={iframeSrc}
                                            // src={`https://geo.dailymotion.com/player/x7jsd.html?video=${Livetv}`}
                                            // src={`https://www.youtube.com/embed/${homePrimary?.Livetv1}?autoplay=true&mute=1`}
                                            allow="autoplay; fullscreen; picture-in-picture" allowfullscreen frameborder="0"
                                            title="video"
                                            muted>
                                        </iframe>
                                        // <YouTube videoId="VmW8VgNOzpo" opts={opts} />

                                        : ''}

                                </a>
                            </div>
                            <div className='col-md-3 px-0 bg-black video-s-story'>
                                <div className="featured mt-2 div-height">
                                    <div className="s-story p-0">
                                        <div className='px-4 '>
                                            {
                                                video?.Latest?.length > 0 && video?.Latest?.[0]?.posts?.map((els, idx) => (
                                                    <>
                                                        {els?.post_type === 4 ?
                                                            <>
                                                                <div className='padding-video-right' to="" onClickCapture={() => { VideoModalOpen(els?.video, els?.video_yt, els?.title, els?.publish_date); }}>
                                                                    <CommonContent type="video" bg="black" dataclass2 dataclass3="video-page" dataclass='top10' src={`${assetbaseUrl?.value}${els?.media}`} post_type={els?.post_type} time={els?.time} category={els?.category} title={els?.title} />
                                                                </div>

                                                                {/* <Link className='padding-video-right' to="" onClickCapture={() => { VideoModalOpen(els?.video, els?.video?.split('/')?.reverse()[0], els?.title, els?.publish_date); }}>
                                                                    <img loading="lazy" src={!els?.media ? sandeshske : `${assetbaseUrl?.value}${els?.media}`} alt="" />
                                                                    <span className="button-hover subvideo-tagline video-time-over-image">{els?.time} </span>
                                                                    <p className="text-white font-size-video ellipsis">{els?.title} </p>
                                                                </Link> */}
                                                                <hr className='border-bt m-0' />
                                                            </>
                                                            :
                                                            <>
                                                                <CommonContent type="video" url={els?.url} dataclass='top10' src={`${assetbaseUrl?.value}${els?.media}`} post_type={els?.post_type} time={els?.time} category={els?.category} title={els?.title} />
                                                                {/* <Link className='padding-video-right' to={`/${els?.url}`}>
                                                                    <img loading="lazy" src={!els?.media ? sandeshske : `${assetbaseUrl?.value}${els?.media}`} alt="" />
                                                                    <span className="button-hover subvideo-tagline video-time-over-image">{els?.time} </span>
                                                                    <p className="text-white font-size-video ellipsis">{els?.title} </p>
                                                                </Link> */}
                                                                <hr className='border-bt m-0' />
                                                            </>
                                                        }
                                                    </>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='container'>
                        <div className='row mt-3'>
                            <div className='col-md-9'>
                                {video?.Latest?.[0]?.posts?.length > 0 ?

                                    <div className="shadow px-3 mb-3 bg-white rounded">
                                        <div className="single-blog mt-3">
                                            <span className="badge badge-warning">{video?.Latest[0]?.name}</span>
                                            <Link target="_parent" to={`/video/${video?.Latest[0]?.url}`} className="readmore px-2">View More</Link>
                                        </div>

                                        <div className="row substory">
                                            {
                                                video?.Latest?.length > 0 && video?.Latest?.[0]?.posts?.map((els, idx) => (
                                                    <>
                                                        {els?.post_type === 4 ?
                                                            <div className="col-md-4">
                                                                <div className="single-blog mx-2">
                                                                    <div className="main-blogs cursor-pointer" onClickCapture={() => { VideoModalOpen(els?.video, els?.video_yt, els?.title, els?.publish_date); }}>
                                                                        <div className="skeleton-sub mb-0">
                                                                            <img loading="lazy" style={{ width: '100%' }} src={`${assetbaseUrl?.value}${els?.media}`} className="img-responsive" alt="" />
                                                                            <div className='playicon-video'><i className="fas fa-play"></i></div>
                                                                            <span className="button-hover colorspan-top-trending left-video">{els?.time} </span>
                                                                        </div>
                                                                        <div className="blog-details">
                                                                            <p className="ellipsis">{els?.title}</p>
                                                                            <span className="blog-time"><i className="far fa-clock"></i>{els?.publish_date}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            :
                                                            <div className="col-md-4">
                                                                <div className="single-blog mx-2">
                                                                    <Link target="_parent" to={`/${els?.url}`} className="main-blogs">
                                                                        <div className="skeleton-sub mb-0">
                                                                            <img loading="lazy" style={{ width: '100%' }} src={`${assetbaseUrl?.value}${els?.media}`} className="img-responsive" alt="" />
                                                                            <div className='playicon-video'><i className="fas fa-play"></i></div>
                                                                            <span className="button-hover colorspan-top-trending left-video">{els?.time} </span>
                                                                        </div>
                                                                        <div className="blog-details">
                                                                            <p className="ellipsis">{els?.title}</p>
                                                                            <span className="blog-time"><i className="far fa-clock"></i>{els?.publish_date}</span>
                                                                        </div>
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        }
                                                    </>
                                                ))
                                            }
                                        </div>

                                    </div> : ''
                                }
                            </div>
                            <div className=' xxx col-3'>
                                {/* <div className="shadow bg-white rounded"> */}
                                <div className=" ad-side text-center">
                                    {/* {firstright?.[0]?.script &&  */}
                                    <div className="ad-section">
                                        <HelperScript argSmall={225} argLarge={161} />
                                        {/* <InjectScript script={firstright?.[0]?.script} /> */}
                                    </div>
                                    {/* } */}
                                    {/* {secondright?.[0]?.script &&  */}
                                    
                                    {/* second ad on video */}
                                    {/* <div className="ad-section"> tet
                                        <HelperScript argSmall={226} argLarge={162} /> */}
                                        {/* <InjectScript script={secondright?.[0]?.script} /> */}
                                    {/* </div> */}
                                    
                                    {/* } */}
                                    {/* <Helmet>
                                        <script src="https://www.cdc.gov/TemplatePackage/contrib/widgets/tp-widget-external-loader.js" >
                                        </script>
                                    </Helmet> */}
                                    {/* <div className='ad-side-head '>
                                        <div className="ad-side">
                                            <img loading="lazy" src="https://assets.sandesh.com/im/adside.jpg" alt="" />
                                        </div>
                                    </div> */}
                                </div>
                                {/* </div> */}
                            </div>
                        </div>
                        {/* <div className="container"> */}
                        {/* <span className="badge badge-danger">Don't Miss</span> */}
                        <div className="row">
                            <div className="col-md-12">
                                {video?.Mostshared?.[0]?.posts?.length > 0 ?
                                    <div className="shadow px-3 my-3 bg-white rounded mostshared">
                                        {/* <AdTwo className="my-3" /> */}
                                        <div className="single-blog mt-3">
                                            <span className="badge badge-warning">Most Shared</span>
                                            <Link target="_parent" to={`/video/${video?.Mostshared[0]?.url}`} className="readmore px-2">View More</Link>
                                        </div>
                                        <div className="row mb-3">
                                            {
                                                video?.Mostshared?.length > 0 && video?.Mostshared?.[0]?.posts?.map((els, idx) => (
                                                    <>
                                                        {els?.post_type === 4 ?
                                                            <div className="col-md-12 col-lg-3 col-xl-3 pdg">
                                                                <div className="cursor-pointer" onClickCapture={() => { VideoModalOpen(els?.video, els?.video_yt, els?.title, els?.publish_date); }}>
                                                                    <div className='img-post'>
                                                                        <div className="skeleton-sub mb-0">
                                                                            <img loading="lazy" src={`${assetbaseUrl?.value}${els?.media}`} alt="Notebook" style={{ width: '100%' }} />
                                                                            <div className='playicon-video-Most-Shared'><i className="fas fa-play"></i></div>
                                                                            <span className="button-hover video-most-share">{els?.time} </span>
                                                                        </div>
                                                                        <div className="content">
                                                                            <p className="m-0 ellipsis">{els?.title}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            :
                                                            <div className="col-md-12 col-lg-3 col-xl-3 pdg">
                                                                <Link target="_parent" to={`/${els?.url}`}>
                                                                    <div className='img-post'>
                                                                        <div className="skeleton-sub mb-0">
                                                                            <img loading="lazy" src={`${assetbaseUrl?.value}${els?.media}`} alt="Notebook" style={{ width: '100%' }} />
                                                                            <div className='playicon-video-Most-Shared'><i className="fas fa-play"></i></div>
                                                                            <span className="button-hover video-most-share">{els?.time} </span>
                                                                        </div>
                                                                        <div className="content">
                                                                            <p className="m-0 ellipsis">{els?.title}</p>
                                                                        </div>
                                                                    </div>
                                                                </Link>
                                                            </div>
                                                        }
                                                    </>
                                                ))
                                            }
                                        </div>
                                    </div>
                                    : ''
                                }
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-md-9'>
                                {
                                    video && Object.values(video)?.map((els, idx) => (
                                        <>
                                            {idx > 2 && els?.[0]?.posts?.length > 0 &&
                                                <div key={idx} className="shadow px-3 mb-3 bg-white rounded">
                                                    {/* <span className="badge badge-danger my-3">Don't Miss</span> */}
                                                    <div className="single-blog mt-3">
                                                        <span className="badge badge-warning">{els?.[0]?.name}</span>
                                                        <Link target="_parent" to={`/video/${els?.[0]?.url}`} className="readmore px-2">View More</Link>
                                                    </div>

                                                    <div className="row substory">

                                                        {
                                                            els?.[0]?.posts?.length > 0 && els?.[0]?.posts?.map((els, idx) => (
                                                                <>
                                                                    {els?.post_type === 4 ?
                                                                        <div className="col-md-4">
                                                                            <div className="single-blog mx-2">
                                                                                <div onClickCapture={() => { VideoModalOpen(els?.video, els?.video_yt, els?.title, els?.publish_date); }} className="main-blogs cursor-pointer">
                                                                                    <div className="skeleton-sub mb-0">
                                                                                        <img loading="lazy" style={{ width: '100%' }} src={`${assetbaseUrl?.value}${els?.media}`} className="img-responsive" alt="" />
                                                                                        <div className='playicon-video'><i className="fas fa-play"></i></div>
                                                                                        <span className="button-hover colorspan-top-trending left-video">{els?.time} </span>
                                                                                    </div>
                                                                                    <div className="blog-details">
                                                                                        <p className="ellipsis">{els?.title}</p>
                                                                                        <span className="blog-time"><i className="far fa-clock"></i>{els?.publish_date}</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        <div className="col-md-4">
                                                                            <div className="single-blog mx-2">
                                                                                <Link target="_parent" to={`/${els?.url}`} className="main-blogs">
                                                                                    <div className="skeleton-sub mb-0">
                                                                                        <img loading="lazy" style={{ width: '100%' }} src={`${assetbaseUrl?.value}${els?.media}`} className="img-responsive" alt="" />
                                                                                        <div className='playicon-video'><i className="fas fa-play"></i></div>
                                                                                        <span className="button-hover colorspan-top-trending left-video">{els?.time} </span>
                                                                                    </div>
                                                                                    <div className="blog-details">
                                                                                        <p className="ellipsis">{els?.title}</p>
                                                                                        <span className="blog-time"><i className="far fa-clock"></i>{els?.publish_date}</span>
                                                                                    </div>
                                                                                </Link>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </>
                                                            ))
                                                        }
                                                    </div>
                                                </div>}
                                                {
                                                    idx === 2 ?
                                                    <div>
                                                    <InjectScript script={videoUnibot} />
                                                </div> :''
                                                }
                                        </>
                                    ))
                                }

                                <div className='row ExtraTaboola'>

                                    {
                                        (window.innerWidth > 991) ?
                                            <HomeTaboola key={window.location.href} />
                                            : ''
                                    }
                                </div>
                            </div>

                            <div className='xxx col-3'>
                                <div className="featured innerPageVideo">
                                    <a href="javascript:void(0)" className="title">
                                        Trending Videos
                                    </a>
                                    <div className="s-story p-0">
                                        {
                                            homePrimary?.Trendingvideos?.length > 0 && homePrimary?.Trendingvideos?.map((els, idx) => (
                                                <>
                                                    {idx === 0 ?
                                                        <>
                                                            <Link target="_parent" to={`/${els?.url}`} className="img-subhome-main-video">
                                                                <div className='row w-100'>
                                                                    <div className='col-12 main'>
                                                                        <Image style={{ width: "100%", marginTop: '5px' }} src={`${assetbaseUrl?.value}${els?.media}`} alt="" />
                                                                        <span style={{ left: '15px' }} className="button-hover subvideo-tagline">{els?.time} </span>
                                                                        <p className='playicon-subhome'><i className="fas fa-play"></i></p>
                                                                    </div>
                                                                    <div className='col-12 mt-2 pr-0'>
                                                                        {/* <p className="color-red">{els?.tagline}</p> */}
                                                                        <p className="font-size-video ellipsis">{els?.title}</p>
                                                                    </div>
                                                                </div>
                                                            </Link><hr className='border-bt' />
                                                        </>
                                                        :
                                                        <>
                                                            {idx > 0 && idx < 5 &&
                                                                <>
                                                                    {els?.post_type === 4 ?
                                                                        <>
                                                                            <div onClickCapture={() => { VideoModalOpen(els?.video, els?.video_yt, els?.title, els?.publish_date); }}>
                                                                                <CommonContent type="video" dataclass2 dataclass='top10' src={`${assetbaseUrl?.value}${els?.media}`} post_type={els?.post_type} time={els?.time} publish_date={els?.publish_date} category={els?.category} title={els?.title} />
                                                                            </div>
                                                                            <hr className='border-bt' />
                                                                        </> :
                                                                        <>
                                                                            <CommonContent type="video" url={els?.url} dataclass='top10' src={`${assetbaseUrl?.value}${els?.media}`} post_type={els?.post_type} time={els?.time} publish_date={els?.publish_date} category={els?.category} title={els?.title} />
                                                                            <hr className='border-bt' />
                                                                        </>
                                                                    }
                                                                </>
                                                            }
                                                        </>
                                                    }
                                                </>
                                            ))
                                        }
                                    </div>
                                </div>


                                <div className='my-4 sidebar-taboola' key={window.location.href}>
                                    <InjectScript type="taboola" script={taboolacontent} key={window.location.href} />
                                    <HelperScript argLarge={261} />
                                </div>
                            </div>
                        </div>


                        {
                            (window.innerWidth < 991) ?
                                <HomeTaboola key={window.location.href} />
                                : ''
                        }
                        {/* <AdTwo className="my-3" /> */}
                        <div className='row mt-3'>
                            {/* <div className='col-md-9'>
                            <div className="shadow p-3 mb-3 bg-white rounded">
                                <div className="single-blog mt-3">
                                    <span className="badge badge-warning">Most Viewed</span>
                                </div>

                                <div className="row substory">

                                    {
                                        video?.Mostviewed?.length > 0 && video?.Mostviewed?.map((els, idx) => (
                                            <>
                                                <div className="col-md-4">
                                                    <div className="single-blog mx-2">
                                                        <Link target="_parent" to={`/${els?.url}`} className="main-blogs">
                                                            <img loading="lazy" style={{ width: '100%' }} src={`${assetbaseUrl?.value}${els?.media}`} className="img-responsive" alt="" />
                                                            <span className="button-hover colorspan-top-trending left-video">{els?.time} </span>
                                                            <div className="blog-details">
                                                                <p className="ellipsis">{els?.title}</p>
                                                                <span className="blog-time"><i className="far fa-clock"></i>{els?.publish_date}</span>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </>
                                        ))
                                    }
                                </div>
                            </div>
                        </div> */}
                            {/* <div className='col-md-3'>
                            <div className="shadow p-2 mb-3 bg-white rounded">
                                <div className='ad-side-head '>
                                    <div className="ad-side">
                                        <img loading="lazy" style={{ height: '585px' }} src={game} alt='' />
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        </div>
                        {/* <span className="badge badge-danger">Don't Miss</span> */}
                        <div className="row">
                            <div className="col-md-12">
                                {/* <AdTwo className="my-3" /> */}
                            </div>
                        </div>
                        {/* </div> */}
                    </div>
                    {/* <div id="videoPoPup" className="w3-sidebar w3-bar-block" style={{display: 'none'}} >
                    <button onClick={() => {VideoModalClose()}} className="w3-bar-item w3-button w3-large">Close &times;</button>
                    <a href="#" className="w3-bar-item w3-button">Link 1</a>
                    <a href="#" className="w3-bar-item w3-button">Link 2</a>
                    <a href="#" className="w3-bar-item w3-button">Link 3</a>
                </div> */}
                </div>
                <div id="videoPoPup" className="videoSidebar">
                    <div className="closebtn" onClick={() => VideoModalClose()} >
                        <i className="fas fa-times"></i>
                    </div>
                    {/* <Dailymotion
                        className="dailymotion-videoPoPup-page"
                        video={`${vidId}`}
                        showEndScreen={true}
                        uiTheme="light"
                        autoplay
                        uiShowLogo="false"
                        origin=""
                    /> */}
                    {vidtype ? 
                    // <><iframe className='yt_clear' src={`https://play.gumlet.io/embed/${vidId}?autoplay=1&mute=1&vast_tag_url=https://servg.playstream.media/api/adserver61/vast/?AV_PUBLISHERID=64a2c899d453167b2405d639&AV_CHANNELID=652d274d9cdbd36a840a0405&AV_URL=[URL_MACRO]&cb=[TIMESTAMP_MACRO]&AV_WIDTH=[WIDTH_MACRO]&AV_HEIGHT=[HEIGHT_MACRO]&logo=false`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="allowfullscreen"></iframe></>
                    <><iframe loading="lazy" className='yt_clear gumlet_clear' src={`https://play.gumlet.io/embed/${vidId}?autoplay=1&mute=1&vast_tag_url=https%3A%2F%2Fservx.playstream.media%2Fapi%2Fadserver%2Fvast3%2F%3FAV_PUBLISHERID%3D64a2c899d453167b2405d639%26AV_CHANNELID%3D6513e6dbda9d898c5703df35%26AV_URL%3D%5BURL_MACRO%5D%26cb%3D%5BTIMESTAMP_MACRO%5D%26AV_WIDTH%3D%5BWIDTH_MACRO%5D%26AV_HEIGHT%3D%5BHEIGHT_MACRO%5D`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="allowfullscreen" referrerpolicy="unsafe-url"></iframe></>
:
                     <YouTube className="dailymotion-videoPoPup-page" videoId={vidId} opts={optsModal} /> 
                    }

                    <div className="videoPoPup-details">
                        <p>{vidTitle}</p>
                        <span><i className="far fa-clock"></i>&nbsp;{vidTime}</span>
                    </div>
                </div>

            </section>
        </>
    )
}

export default VideosComponent

function Image(props) {
    return (
        <div className="imgWrapper">
            <img loading="lazy" className="img" src={props?.src} alt={props.title}  />
        </div>
    );
}
