import React, { useState } from 'react'

const PagesInvestorRelation = () => {
    const [active, setActive] = useState(2);

    const clickHandle = (e) => {
        setActive(active == e ? null : e);
    }

    return (
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="accordion" id="accordionExample">




                    <div class="accordion-item"><h2 class="accordion-header" id="heading__2"><button onClick={() => clickHandle(2)} className={`accordion-button ${active == 2 ? `` : `collapsed`}`} type="button" data-bs-toggle="collapse" data-bs-target="#collapse__2" aria-expanded="false" aria-controls="collapse__2">
        Home
    </button></h2><div id="collapse__2" className={`accordion-collapse collapse ${active == 2 ? `show` : ``}`} aria-labelledby="heading__2" data-bs-parent="#accordionExample"><div class="accordion-body"><ul><li><a href="https://epapercdn.sandesh.com/investors/Corporate_information.pdf" target="_blank">Corporate Information</a></li>
<li><a href="https://epapercdn.sandesh.com/investors/Details of Business.pdf" target="_blank">Details of Business</a></li>
<li><a href="https://epapercdn.sandesh.com/investor/home/SEBI%20Circular%20on%20Online%20Dispute%20Resolution.pdf" target="_blank">SEBI Circular on Online Dispute Resolution</a></li>
<li><a href="https://smartodr.in/login" target="_blank">Link to SMART ODR</a></li>
</ul>
</div></div></div>





<div class="accordion-item"><h2 class="accordion-header" id="heading__3"><button onClick={() => clickHandle(3)} className={`accordion-button ${active == 3 ? `` : `collapsed`}`} type="button" data-bs-toggle="collapse" data-bs-target="#collapse__3" aria-expanded="false" aria-controls="collapse__3">
        Financial and Annual Report
    </button></h2><div id="collapse__3" className={`accordion-collapse collapse ${active == 3 ? `show` : ``}`} aria-labelledby="heading__3" data-bs-parent="#accordionExample"><div class="accordion-body"><ul><li class="no-icons-l">A. Audited Financial Statement<ul><li class="no-icons-l">i. Financial Results<ul><li><a href="https://epapercdn.sandesh.com/investors/1. Financial Result_31.03.2020.pdf" target="_blank">Financial Result_31.03.2020</a></li>
<li><a href="https://epapercdn.sandesh.com/investors/2. Financial Result_31.03.2021.pdf" target="_blank">Financial Result_31.03.2021</a></li>
<li><a href="https://epapercdn.sandesh.com/investors/3. Financial Result_31.03.2022.pdf" target="_blank">Financial Result_31.03.2022</a></li>
<li><a href="https://epapercdn.sandesh.com/investors/4. Financial Result_31.03.2023.pdf" target="_blank">Financial Result_31.03.2023</a></li>
</ul>
</li>
<li class="no-icons-l">ii. Newspaper Publication<ul><li><a href="https://epapercdn.sandesh.com/investors/1. Newspaper publication for the Quarter and year ended 31-03-2020.pdf" target="_blank">Newspaper publication for the Quarter and year ended 31-03-2020</a></li>
<li><a href="https://epapercdn.sandesh.com/investors/2. Newspaper publication for the Quarter and year ended 31-03-2022.pdf" target="_blank">Newspaper publication for the Quarter and year ended 31-03-2022</a></li>
<li><a href="https://epapercdn.sandesh.com/investors/3. Newspaper publication for the Quarter and year ended 31-03-2023.pdf" target="_blank">Newspaper publication for the Quarter and year ended 31-03-2023</a></li>
</ul>
</li>
</ul>
</li>
<li class="no-icons-l">B. Unaudited Financial Statement<ul><li class="no-icons-l">i. Financial Results<ul><li><a href="https://epapercdn.sandesh.com/investors/1. Financial Result_30.06.2020.pdf" target="_blank">Financial Result_30.06.2020</a></li>
<li><a href="https://epapercdn.sandesh.com/investors/2. Financial Result_30.09.2020.pdf" target="_blank">Financial Result_30.09.2020</a></li>
<li><a href="https://epapercdn.sandesh.com/investors/3. Financial Result_31.12.2020.pdf" target="_blank">Financial Result_31.12.2020</a></li>
<li><a href="https://epapercdn.sandesh.com/investors/4. Financial Result_30.06.2021.pdf" target="_blank">Financial Result_30.06.2021</a></li>
<li><a href="https://epapercdn.sandesh.com/investors/5. Financial Result_30.09.2021.pdf" target="_blank">Financial Result_30.09.2021</a></li>
<li><a href="https://epapercdn.sandesh.com/investors/6. Financial Result_31.12.2021.pdf" target="_blank">Financial Result_31.12.2021</a></li>
<li><a href="https://epapercdn.sandesh.com/investors/7. Financial Result_30.06.2022.pdf" target="_blank">Financial Result_30.06.2022</a></li>
<li><a href="https://epapercdn.sandesh.com/investors/8. Financial Result_30.09.2022.pdf" target="_blank">Financial Result_30.09.2022</a></li>
<li><a href="https://epapercdn.sandesh.com/investors/9. Financial Result_31.12.2022.pdf" target="_blank">Financial Result_31.12.2022</a></li>
<li><a href="https://epapercdn.sandesh.com/investors/10. Financial Result_30.06.2023.pdf" target="_blank">Financial Result_30.06.2023</a></li>
<li><a href="https://epapercdn.sandesh.com/investors/Financial%20Result_30.09.2023_1.pdf" target="_blank">Financial Result_30.09.2023</a></li>
</ul>
</li>
<li class="no-icons-l">ii. Newspaper<ul><li><a href="https://epapercdn.sandesh.com/investors/1. Newspaper publication for the Quarter ended 30-06-2020.pdf" target="_blank">Newspaper publication for the Quarter ended 30-06-2020</a></li>
<li><a href="https://epapercdn.sandesh.com/investors/2. Newspaper publication for the Quarter ended 30-09-2020.pdf" target="_blank">Newspaper publication for the Quarter ended 30-09-2020</a></li>
<li><a href="https://epapercdn.sandesh.com/investors/3. Newspaper publication for the Quarter ended 31-12-2020.pdf" target="_blank">Newspaper publication for the Quarter ended 31-12-2020</a></li>
<li><a href="https://epapercdn.sandesh.com/investors/4. Newspaper publication for the Quarter ended 30-06-2021.pdf" target="_blank">Newspaper publication for the Quarter ended 30-06-2021</a></li>
<li><a href="https://epapercdn.sandesh.com/investors/5. Newspaper publication for the Quarter ended 30-09-2021.pdf" target="_blank">Newspaper publication for the Quarter ended 30-09-2021</a></li>
<li><a href="https://epapercdn.sandesh.com/investors/6. Newspaper publication for the Quarter ended 31-12-2021.pdf" target="_blank">Newspaper publication for the Quarter ended 31-12-2021</a></li>
<li><a href="https://epapercdn.sandesh.com/investors/7. Newspaper publication for the Quarter ended 30-06-2022.pdf" target="_blank">Newspaper publication for the Quarter ended 30-06-2022</a></li>
<li><a href="https://epapercdn.sandesh.com/investors/8. Newspaper publication for the Quarter ended 30-09-2022.pdf" target="_blank">Newspaper publication for the Quarter ended 30-09-2022</a></li>
<li><a href="https://epapercdn.sandesh.com/investors/9. Newspaper publication for the Quarter ended 31-12-2022.pdf" target="_blank">Newspaper publication for the Quarter ended 31-12-2022</a></li>
<li><a href="https://epapercdn.sandesh.com/investors/10. Newspaper publication for the Quarter ended 30-06-2023.pdf" target="_blank">Newspaper publication for the Quarter ended 30-06-2023</a></li>
<li><a href="https://epapercdn.sandesh.com/investors/Newspaper%20Publication%20for%20the%20Quarter%20ended%2030-09-2023.pdf" target="_blank">Newspaper publication for the Quarter ended 30-09-2023</a></li>
</ul>
</li>
</ul>
</li>
<li class="no-icons-l">C. Credit Ratings<ul><li><a href="https://epapercdn.sandesh.com/investors/Credit%20Rating.pdf" target="_blank">Details of Credit Ratings</a></li>
</ul>
</li>
<li class="no-icons-l">D. Annual Reports<ul><li><a href="https://epapercdn.sandesh.com/investors/Annual Report_2019-20.pdf" target="_blank">Annual Report_2019-20</a></li>
<li><a href="https://epapercdn.sandesh.com/investors/Annual Report_2020-21.pdf" target="_blank">Annual Report_2020-21</a></li>
<li><a href="https://epapercdn.sandesh.com/investors/Annual Report_2021-22.pdf" target="_blank">Annual Report_2021-22</a></li>
<li><a href="https://epapercdn.sandesh.com/investors/Sandesh%20AR%20Spread.pdf" target="_blank">Annual Report_2022-23</a></li>
</ul>
</li>
<li class="no-icons-l">E. Draft Annual Return<ul><li><a href="https://epapercdn.sandesh.com/investors/Draft Annual Return _2020-21.pdf" target="_blank">Draft Annual Return _2020-21</a></li>
<li><a href="https://epapercdn.sandesh.com/investors/Draft Annual Return _2021-22.pdf" target="_blank">Draft Annual Return _2021-22</a></li>
<li><a href="https://epapercdn.sandesh.com/investors/Draft Annual Return _2022-23.pdf" target="_blank">Draft Annual Return _2022-23</a></li>
</ul>
</li>
<li class="no-icons-l">F. Details of CSR Activities<ul><li><a href="https://epapercdn.sandesh.com/investors/Details_of%20CSR%20Activities.pdf" target="_blank">Details of CSR Activities</a></li>
</ul>
</li>
</ul>
</div></div></div>





<div class="accordion-item"><h2 class="accordion-header" id="heading__4"><button onClick={() => clickHandle(4)} className={`accordion-button ${active == 4 ? `` : `collapsed`}`} type="button" data-bs-toggle="collapse" data-bs-target="#collapse__4" aria-expanded="false" aria-controls="collapse__4">
        Corporate Governance
    </button></h2><div id="collapse__4" className={`accordion-collapse collapse ${active == 4 ? `show` : ``}`} aria-labelledby="heading__4" data-bs-parent="#accordionExample"><div class="accordion-body"><ul><li class="no-icons-l">A. Board of Directors<ul><li class="no-icons-l"><a href="https://epapercdn.sandesh.com/investors/i. Composition of Board.pdf" target="_blank">i. Composition of Board</a></li>
</ul>
</li>
<li class="no-icons-l">B. Committee<ul><li class="no-icons-l">i. Audit Committee<ul><li><a href="https://epapercdn.sandesh.com/investors/I. Composition of Audit Committee.pdf" target="_blank">Composition of Audit Committee</a></li>
<li><a href="https://epapercdn.sandesh.com/investors/II. Charter of Audit Committee.pdf" target="_blank">Charter of Audit Committee</a></li>
</ul>
</li>
<li class="no-icons-l">ii. Stakeholder Relationship Committee<ul><li><a href="https://epapercdn.sandesh.com/investors/I. Composition of Stakeholder Relationship Committee.pdf" target="_blank">Composition of Stakeholder Relationship Committee</a></li>
<li><a href="https://epapercdn.sandesh.com/investors/II. Charter of Stakeholder Relationship Committee.pdf" target="_blank">Charter of Stakeholder Relationship Committee</a></li>
</ul>
</li>
<li class="no-icons-l">iii. Nomination and Remuneration Committee<ul><li><a href="https://epapercdn.sandesh.com/investors/I. Composition of Nomination and Remuneration Committee.pdf" target="_blank">Composition of Nomination and Remuneration Committee</a></li>
<li><a href="https://epapercdn.sandesh.com/investors/II. Charter of Nomination and Remuneration Committee.pdf" target="_blank">Charter of Nomination and Remuneration Committee</a></li>
</ul>
</li>
<li class="no-icons-l">iv. Risk Management Committee<ul><li><a href="https://epapercdn.sandesh.com/investors/I. Composition of Risk Management Committee.pdf" target="_blank">Composition of Risk Management Committee</a></li>
<li><a href="https://epapercdn.sandesh.com/investors/II. Charter of Risk Management Committee.pdf" target="_blank">Charter of Risk Management Committee</a></li>
</ul>
</li>
<li class="no-icons-l">v. Corporate Social Responsibility Committee<ul><li><a href="https://epapercdn.sandesh.com/investors/I. Composition of CSR Committee.pdf" target="_blank">Composition of CSR Committee</a></li>
<li><a href="https://epapercdn.sandesh.com/investors/II. Charter of CSR Committee.pdf" target="_blank">Charter of CSR Committee</a></li>
</ul>
</li>
<li class="no-icons-l">vi. Executive Committee<ul><li><a href="https://epapercdn.sandesh.com/investors/I. Composition of Executive Committee.pdf" target="_blank">Composition of Executive Committee</a></li>
<li><a href="https://epapercdn.sandesh.com/investors/II. Charter of Executive Committee.pdf" target="_blank">Charter of Executive Committee</a></li>
</ul>
</li>
</ul>
</li>
<li class="no-icons-l">C. Policies and codes<ul><li ><a href="https://epapercdn.sandesh.com/investors/i. Risk Management Policy.pdf" target="_blank">Risk Management Policy</a></li>
<li ><a href="https://epapercdn.sandesh.com/investors/ii. Dividend Distribution Policy.pdf" target="_blank">Dividend Distribution Policy</a></li>
<li ><a href="https://epapercdn.sandesh.com/investors/iii. Policy determining Material Information.pdf" target="_blank">Policy determining Material Information</a></li>
<li ><a href="https://epapercdn.sandesh.com/investors/iv. Archival Policy.pdf" target="_blank">Archival Policy</a></li>
<li ><a href="https://epapercdn.sandesh.com/investors/ix. Policy for determining Material Subsidiary.pdf" target="_blank">Policy for determining Material Subsidiary</a></li>
<li ><a href="https://epapercdn.sandesh.com/investors/v. RPT Policy.pdf" target="_blank">RPT Policy</a></li>
<li ><a href="https://epapercdn.sandesh.com/investors/vi. Code of Conduct of Board of Directors and Senior Management Personnel.pdf" target="_blank">Code of Conduct of Board of Directors and Senior Management Personnel</a></li>
<li ><a href="https://epapercdn.sandesh.com/investors/vii. Vigil Mechanism & Whistle Blower Policy.pdf" target="_blank">Vigil Mechanism & Whistle Blower Policy</a></li>
<li ><a href="https://epapercdn.sandesh.com/investors/viii. Criteria of making payments to non-executive directors.pdf" target="_blank">Criteria of making payments to non-executive directors</a></li>
<li ><a href="https://epapercdn.sandesh.com/investors/Details%20of%20familiarization%20programmes.pdf" target="_blank">Details of Familiarization program imparted to Independent Directors</a></li>
<li ><a href="https://epapercdn.sandesh.com/investors/xii. Code of practices and procedure for fair disclosure of UPSI.pdf" target="_blank">Code of practices and procedure for fair disclosure of UPSI</a></li>
<li ><a href="https://epapercdn.sandesh.com/investors/xiii. CSR Policy.pdf" target="_blank">CSR Policy</a></li>
<li ><a href="https://epapercdn.sandesh.com/investors/xv. Nomination and Remuneration Policy.pdf" target="_blank">Nomination and Remuneration Policy</a></li>
<li ><a href="https://epapercdn.sandesh.com/investors/Familiarization%20program%20for%20%20Independent%20Directors%20of%20the%20Company.pdf" target="_blank">Familiarization program for  Independent Directors of the Company</a></li>
</ul>
</li>
<li class="no-icons-l">D. person authorised for determining material event<ul>
<li><a href="https://epapercdn.sandesh.com/investors/Person%20authorised%20for%20determining%20materiality%20of%20an%20events.pdf" target="_blank">Person authorised for determining materiality of an event</a></li>
</ul>
</li>
<li class="no-icons-l">E. Compliance Reports<ul><li class="no-icons-l">i. Related Party Transactions<ul><li><a href="https://epapercdn.sandesh.com/investors/Related party Transactions_30-09-2022.pdf" target="_blank">Related party Transactions_30-09-2022</a></li>
</ul>
</li>
<li class="no-icons-l">ii. Shareholding Pattern<ul><li><a href="https://epapercdn.sandesh.com/investors/Shareholding Pattern_30.06.2020.pdf" target="_blank">Shareholding Pattern_30.06.2020</a></li>
<li><a href="https://epapercdn.sandesh.com/investors/Shareholding Pattern_30.06.2021.pdf" target="_blank">Shareholding Pattern_30.06.2021</a></li>
<li><a href="https://epapercdn.sandesh.com/investors/Shareholding Pattern_30.09.2020.pdf" target="_blank">Shareholding Pattern_30.09.2020</a></li>
<li><a href="https://epapercdn.sandesh.com/investors/Shareholding Pattern_30.09.2021.pdf" target="_blank">Shareholding Pattern_30.09.2021</a></li>
<li><a href="https://epapercdn.sandesh.com/investors/Shareholding Pattern_31.03.2020.pdf" target="_blank">Shareholding Pattern_31.03.2020</a></li>
<li><a href="https://epapercdn.sandesh.com/investors/Shareholding Pattern_31.03.2021.pdf" target="_blank">Shareholding Pattern_31.03.2021</a></li>
<li><a href="https://epapercdn.sandesh.com/investors/Shareholding Pattern_31.12.2020.pdf" target="_blank">Shareholding Pattern_31.12.2020</a></li>
<li><a href="https://epapercdn.sandesh.com/investors/Shareholding Pattern_31.12.2021.pdf" target="_blank">Shareholding Pattern_31.12.2021</a></li>

<li><a href="https://epapercdn.sandesh.com/investors/Shareholding%20Pattern_31.03.2022.pdf" target="_blank">Shareholding Pattern_31.03.2022</a></li>
<li><a href="https://epapercdn.sandesh.com/investors/Shareholding%20Pattern_30.06.2022.pdf" target="_blank">Shareholding Pattern_30.06.2022</a></li>
<li><a href="https://epapercdn.sandesh.com/investors/Shareholding%20Pattern_30.09.2022.pdf" target="_blank">Shareholding Pattern_30.09.2022</a></li>
<li><a href="https://epapercdn.sandesh.com/investors/Shareholding%20Pattern_31.12.2022.pdf" target="_blank">Shareholding Pattern_31.12.2022</a></li>

<li><a href="https://epapercdn.sandesh.com/investors/Shareholding%20Pattern_31.03.2023.pdf" target="_blank">Shareholding Pattern_31.03.2023</a></li>
<li><a href="https://epapercdn.sandesh.com/investors/Shareholding%20Pattern_30.06.2023.pdf" target="_blank">Shareholding Pattern_30.06.2023</a></li>
<li><a href="https://epapercdn.sandesh.com/investors/Shareholding%20Pattern_30.09.2023.pdf" target="_blank">Shareholding Pattern_30.09.2023</a></li>
<li><a href="https://epapercdn.sandesh.com/investors/Shareholding%20Pattern_31.12.2023.pdf" target="_blank">Shareholding Pattern_31.12.2023</a></li>
</ul>
</li>
<li class="no-icons-l">iii. Corporate Governance Report<ul><li><a href="https://epapercdn.sandesh.com/investors/Corporate Governance Report_30.06.2020.pdf" target="_blank">Corporate Governance Report_30.06.2020</a></li>
<li><a href="https://epapercdn.sandesh.com/investors/Corporate Governance Report_30.06.2021.pdf" target="_blank">Corporate Governance Report_30.06.2021</a></li>
<li><a href="https://epapercdn.sandesh.com/investors/Corporate Governance Report_30.09.2020.pdf" target="_blank">Corporate Governance Report_30.09.2020</a></li>
<li><a href="https://epapercdn.sandesh.com/investors/Corporate Governance Report_30.09.2021.pdf" target="_blank">Corporate Governance Report_30.09.2021</a></li>
<li><a href="https://epapercdn.sandesh.com/investors/Corporate Governance Report_31.03.2021.pdf" target="_blank">Corporate Governance Report_31.03.2021</a></li>
<li><a href="https://epapercdn.sandesh.com/investors/Corporate Governance Report_31.12.2020.pdf" target="_blank">Corporate Governance Report_31.12.2020</a></li>
</ul>
</li>
<li class="no-icons-l"><ul>
</ul>
</li>
</ul>
</li>
<li class="no-icons-l">F. Notice of resignation<ul><li><a href="https://epapercdn.sandesh.com/investors/Resignation Letter_Shri Shreyasbhai Pandya.pdf" target="_blank">Resignation Letter_Shri Shreyasbhai Pandya</a></li>
<li><a href="https://epapercdn.sandesh.com/investors/Resignation Letter_Shri Yogeshbhai Jani.pdf" target="_blank">Resignation Letter_Shri Yogeshbhai Jani</a></li>
</ul>
</li>
</ul>
</div></div></div>





<div class="accordion-item"><h2 class="accordion-header" id="heading__5"><button onClick={() => clickHandle(5)} className={`accordion-button ${active == 5 ? `` : `collapsed`}`} type="button" data-bs-toggle="collapse" data-bs-target="#collapse__5" aria-expanded="false" aria-controls="collapse__5">
        Corporate Action
    </button></h2><div id="collapse__5" className={`accordion-collapse collapse ${active == 5 ? `show` : ``}`} aria-labelledby="heading__5" data-bs-parent="#accordionExample"><div class="accordion-body"><ul><li class="no-icons-l">A. Annual General Meeting<ul><li class="no-icons-l">i. Newspaper publications<ul><li><a href="https://epapercdn.sandesh.com/investors/Newspaper publication_77th AGM_2020.pdf" target="_blank">Newspaper publication_77th AGM_2020</a></li>
<li><a href="https://epapercdn.sandesh.com/investors/Newspaper publication_78th AGM_2021.pdf" target="_blank">Newspaper publication_78th AGM_2021</a></li>
<li><a href="https://epapercdn.sandesh.com/investors/Newspaper publication_79th AGM_2022.pdf" target="_blank">Newspaper publication_79th AGM_2022</a></li>
</ul>
</li>
<li class="no-icons-l">ii. Notice of Annual General Meeting<ul><li><a href="https://epapercdn.sandesh.com/investors/Notice of 77th Annual General Meeting.pdf" target="_blank">Notice of 77th Annual General Meeting</a></li>
<li><a href="https://epapercdn.sandesh.com/investors/Notice of 78th Annual General Meeting.pdf" target="_blank">Notice of 78th Annual General Meeting</a></li>
<li><a href="https://epapercdn.sandesh.com/investors/Notice of 79th Annual General Meeting.pdf" target="_blank">Notice of 79th Annual General Meeting</a></li>
</ul>
</li>
<li class="no-icons-l">iii. E-voting Results<ul><li><a href="https://epapercdn.sandesh.com/investors/Voting results 77th AGM.pdf" target="_blank">Voting results 77th AGM</a></li>
<li><a href="https://epapercdn.sandesh.com/investors/Voting results 78th AGM.pdf" target="_blank">Voting results 78th AGM</a></li>
<li><a href="https://epapercdn.sandesh.com/investors/Voting results 79th AGM.pdf" target="_blank">Voting results 79th AGM</a></li>
</ul>
</li>
<li class="no-icons-l">iv. Transcript<ul><li><a href="https://epapercdn.sandesh.com/investors/77th AGM Transcript.pdf" target="_blank">77th AGM Transcript</a></li>
<li><a href="https://epapercdn.sandesh.com/investors/78th AGM Transcript.pdf" target="_blank">78th AGM Transcript</a></li>
<li><a href="https://epapercdn.sandesh.com/investors/79th AGM Transcript.pdf" target="_blank">79th AGM Transcript</a></li>
</ul>
</li>
</ul>
</li>
<li class="no-icons-l">B. Board Meeting<ul><li class="no-icons-l">i. Notice of Board Meeting<ul><li>Newspaper Publication<ul><li><a href="https://epapercdn.sandesh.com/investors/BM_Notice_Newspaper_30.09.2020.pdf" target="_blank">BM_Notice_Newspaper_30.09.2020</a></li>
<li><a href="https://epapercdn.sandesh.com/investors/BM_Notice_Newspaper_31.12.2020.pdf" target="_blank">BM_Notice_Newspaper_31.12.2020</a></li>
</ul>
</li>
<li>Notice to Stock Exchange<ul><li><a href="https://epapercdn.sandesh.com/investors/BM_Intimation_30.06.2020.pdf" target="_blank">BM_Intimation_30.06.2020</a></li>
<li><a href="https://epapercdn.sandesh.com/investors/BM_Intimation_30.06.2021.pdf" target="_blank">BM_Intimation_30.06.2021</a></li>
<li><a href="https://epapercdn.sandesh.com/investors/BM_Intimation_30.06.2022.pdf" target="_blank">BM_Intimation_30.06.2022</a></li>
<li><a href="https://epapercdn.sandesh.com/investors/BM_Intimation_30.09.2020.pdf" target="_blank">BM_Intimation_30.09.2020</a></li>
<li><a href="https://epapercdn.sandesh.com/investors/BM_Intimation_30.09.2021.pdf" target="_blank">BM_Intimation_30.09.2021</a></li>
<li><a href="https://epapercdn.sandesh.com/investors/BM_Intimation_30.09.2022.pdf" target="_blank">BM_Intimation_30.09.2022</a></li>
<li><a href="https://epapercdn.sandesh.com/investors/BM_Intimation_31.03.2020.pdf" target="_blank">BM_Intimation_31.03.2020</a></li>
<li><a href="https://epapercdn.sandesh.com/investors/BM_Intimation_31.03.2021.pdf" target="_blank">BM_Intimation_31.03.2021</a></li>
<li><a href="https://epapercdn.sandesh.com/investors/BM_Intimation_31.12.2020.pdf" target="_blank">BM_Intimation_31.12.2020</a></li>
<li><a href="https://epapercdn.sandesh.com/investors/BM_Intimation_31.12.2021.pdf" target="_blank">BM_Intimation_31.12.2021</a></li>
</ul>
</li>
</ul>
</li>
<li class="no-icons-l">ii. Outcome of Board Meeting<ul><li><a href="https://epapercdn.sandesh.com/investors/BM_Outcome_30.06.2020.pdf" target="_blank">BM_Outcome_30.06.2020</a></li>
<li><a href="https://epapercdn.sandesh.com/investors/BM_Outcome_30.06.2021.pdf" target="_blank">BM_Outcome_30.06.2021</a></li>
<li><a href="https://epapercdn.sandesh.com/investors/BM_Outcome_30.06.2022.pdf" target="_blank">BM_Outcome_30.06.2022</a></li>
<li><a href="https://epapercdn.sandesh.com/investors/BM_Outcome_30.09.2020.pdf" target="_blank">BM_Outcome_30.09.2020</a></li>
<li><a href="https://epapercdn.sandesh.com/investors/BM_Outcome_30.09.2021.pdf" target="_blank">BM_Outcome_30.09.2021</a></li>
<li><a href="https://epapercdn.sandesh.com/investors/BM_Outcome_30.09.2022.pdf" target="_blank">BM_Outcome_30.09.2022</a></li>
<li><a href="https://epapercdn.sandesh.com/investors/BM_Outcome_31.12.2020.pdf" target="_blank">BM_Outcome_31.12.2020</a></li>
<li><a href="https://epapercdn.sandesh.com/investors/BM_Outcome_31.12.2021.pdf" target="_blank">BM_Outcome_31.12.2021</a></li>
</ul>
</li>
</ul>
</li>
</ul>
</div></div></div>





<div class="accordion-item"><h2 class="accordion-header" id="heading__6"><button onClick={() => clickHandle(6)} className={`accordion-button ${active == 6 ? `` : `collapsed`}`} type="button" data-bs-toggle="collapse" data-bs-target="#collapse__6" aria-expanded="false" aria-controls="collapse__6">
        Dividend and IEPF
    </button></h2><div id="collapse__6" className={`accordion-collapse collapse ${active == 6 ? `show` : ``}`} aria-labelledby="heading__6" data-bs-parent="#accordionExample"><div class="accordion-body"><ul><li class="no-icons-l">i. Details of Shares liable to be transferred to IEPF<ul><li><a href="https://epapercdn.sandesh.com/investors/List of shareholders whose shares are liable to be transferred to IEPF in FY 2019-20.pdf" target="_blank">List of shareholders whose shares are liable to be transferred to IEPF in FY 2019-20</a></li>
<li><a href="https://epapercdn.sandesh.com/investors/List of shareholders whose shares are liable to be transferred to IEPF in FY 2020-21.pdf" target="_blank">List of shareholders whose shares are liable to be transferred to IEPF in FY 2020-21</a></li>
<li><a href="https://epapercdn.sandesh.com/investors/List of shareholders whose shares are liable to be transferred to IEPF in FY 2021-22.pdf" target="_blank">List of shareholders whose shares are liable to be transferred to IEPF in FY 2021-22</a></li>
<li><a href="https://epapercdn.sandesh.com/investors/List of shareholders whose shares are liable to be transferred to IEPF in FY 2022-23.pdf" target="_blank">List of shareholders whose shares are liable to be transferred to IEPF in FY 2022-23</a></li>
</ul>
</li>
<li class="no-icons-l">iii. Newspaper Publication<ul><li><a href="https://epapercdn.sandesh.com/investors/Newspaper publication_Transfer of shares to IEPF_2018.pdf" target="_blank">Newspaper publication_Transfer of shares to IEPF_2018</a></li>
<li><a href="https://epapercdn.sandesh.com/investors/Newspaper publication_Transfer of shares to IEPF_2019.pdf" target="_blank">Newspaper publication_Transfer of shares to IEPF_2019</a></li>
<li><a href="https://epapercdn.sandesh.com/investors/Newspaper publication_Transfer of shares to IEPF_2021.pdf" target="_blank">Newspaper publication_Transfer of shares to IEPF_2021</a></li>
<li><a href="https://epapercdn.sandesh.com/investors/Newspaper publication_Transfer of shares to IEPF_2022.pdf" target="_blank">Newspaper publication_Transfer of shares to IEPF_2022</a></li>
<li><a href="https://epapercdn.sandesh.com/investors/Newspaper publication_Transfer of shares to IEPF_2023.pdf" target="_blank">Newspaper publication_Transfer of shares to IEPF_2023</a></li>
</ul>
</li>
<li class="no-icons-l">v. Record Date and Payment Date of Dividend<ul><li><a href="https://epapercdn.sandesh.com/investors/Notice-of-Record-date-for-payment-of-Interim-Dividend_F.Y.-19-20.pdf" target="_blank">Notice-of-Record-date-for-payment-of-Interim-Dividend_F.Y.-19-20</a></li>
<li><a href="https://epapercdn.sandesh.com/investors/Notice-of-Record-date-for-payment-of-Interim-Dividend_F.Y.-20-21.pdf" target="_blank">Notice-of-Record-date-for-payment-of-Interim-Dividend_F.Y.-20-21</a></li>
<li><a href="https://epapercdn.sandesh.com/investors/Notice-of-Record-date-for-payment-of-Interim-Dividend_F.Y.-21-22.pdf" target="_blank">Notice-of-Record-date-for-payment-of-Interim-Dividend_F.Y.-21-22</a></li>
</ul>
</li>
</ul>
</div></div></div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default PagesInvestorRelation
