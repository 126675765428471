/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import logo from '../../assets/logoog.jpg'
import Login from '../pages/Login';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { searchValue } from '../../redux/actions/searchValue';
import HelperScript from '../../utils/helper'
import { profileFetchData } from '../../redux/actions/commonAction';
import ReactGA from "react-ga4";
import InjectScript from '../CustomScript';
import { signInSaveData } from '../../redux/actions/loginAction';

const Header = (props) => {
    // const { 3: headerWidget } = useSelector((state) => state?.widgetReducer?.data);
    // const { 2: Topbar } = useSelector((state) => state?.widgetReducer?.data);
    const [loginStateEnable, setLoginEnableState] = useState(false);
    const [userName, setUserName] = useState('');
    const [login, setlogin] = useState('');
    const tithi = useSelector((state) => state?.TithiReducer);
    const usernameState = useSelector((state) => state?.LogingReducer?.data);
    const showLoginModel = () => {
        setLoginEnableState(false);
        setTimeout(() => {
            setLoginEnableState(true)
        });
    };
    // const [ , setDate] = useState(new Date());
    let navigate = useNavigate();

    let dispatch = useDispatch();
    var months = ['જાન્યુઆરી', 'ફેબ્રુઆરી', 'માર્ચ', 'એપ્રિલ', 'મે', 'જૂન', 'જુલાઈ', 'ઓગસ્ટ', 'સપ્ટેમ્બર', 'ઓક્ટોબર', 'નવેમ્બર', 'ડિસેમ્બર'];
    var now = new Date();
    var thisMonth = months[now.getMonth()];

    var newdate = new Date();
    var thisDate = newdate.getDate()

    var days = ['રવિવાર', 'સોમવાર', 'મંગળવાર', 'બુધવાર', 'ગુરૂવાર', 'શુક્રવાર', 'શનિવાર'];
    var newday = new Date();
    var thisDay = days[newday.getDay()];

    var year = new Date();
    var thisYear = year.getFullYear()

    // function refreshClock() {
    // setDate(new Date());
    // }
    useEffect(() => {
        // const timerId = setInterval(refreshClock, 1000);
        const timerId = setTimeout(checklocation, 1000);
        return function cleanup() {
            clearInterval(timerId);
        };
    }, []);

    useEffect(() => {
        if (usernameState?.name) setUserName(usernameState?.name);
    }, [usernameState])

    const [InputSearchValue, setInputSearchValue] = useState('');
    const checklocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(successFunction, errorFunction);
        }
    }

    function successFunction(position) {
        const lat = position.coords.latitude;
        const long = position.coords.longitude;
        localStorage.setItem('SandeshLocation', JSON.stringify({ lat: lat, long: long }));
        document.getElementById('locationCheck').style.display = 'none';
    }

    function errorFunction(err) {
        // console.log('Location Error:', err.message);
        // alert('Location Error: ' + err.message);
    }

    useEffect(() => {
        const timer = setInterval(() => {
            // InputSearchValue
            dispatch(searchValue(InputSearchValue))
            if (InputSearchValue) {
                navigate('/search')
            }
        }, 3000);
        return () => {
            clearInterval(timer);
        }
    }, [InputSearchValue]);

    const Logout = () => {
        localStorage.removeItem('userInfo');
        localStorage.removeItem('access_token');
        localStorage.removeItem('userID');
        localStorage.removeItem('socialLogin');
        setUserName('');
        setlogin('clear');
        if (window.location.pathname === '/profile') {
            // navigate('/');
            window.location.href = '/';
        } else {
            // navigate(window.location.pathname);
            window.location.reload();
        }
    }

    useEffect(() => {
        if (localStorage.getItem('access_token') !== null) {
            dispatch(profileFetchData()).then((res) => {
                setUserName(res?.user_data?.name || '');
                dispatch(signInSaveData(null, res?.user_data));
            });
        }
    }, []);

    useEffect(() => {
        if (localStorage.getItem('access_token')) {
            setlogin('success');
        } else {
            setlogin('clear');
        }
    })
    // }, [profileFetchData])
    // const thisTime = date.toLocaleTimeString();

    const success = (success) => {
        setlogin(success);
    }


    const headerweb =
        '' +
        // "<div id='div-gpt-ad-1652870128848-0' style='min-width: 300px; min-height: 250px;'> " +
        "<div id='div-gpt-ad-1646031348333-0' style='min-width: 728px; min-height: 90px;'> " +
        '<script>' +
        'window.googletag = window.googletag || { cmd: [] };' +
        'googletag.cmd.push(function () {' +
        "googletag.defineSlot('/22678428348/Test_HP_header_728X90', [728, 90], 'div-gpt-ad-1646031348333-0').addService(googletag.pubads());" +
        'googletag.pubads().enableSingleRequest();' +
        'googletag.pubads().disableInitialLoad();' +
        'googletag.enableServices();' +
        '});' +
        '</script>' +
        '' +
        '<script>' +
        "  googletag.cmd.push(function() { googletag.display('div-gpt-ad-1646031348333-0'); });" +
        '</script>' +
        '</div>' +
        // '<div id="gpt-passback">'+
        // ' <script>'+
        // 'window.googletag = window.googletag || {cmd: []};'+
        // 'googletag.cmd.push(function() {'+
        // "googletag.defineSlot('/21928950349,22624158342/sandesh_backfill_728x90', [728, 90], 'gpt-passback').addService(googletag.pubads());"+
        // 'googletag.enableServices();'+
        // "googletag.display('gpt-passback');"+
        // '});'+
        // '</script>'+
        // '</div>'+
        '' +
        '';
    return (
        <>
            <div className="text-center">
                {/* {Topbar?.[0]?.script &&  */}
                <div className="ad-section">
                    <HelperScript addstyle={1} argSmall={164} argLarge={2} />
                    {/* /    <InjectScript script={Topbar?.[0]?.script} /> */}
                </div>
                {/* } */}
            </div>
            <section className="header">
                <div className="topbar">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="d-flex justify-content-between">
                                    <div className="d-flex align-items-center">
                                        {
                                            <div className="time" id="demo">
                                                <span className='date-top-header'>{thisDay}, {thisDate} {thisMonth}, {thisYear} </span>
                                                <span className='ml-3' dangerouslySetInnerHTML={{ __html: tithi?.data?.text }} />
                                                {/* શનિવાર, 5 ફેબ્રુઆરી, 2022 <span className='ml-3'>પૂનમ</span> */}
                                                {/* <b className="digi-clock">{thisTime}</b> */}
                                                {/* <span className="ml-2"> પૂનમ</span> */}
                                            </div>
                                        }
                                        <a href='/epaper' target="_blank" className='e-paper-header'>E-PAPER</a>  {/* <div className="icons">
                                    <ul>
                                        <li><a href="#."><i className="fab fa-facebook"></i></a></li>
                                        <li><a href="#."><i className="fab fa-twitter"></i></a></li>
                                        <li><a href="#."><i className="fab fa-instagram"></i></a></li>
                                        <li><a href="#."><i className="fab fa-youtube"></i></a></li>
                                    </ul>
                                </div> */}
                                        <div className="contacts">
                                            {/* <ul>
                                        <li><a href="#."><i className="fas fa-phone"></i> <span>079 4000 4000</span></a></li>
                                        <li><a href="#."><i className="fas fa-envelope"></i> <span>digital.advertising@sandesh.com</span></a>
                                        </li>
                                    </ul> */}
                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            <div className="rightbar">
                                                <ul>
                                                    {/* <li><a href="#." onClick={props.onDarkMode} className="darkmode">{props.mode === 'light' ? <i className="fas fa-moon"></i> : <i className="fas fa-sun"></i>}</a></li> */}
                                                    {
                                                        login === 'success' && localStorage.getItem('access_token') ? (
                                                            // ''
                                                            <li><Link target="_parent"  to='/profile' className="signin signin-user-icon cursor-pointer"><i className="fas fa-user"></i></Link></li>
                                                        ) : (
                                                            <li><a onClick={showLoginModel} className="signin cursor-pointer"><i className="fas fa-user"></i></a></li>
                                                        )
                                                    }
                                                    {
                                                        login === 'success' ? (
                                                            <li><Link target="_parent"  to='/profile' className="signin cursor-pointer">Hi, {userName ? userName : 'There'}</Link></li>
                                                        ) : (''
                                                            // <li><Link target="_parent"  to='/profile' className="signin cursor-pointer">Hi, There</Link></li>
                                                        )
                                                    }
                                                    {/* <li className="search-animate">
                                            <a href="#." className="icon"><i className="fas fa-search"></i></a>
                                        </li> */}
                                                    {
                                                        login === 'success' && localStorage.getItem('access_token') ? (
                                                            <li><i onClick={() => { Logout() }} style={{ fontSize: '18px', padding: '9px', cursor: 'pointer' }} className="fas fa-power-off icon-header-logout"></i></li>
                                                        ) : ''
                                                    }
                                                    <li id='locationCheck'><a href="javascript:void(0)" onClick={checklocation} className="location"><i className="fas fa-map-marker-alt"></i></a></li>
                                                    <li>
                                                        <div className="d-flex">
                                                            <div className="collapse fade indextab" id="searchForm">
                                                                <input value={InputSearchValue} onChange={(e) => setInputSearchValue(e.target.value)} type="search" className="form-control border-0 bg-light" placeholder="search" />
                                                            </div>
                                                            <Link target="_parent"  to="/search" className="nav-link ml-auto" onClickCapture={() => ReactGA.event({ category: 'Header', action: 'Search', label: 'Clicked' })}>
                                                                <i className="fas fa-search"></i>
                                                            </Link>
                                                        </div>
                                                    </li>
                                                    { }
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="logo">
                    <div className="container">
                        <div className="row ">
                            <div className="col-md-4">
                                <a href="/" className="brand">
                                    <img loading="lazy" style={{ filter: "none", width: '263px' }} src={logo} alt="" />
                                </a>
                            </div>
                            {/* <div className="col-md-1" style={{ marginLeft: '-45px', marginRight: 'auto', padding: '0 2px' }}>
                                <img loading="lazy" className='w-100' src={require('../../assets/gc-header.png')} alt="" />
                            </div> */}
                            <div className="col-md-8">
                                {/* {headerWidget?.[0]?.script && */}
                                <div className='row'>
                                    <div className="col-md-10 ad-section" style={{ marginRight: 'auto', marginLeft: '-23px' }} >

                                        <HelperScript argSmall={165} argLarge={3} key={window.location.pathname} />
                                        {/* {window.innerWidth > 991 ?
                                            <InjectScript
                                                key={Math.random()}
                                                script={headerweb}
                                            />
                                            :
                                            ''
                                        } */}
                                        {/* <div dangerouslySetInnerHTML={{ __html: headerWidget?.[0]?.script }} /> */}
                                        {/* <InjectScript script={headerWidget?.[0]?.script} /> */}
                                    </div>
                                    <div className='col-md-2 qr-code-app text-center'>
                                        <img loading="lazy" src={require('../../assets/qrcode-app.png')} alt='' />
                                        <span>Download App</span>
                                    </div>
                                </div>
                                {/* } */}
                                {/* <Helmet>
                                        <script async type="text/javascript">{headerWidget?.[0]?.script}</script>
                                </Helmet> */}
                                {/* <a href="#.">
                                    <img loading="lazy" src={addtop} alt="" />
                                </a> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {loginStateEnable && <Login success={success} />}

        </>
    )
}


export default Header
