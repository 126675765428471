/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-undef */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from 'react'
import { useSelector } from "react-redux";
import HelperScript from '../../../utils/helper';
import ReactGA from "react-ga4";
import { Helmet } from 'react-helmet';
import InjectScript from '../../CustomScript';
import HomeTaboola from "../../subcomponents/HomeTaboola";


function ClippedImage(props) {
    const [zoomImg, setZoomImg] = useState('');
    const [zoomImgPopup, setzoomImgPopup] = useState(false);

    const { 21: assetbaseUrl } = useSelector(
        (state) => state?.SettingReducer?.data
    );
    const [urlParam, setUrlParams] = useState({});
    const [downloadLink, setdownloadLink] = useState('');

    const onClose = () => {
        window.opener = null;
        window.open('', '_self');
        window.close();
    }

    const saveFile = (url) => {
        const xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onload = function () {
            // const href = window.URL.createObjectURL(xhr.response);
            // setdownloadLink(href);

            var reader = new FileReader();
            reader.onloadend = function () {
                const href = reader.result;
                if (href) {
                    setdownloadLink(href);
                }
            }
            reader.readAsDataURL(xhr.response);
        };
        xhr.open('GET', url);
        xhr.send();
    }

    const convertDateStringToDate = (dateStr) => {
        let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        let date = new Date(dateStr);
        let str = date.getDate()
            + ' ' + months[date.getMonth()]
            + ' ' + date.getFullYear()
        return str;
    }

    useEffect(() => {
        const params = Object.fromEntries(new URLSearchParams(window.location.search));
        setUrlParams(params);
    }, []);

    useEffect(() => {
        saveFile('https://epapercdn.sandesh.com/' + urlParam?.path);
    }, [urlParam]);

    const ZoomModalOpen = (VidDataId, VidDataTitle, VidDataTime) => {
        ReactGA.event({ category: 'E-Paper Clipped Zoom', action: urlParam?.name, label: urlParam?.date + ' - ' + urlParam?.path })
        setZoomImg(VidDataId);
        // setZoomTitle(VidDataTitle);
        // setZoomTime(VidDataTime);
        setTimeout(() => {
            // document.getElementById("videoPoPup").style.width = "100%";
            // document.getElementById("videoPoPup").style.height = "100vh";
            document.getElementById("ZoomModal").classList.add('active-modal');
            document.body.style.overflow = 'hidden';
        }, 50);
    };

    const ZoomInOut = () => {
        setzoomImgPopup(!zoomImgPopup);
    };

    const ZoomModalClose = () => {
        setZoomImg('');
        // setZoomId('');
        // document.getElementById("videoPoPup").style.width = "0%";
        // document.getElementById("videoPoPup").style.height = "0vh";
        document.body.style.overflow = 'auto';
        document.getElementById("ZoomModal").classList.remove('active-modal');
    };
    const afterbeforeimg =
        '' +
        "<div id='div-gpt-ad-1657961203661-0' style='min-width: 300px; min-height: 250px;'>" +
        '<script>' +
        'window.googletag = window.googletag || {cmd: []};' +
        'googletag.cmd.push(function() {' +
        "googletag.defineSlot('/22678428348/Mweb_Epaper_AboveBelowclippedimg_300x250', [300, 250], 'div-gpt-ad-1657961203661-0').addService(googletag.pubads());" +
        'googletag.pubads().enableSingleRequest();' +
        'googletag.enableServices();' +
        '});' +
        '</script>' +
        '' +
        '<script>' +
        "googletag.cmd.push(function() {googletag.display('div-gpt-ad-1657961203661-0'); });" +
        '</script>' +
        '</div>' +
        '' +
        '';

    // const clip254 = "<div id='div-gpt-ad-1646915344333-0' style='min-width: 728px; min-height: 90px;'><script> window.googletag = window.googletag || {cmd: []}; googletag.cmd.push(function() { googletag.defineSlot('/22678428348/Mweb_Epaper_Header_728x90', [728, 90], 'div-gpt-ad-1646915344333-0').addService(googletag.pubads()); googletag.pubads().enableSingleRequest(); googletag.pubads().disableInitialLoad(); googletag.enableServices(); }); </script> <script> googletag.cmd.push(function() { googletag.display('div-gpt-ad-1646915344333-0'); }); </script> </div>"
    // const clip255 = " <div id='div-gpt-ad-1646915375853-0' style='min-width: 728px; min-height: 90px;'> <script> window.googletag = window.googletag || {cmd: []}; googletag.cmd.push(function() { googletag.defineSlot('/22678428348/Mweb_Epaper_Bottom_728x90', [728, 90], 'div-gpt-ad-1646915375853-0').addService(googletag.pubads()); googletag.pubads().enableSingleRequest(); googletag.pubads().disableInitialLoad(); googletag.enableServices(); }); </script> <script> googletag.cmd.push(function() { googletag.display('div-gpt-ad-1646915375853-0'); }); </script> </div>"
    // const clip256 = ""
    // const clip257 = ""
    const clip254 = " <div id='div-gpt-ad-1646915344333-0' style='min-width: 728px; min-height: 90px;'><script> window.googletag = window.googletag || {cmd: []}; googletag.cmd.push(function() { googletag.defineSlot('/22678428348/Mweb_Epaper_Header_728x90', [728, 90], 'div-gpt-ad-1646915344333-0').addService(googletag.pubads()); googletag.pubads().enableSingleRequest(); googletag.pubads().disableInitialLoad(); googletag.enableServices(); }); </script>  <script> googletag.cmd.push(function() { googletag.display('div-gpt-ad-1646915344333-0'); }); </script> </div>"
    const clip255 = " <div id='div-gpt-ad-1646915375853-0' style='min-width: 728px; min-height: 90px;'> <script> window.googletag = window.googletag || {cmd: []}; googletag.cmd.push(function() { googletag.defineSlot('/22678428348/Mweb_Epaper_Bottom_728x90', [728, 90], 'div-gpt-ad-1646915375853-0').addService(googletag.pubads()); googletag.pubads().enableSingleRequest(); googletag.pubads().disableInitialLoad(); googletag.enableServices(); }); </script> <script> googletag.cmd.push(function() { googletag.display('div-gpt-ad-1646915375853-0'); }); </script> </div>"

    return (
        <>
            <Helmet>
                <title>{(urlParam?.name) ? urlParam?.name : ''} - E-Paper | Sandesh</title>
            </Helmet>
            <div className='subPaper'>
                <div className="container">
                    <div className="row subPaperHeader">
                        <div className="col-md-2">
                            <h1>{urlParam?.name}</h1>
                            <b>{convertDateStringToDate(urlParam?.date)}</b>
                        </div>
                        <div className="col-md-6">
                            {/* <HelperScript argSmall={256} argLarge={254} /> */}
                            {
                                window.innerWidth > 991 ?
                                <InjectScript script={clip254} />
                                : ''
                            }
                        </div>
                        <div className="col-md-4">
                            <div className="text-right">
                                <a
                                    // onClickCapture={() => { ZoomModalOpen(assetbaseUrl?.value + epaperData[activePage]?.photo.split('?')[0]); }}
                                    // onClickCapture={() => { ZoomModalOpen(assetbaseUrl?.value + urlParam?.path); }}
                                    onClickCapture={() => { ZoomModalOpen('https://epapercdn.sandesh.com/' + urlParam?.path); }}
                                    // onClickCapture={() => { ZoomModalOpen('https://esandesh.gumlet.io/' + urlParam?.path); }}
                                    className="cursor-pointer new_zoom pb-2  mr-2" rel="noreferrer"
                                >
                                    <i className="fa fa-search-plus"></i> &nbsp;Zoom
                                </a>
                                {/* {downloadLink &&
                                    (<a
                                        target="_blank"
                                        onClickCapture={() => ReactGA.event({ category: 'E-Paper Clipped Download', action: urlParam?.name, label: urlParam?.date + ' - ' + urlParam?.path })}
                                        href={downloadLink}
                                        download={`${urlParam?.name} ${convertDateStringToDate(urlParam?.date)}`}
                                        className="download-btn m-0 mr-2"><i className="fas fa-download mr-2"></i> Download</a>
                                    )} */}
                                <button onClick={() => onClose()} className='btn-close m-0'><i className="fas fa-times mr-2"></i>Close</button>
                            </div>
                        </div>
                    </div>
                    {window.innerWidth < 991 ?
                        <div className="text-center">
                            <InjectScript script={afterbeforeimg} key={afterbeforeimg} />
                        </div>
                        : ''}
                    <div className="row subPaperBody">
                        <div className="col-md-12 text-center">
                            {/* <a
                            target="_blank"
                            href={downloadLink}> */}
                            <img loading="lazy"
                                // src={assetbaseUrl?.value + urlParam?.path}
                                src={'https://epapercdn.sandesh.com/' + urlParam?.path}
                                // src={'https://esandesh.gumlet.io/' + urlParam?.path}
                                alt={urlParam?.name}
                            />
                            {/* </a> */}
                        </div>
                    </div>
                    <div id="epaper_clipped" class="epaper_clipped"></div>
                    {window.innerWidth < 991 ?
                        <div className="text-center">
                            <InjectScript script={afterbeforeimg} key={afterbeforeimg} />
                        </div>
                        : ''}
                    <div className="row subPaperHeader subPaperFooter">
                        <div className="col-md-2">
                            <h1>{urlParam?.name}</h1>
                            <b>{convertDateStringToDate(urlParam?.date)}</b>
                        </div>
                        <div className="col-md-6">
                            {/* <HelperScript argSmall={257} argLarge={255} /> */}
                            {
                                window.innerWidth > 991 ?
                                <InjectScript script={clip255} />
                                : ''
                            }
                            {/* {
                                window.innerWidth < 991 ?
                                <InjectScript script={clip257} />
                                :
                                <InjectScript script={clip255} />
                            } */}
                        </div>
                        <div className="col-md-4">
                            <div className="text-right">
                                <a
                                    // onClickCapture={() => { ZoomModalOpen(assetbaseUrl?.value + epaperData[activePage]?.photo.split('?')[0]); }}
                                    // onClickCapture={() => { ZoomModalOpen(assetbaseUrl?.value + urlParam?.path); }}
                                    onClickCapture={() => { ZoomModalOpen('https://epapercdn.sandesh.com/' + urlParam?.path); }}
                                    // onClickCapture={() => { ZoomModalOpen('https://esandesh.gumlet.io/' + urlParam?.path); }}
                                    className="cursor-pointer new_zoom pb-2  mr-2" rel="noreferrer"
                                >
                                    <i className="fa fa-search-plus"></i> &nbsp;Zoom
                                </a>
                                {/* {downloadLink &&
                                    (<a
                                        target="_blank"
                                        onClickCapture={() => ReactGA.event({ category: 'E-Paper Clipped Download', action: urlParam?.name, label: urlParam?.date + ' - ' + urlParam?.path })}
                                        href={downloadLink}
                                        download={`${urlParam?.name} ${convertDateStringToDate(urlParam?.date)}`}
                                        className="download-btn m-0 mr-2"><i className="fas fa-download mr-2"></i> Download</a>
                                    )} */}
                                <button onClick={() => onClose()} className='btn-close m-0'><i className="fas fa-times mr-2"></i>Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="ZoomModal" className="videoSidebar">
                <div className="closebtn-zoom-epaper" onClick={() => ZoomModalClose()} >
                    <i className="fas fa-times"></i>
                </div>

                <div className="zoombtn-inner-epaper" onClick={() => ZoomInOut()} >
                    <i className={`fas fa-${zoomImgPopup ? 'minus' : 'plus'}`}></i> ZOOM
                </div>

                {/* <img loading="lazy" className="epaper-zoom-modal-page" src="https://epapercdn.sandesh.com/epaper/2022/03/03/21416462617691.jpg" alt="" /> */}
                <div className={`${zoomImgPopup ? '' : 'minimize'} epaperZoomer bg-white`}>
                    <img loading="lazy" className={`epaper-zoom-modal-page ${zoomImgPopup ? '' : 'minimize'}`} src={zoomImg} alt="" />
                </div>
            </div>
            <HomeTaboola key={window.location.href} />
        </>
    )
}

export default ClippedImage