import { SETTING_ARE_LOADING, SETTING_FETCH_DATA_SUCCESS, SETTING_HAVE_ERROR } from '../actionType';
import HttpService from '../../utils/httpService';
import { Get } from '../../services/api.service'

export function settingAction(action, payload) {
    switch (action) {
        case SETTING_HAVE_ERROR:
            return {
                type: action,
                hasError: payload
            };

        case SETTING_ARE_LOADING:
            return {
                type: action,
                isLoading: payload
            };

        case SETTING_FETCH_DATA_SUCCESS:
            return {
                type: action,
                Setting: payload,
            };

        default:
            return;
    }
}

export function settingFetchData() {
    return (dispatch) => {
        try {
            return new Promise((resolve, reject) => {
                dispatch(settingAction(SETTING_ARE_LOADING, true));
                Get(HttpService.setting_V2).then((result) => {
                    const settings = result.data;
                    localStorage.setItem('SandeshCDN', settings[21].value);
                    dispatch(settingAction(SETTING_FETCH_DATA_SUCCESS, settings));
                    resolve(result);
                }, (error) => {
                    reject(error);
                });
            });
        } catch (error) {
            dispatch(settingAction(SETTING_HAVE_ERROR, true));
        }
    }
}
