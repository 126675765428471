import React from 'react';
import { Link } from 'react-router-dom';
// import ShareCommon from './component/ShareCommon';

function CommonCardContent(props) {
    // const onShareClick = (e) => {
    //     document.getElementById('common-share-dropdown').classList.remove('d-none');
    //     e.stopPropagation();
    //     return false;
    // }
    return (
        <div className="single-blog mx-2 share-common-card-content">
            <Link target="_parent" to={`/${props.url}`} className="main-blogs">
                <div className='skeleton-sub'>
                    <img loading="lazy" src={props.src} alt={props.title} key={Math.random()} />
                    <span className="button-hover colorspan-top-trending">{props?.tagline}</span>
                </div>
                <div className="blog-details">
                    <p className="ellipsis">{props?.title}</p>
                    <span className="blog-time"><i className="far fa-clock"></i>{props?.publish_date}</span>
                </div>
                {/* <div class="dropleft">
                    <button onClick={(e) => onShareClick(e)} className="dropbtn ml-2">
                        <i class="fa fa-share-alt"></i>
                    </button>
                    <div class="dropdown-menu">
                        <ShareCommon id="common-share-dropdown" url={props.url} media={props.src} title={props.title} dataid={props.id} />
                    </div>
                </div> */}
            </Link>
        </div>
    )
}

export default CommonCardContent