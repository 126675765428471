/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react'
import ShareWith from '../component/ShareWith';
import Login from '../pages/Login';
import Comment from './comment';
import { Helmet } from 'react-helmet';
import AdTwo from '../subcomponents/AdTwo'
import InjectScript from '../CustomScript';
import HelperScript from '../../utils/helper'
import Dailymotion from 'react-dailymotion';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { PostFetchData } from '../../redux/actions/postAction'
import ReactGA from "react-ga4";
import YouTube from 'react-youtube';
import { EntertainmentFetchData } from '../../redux/actions/entertainmentAction';


const VideosInnerComponent = ({ url }, props) => {
    // const { url } = useParams();
    const [postCommentModalView, setPostCommentModalView] = useState(false)
    const [comCount, setcomCount] = useState(0)
    const showPostComment = () => {
        setPostCommentModalView(!postCommentModalView);
    }
    const timeline = useSelector((state) => state?.PostReducer?.data?.timeline);
    const homePrimary = useSelector((state) => state?.HomePrimaryReducer.data);
    const homeEntertainment = useSelector((state) => state?.EntertainmentReducer.data);
    const mostviews = useSelector((state) => state?.PostReducer?.data?.mostview);
    let dispatch = useDispatch();

    useEffect(() => {
        if (url.includes('celeb-duniya/')) {
            dispatch(EntertainmentFetchData('', {}, `entertainment?page=1&limit=10`))
        }
    }, []);

    const innerDataRelated = useSelector((state) => state?.PostReducer?.data?.related)
    const innerData = useSelector((state) => state?.PostReducer?.data);
    const { 21: assetbaseUrl, 39: sharerMsg } = useSelector((state) => state?.SettingReducer?.data);
    // const { 99: ArticleContent } = useSelector((state) => state?.widgetReducer?.data);
    const ArticleContent = "<div id='article_outstream' class='article_outstream'></div>";


    // const { 37: firstright } = useSelector((state) => state?.widgetReducer?.data);
    // const { 38: secondright } = useSelector((state) => state?.widgetReducer?.data);
    // const { 39: thirdright } = useSelector((state) => state?.widgetReducer?.data);

    // useEffect(() => {
    //     if (url) {
    //         dispatch(PostFetchData(url))
    //     }
    // }, [url]);
    useEffect(() => {
        PostFetchData(innerData)
        ReactGA.event({ category: 'Article Page', action: 'Video', label: innerData?.title });
    }, [innerData]);

    const setCountCom = (c) => {
        setcomCount(c);
    }
    useEffect(() => {
        const timerIframe = setInterval(() => {
            const videoPlay = document.getElementsByClassName('dailymotion-home-page');
            if (videoPlay.length) {
                if (videoPlay[0].offsetWidth) {
                    videoPlay[0].style.height = (9 * parseInt(videoPlay[0].offsetWidth) / 16) + 'px';
                }
            }
        }, 1000);
        return () => {
            clearInterval(timerIframe);
        }
    }, []);


    const opts = {
        height: '100%',
        width: '100%',
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 1,
            modestbranding: 1,
        },
    };


    const taboolacontent = '' +
        '' +
        '<div id="taboola-right-rail-thumbnails"></div>' +
        '<script type="text/javascript">' +
        'window._taboola = window._taboola || [];' +
        '_taboola.push({' +
        "mode: 'thumbnails-rr'," +
        "container: 'taboola-right-rail-thumbnails'," +
        "placement: 'Right Rail Thumbnails'," +
        "target_type: 'mix'" +
        '});' +
        '</script>' +
        '' +
        '';


    const taboolarelatedcontent = '' +
        '' +
        '<div id="taboola-related-articles-widget-thumbnails"></div>' +
        '<script type="text/javascript">' +
        'window._taboola = window._taboola || [];' +
        '_taboola.push({' +
        "mode: 'thumbnails-b'," +
        "container: 'taboola-related-articles-widget-thumbnails'," +
        "placement: 'Related Articles Widget Thumbnails'," +
        "target_type: 'mix'" +
        '});' +
        '</script>' +
        '' +
        '';

    const bannerAds = '' +
        "<div id='div-gpt-ad-1670999306599-0' style='min-width: 728px; min-height: 90px;'>" +
        '<script>' +
        'window.googletag = window.googletag || {cmd: []};' +
        'googletag.cmd.push(function() {' +
        "googletag.defineSlot('/22678428348/SDPL_AP_BelowArticle_728x90', [728, 90], 'div-gpt-ad-1670999306599-0').addService(googletag.pubads());" +
        'googletag.pubads().enableSingleRequest();' +
        'googletag.enableServices();' +
        '});' +
        '</script>' +
        '<script>' +
        "googletag.cmd.push(function() { googletag.display('div-gpt-ad-1670999306599-0'); });" +
        '</script>' +
        '</div>' +
        ''

    const taboolafootercontent = '' +
        '' +
        '<div id="taboola-below-article-feed-thumbnails"></div>' +
        '<script type="text/javascript">' +
        'window._taboola = window._taboola || [];' +
        '_taboola.push({' +
        "mode: 'alternating-thumbnails-a'," +
        "container: 'taboola-below-article-feed-thumbnails'," +
        "placement: 'Below Article Feed Thumbnails'," +
        "target_type: 'mix'" +
        '});' +
        '</script>' +
        '' +
        '<script type="text/javascript">' +
        'window._taboola = window._taboola || [];' +
        ' _taboola.push({flush: true});' +
        '</script>' +
        '';
    const postWebBody =
        '' +
        // "<div id='div-gpt-ad-1657798998776-0' style='min-width: 300px; min-height: 250px;'>" +
        // '<script>' +
        // 'window.googletag = window.googletag || {cmd: []};' +
        // 'googletag.cmd.push(function() {' +
        // "googletag.defineSlot('/22678428348/Desktop_AP_Belowpointers_728x90', [300, 250], 'div-gpt-ad-1657798998776-0').addService(googletag.pubads());" +
        // 'googletag.pubads().enableSingleRequest();' +
        // 'googletag.enableServices();' +
        // '});' +
        // '</script>' +
        // '' +
        // '<script>' +
        // "googletag.cmd.push(function() {googletag.display('div-gpt-ad-1657798998776-0'); });" +
        // '</script>' +
        // '</div>' +
        // '<script defer id="vidcrunchJS713845546" src="https://cdn.vidcrunch.com/integrations/5f4fa0fc4f13d250c8dd1b3a/Sandesh.com_Both_IBV_300x250_160922.js"></script>'+
        '' +
        '';

    const postMobileBody =
        '' +
        // "<div id='div-gpt-ad-1657799444352-0' style='min-width: 300px; min-height: 250px;'>" +
        // '<script>' +
        // 'window.googletag = window.googletag || {cmd: []};' +
        // 'googletag.cmd.push(function() {' +
        // "                googletag.defineSlot('/22678428348/Mweb_AP_Inbetweenarticle_300X250', [300, 250], 'div-gpt-ad-1657799444352-0').addService(googletag.pubads());" +
        // 'googletag.pubads().enableSingleRequest();' +
        // 'googletag.enableServices();' +
        // '});' +
        // ' </script>' +
        // '' +
        // '<script>' +
        // "googletag.cmd.push(function() {googletag.display('div-gpt-ad-1657799444352-0'); });" +
        // '</script>' +
        // '</div>' +
        // '<script defer id="vidcrunchJS713845546" src="https://cdn.vidcrunch.com/integrations/5f4fa0fc4f13d250c8dd1b3a/Sandesh.com_Both_IBV_300x250_160922.js"></script>'+
        // '<div id="gpt-passback">'+
        // '<script>'+
        // 'window.googletag = window.googletag || {cmd: []};'+
        // 'googletag.cmd.push(function() {'+
        // "googletag.defineSlot('/21928950349,22624158342/sandesh_backfill_300x250', [300, 250], 'gpt-passback').addService(googletag.pubads());"+
        // 'googletag.enableServices();'+
        // "googletag.display('gpt-passback');"+
        // '});'+
        // '</script>'+
        // '</div>'+
        '' +
        '';

        // const cricketwidgetUnibot = '<div id="ub_cricwidget_carousel"></div><script async src="https://cdn.unibots.in/cricketwidget/common/script.js"></script><script>' +
        // 'window.unibotscw = window.unibotscw || { cmd: [] };' +
        // 'unibotscw.cmd.push("sandesh");' +
        // '</script>';

    const windywidget = '<iframe loading="lazy" width="100%" height="450" src="https://embed.windy.com/embed2.html?lat=19.803&lon=71.525&detailLat=23.028&detailLon=72.587&width=650&height=450&zoom=4&level=surface&overlay=wind&product=ecmwf&menu=&message=&marker=&calendar=now&pressure=&type=map&location=coordinates&detail=&metricWind=default&metricTemp=default&radarRange=-1" frameborder="0"></iframe>' +
        '<br>';


    useEffect(() => {
        document.addEventListener('scroll', function (e) {
            const lastKnownScrollPosition = window.scrollY;
            const div1 = document.getElementById('postHeader');

            const c = (lastKnownScrollPosition - 200) * 100 / (document.getElementsByClassName('bottom-taboola')?.[0]?.offsetTop - window.innerHeight);
            if (document.getElementById('scrollwidth')) document.getElementById('scrollwidth').style.width = c + '%';

            if (lastKnownScrollPosition > 140) {
                if (div1) div1.classList.add('fix-title')
            } else {
                if (div1) div1.classList.remove('fix-title')
            }
        });
        return () => {
            document.removeEventListener('scroll');
        }
    }, []);

    return (
        <>
            <Helmet>
                <title>{innerData?.seo_title} | Sandesh</title>
                <meta name="title" content={innerData?.seo_title} />
                <meta name="description" content={innerData?.description} />
                <link rel="canonical" href={window.location.href} />

                <meta property="og:type" content="website" />
                <meta property="og:url" content={window.location.href} />
                <meta property="og:title" content={innerData?.seo_title} />
                <meta property="og:description" content={innerData?.description} />
                <meta property="og:image" content={`${assetbaseUrl?.value}${innerData?.media}`} />

                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content={window.location.href} />
                <meta property="twitter:title" content={innerData?.seo_title} />
                <meta property="twitter:description" content={innerData?.description} />
                <meta property="twitter:image" content={`${assetbaseUrl?.value}${innerData?.media}`} />
            </Helmet>
            <div className='container video_inner'>
                <div className="row my-3">
                    <div className="col-sm-6 col-md-6 col-lg-9 col-xl-9 gujarat-responsive-space-sm">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="story">
                                    <div id="postHeader" className='story-title'><span id='scrollwidth'></span>{innerData?.title}</div>
                                    <p className='video-inner-head-text mb-1' style={{ marginTop: '-8px', fontSize: '26px' }}>{innerData?.title}</p>
                                    {innerData?.byliner_status === "1" ? <p className="byline-text"> <Link className="text-capitalize text-secondary mr-1" to={`/${innerData?.category_url}`}> {innerData?.category_name}</Link>
                                        | {innerData?.byliner &&
                                            <span className="curser-pointer">By {innerData?.byliner} |  </span>
                                        }
                                        {innerData?.publish_date}</p> : ''}

                                    {/* <Dailymotion
                                        className="dailymotion-home-page mb-3"
                                        // video={`${innerData?.video}`}
                                        video={innerData?.video?.toString()?.split('/')[innerData?.video?.toString()?.split('/')?.length - 1]}
                                        showEndScreen={true}
                                        uiTheme="light"
                                        autoplay
                                        uiShowLogo="false"
                                        origin="યુએસ આરોગ્ય સંભાળ વહીવટી ખર્ચ પર વ્યક્તિ દીઠ $2,500 ખર્ચે છે"
                                    /> */}
                                    {/* <YouTube id={innerData?.id} className="dailymotion-videoPoPup-page" videoId={innerData?.video_yt} opts={opts} /> */}

                                    <div>
                                        {/* <iframe className='yt_clear' src={`https://www.youtube.com/embed/${innerData?.video_yt}?autoplay=1&mute=1`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="allowfullscreen"></iframe>
                                         */}
                                        {(window.location.pathname === "/gujarat/cyclone-biporjoy-threatened-over-gujarat-at-monsoon") ?
                                            <InjectScript script={windywidget} />
                                            :
                                            innerData?.video ?
                                                <><iframe loading="lazy" className='yt_clear gumlet_clear' src={`https://play.gumlet.io/embed/${innerData?.video}?autoplay=1&mute=1&vast_tag_url=https%3A%2F%2Fservx.playstream.media%2Fapi%2Fadserver%2Fvast3%2F%3FAV_PUBLISHERID%3D64a2c899d453167b2405d639%26AV_CHANNELID%3D6513e6dbda9d898c5703df35%26AV_URL%3D%5BURL_MACRO%5D%26cb%3D%5BTIMESTAMP_MACRO%5D%26AV_WIDTH%3D%5BWIDTH_MACRO%5D%26AV_HEIGHT%3D%5BHEIGHT_MACRO%5D`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="allowfullscreen" referrerpolicy="unsafe-url"></iframe></>
                                            : <><iframe loading="lazy" className='yt_clear' src={`https://www.youtube.com/embed/${innerData?.video_yt}?autoplay=1&mute=1`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="allowfullscreen"></iframe></>
                                            
                                        }
                                    </div>
                                    {/* <div className={`post-sharer article-${innerData?.id}`} ><InjectScript
                                        script={
                                            window.innerWidth > 991
                                                ? bannerAds
                                                : ''
                                        }
                                    />
                                    </div> */}



                                    {/* <hr /> */}
                                    <div className='post-content'>
                                        {/* <InjectScript script={innerData?.content?.replace('[[$ad]]', ArticleContent?.[0]?.script)} /> */}
                                        {/* {ArticleContent?.[0]?.script ? */}
                                            <InjectScript script={
                                                innerData?.content?.replaceAll('[[$ad]]', ArticleContent)
                                                    .replace('[[$googlead]]', window.innerWidth > 991 ? postWebBody : postMobileBody)
                                                    .replace('[[$alsoread]]', innerData?.alsoread)
                                            } />
                                            {/* :
                                            <InjectScript script={
                                                innerData?.content?.replaceAll('[[$ad]]', '')
                                                    .replace('[[$googlead]]', window.innerWidth > 991 ? postWebBody : postMobileBody)
                                                    .replace('[[$alsoread]]', innerData?.alsoread)
                                            } />
                                        } */}
                                    </div>
                                    {/* <div className='post-content' dangerouslySetInnerHTML={{ __html: innerData?.content }} /> */}

                                    {
                                        timeline?.length > 0 && timeline?.map((els, idx) => (
                                            <div className='live-blog'>
                                                <div className='text-left'>
                                                    <p className='live-blog-time'>{els.date}</p>
                                                    <div className='live-blog-title' dangerouslySetInnerHTML={{ __html: els?.message }}></div>
                                                </div>
                                            </div>
                                        ))
                                    }

                                    {/* <div className='post-sharer' dangerouslySetInnerHTML={{ __html: sharerMsg?.value }} /> */}
                                    <div className={`post-sharer article-${innerData?.id}`} ><InjectScript
                                        script={
                                            window.innerWidth > 991
                                                ? bannerAds
                                                : ''
                                        }
                                    />
                                    </div>
                                    <div className='d-flex justify-content-end social-post'>
                                        <p className='social-share-text'>Share With:</p>
                                        <ShareWith key={innerData?.id} dataid={innerData?.id} url={innerData?.url} title={innerData?.title} media={innerData?.media} />
                                        {/* <p className='social-icon'>
                                            <a aria-label="Facebook" href={`${'https://www.facebook.com/sharer/sharer.php?display=page&u=' + encodeURIComponent(window.location.href) + '&description=' + encodeURIComponent(document.title)}`} target="_blank" rel="noreferrer" ><i className="fab fa-facebook "></i></a>
                                        </p>
                                        <p className='social-icon'>
                                            <a aria-label="Twitter" href={`${'https://twitter.com/share?url=' + encodeURIComponent(window.location.href)}`} target="_blank" rel="noreferrer"><i className="fab fa-twitter"></i></a>
                                        </p>
                                        <p className='social-icon'>
                                            <a aria-label="Pinterest" href={`${'https://www.pinterest.com/pin/create/link/?url=' + encodeURIComponent(window.location.href) + `&media=${assetbaseUrl?.value}${innerData?.media}&description=` + encodeURIComponent(document.title)}`} target="_blank" rel="noreferrer"> <i className="fab fa-pinterest"></i></a>
                                        </p>
                                        <p className='social-icon'>
                                            <a href="javascript:void(0)" onClick={() => { navigator.clipboard.writeText(`https://sandesh.com/${innerData?.url}`) }} ><i className="fas fa-copy"></i></a>
                                        </p> */}
                                    </div>
                                    <hr className='m-0 mb-2' />
                                    {window.innerWidth < 991 ? <HelperScript argSmall={184} /> : ''}
                                    <div className="mb-3 mt-3 text-center">
                                        {/* <textarea placeholder='Write a comment' className="form-control" id="exampleFormControlTextarea1" rows="3"></textarea> */}
                                        {
                                            innerData?.comment_status === 1 &&
                                            <button onClick={() => { showPostComment() }} type="button" className="btn btn-color mt-3">Post a comment {comCount > 0 ? `(${comCount})` : null}</button>
                                        }
                                        {/* {innerData?.comment_status === 1 ? <button onClick={() => { showPostComment() }} type="button" className="btn btn-color mt-3">Post a comment ({innerData?.comment_count})</button> : '' }  */}
                                    </div>

                                    {/* <AdTwo /> */}

                                </div>

                                {/* <div className="single-blog mt-3">
                                    <span className="badge badge-warning">Related Articles</span>
                                </div> */}
                                {/* <div className="row related_articles mb-3">
                                    {
                                        innerDataRelated?.length > 0 && innerDataRelated?.map((els, idx) => (
                                            <>
                                                {innerData?.id !== els?.id ?
                                                    <div className="col-md-12 col-lg-4 col-xl-4 py-3">
                                                        <Link target="_parent" to={`/${els?.url}`}>
                                                            <div className="img-post">
                                                                <img loading="lazy" src={!els?.media ? sandeshske : `${assetbaseUrl?.value}${els?.media}`} alt="" style={{ width: '100%' }} />
                                                                <div className="content">
                                                                    <p className="m-0 ellipsis">{els?.title}</p>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    </div> : ""
                                                }
                                            </>
                                        ))
                                    }
                                </div> */}
                                {/* <AdTwo /> */}
                                <div className='bottom-taboola' key={window.location.href}>
                                    <InjectScript type="taboola" script={taboolarelatedcontent} key={window.location.href} />
                                </div>

                                {
                                    (window.innerWidth > 991) ?
                                        <div className='my-4 footer-taboola taboola' key={window.location.href}>
                                            <InjectScript type="taboola" script={taboolafootercontent} key={window.location.href} />
                                        </div>
                                        : ''
                                }
                            </div>
                        </div>
                    </div>

                    <div className="insta-head-responsive col-12 col-md-12 col-lg-3 col-xl-3">
                        <div className='ad-side-head '>
                            <div className="ad-side mt-0">
                                {/* {firstright?.[0]?.script &&  */}
                                <div className="ad-section">
                                   
                                    {/* {
                                                window.innerWidth < 991 ?
                                                    ''
                                                    :
                                                    <InjectScript script={cricketwidgetUnibot} />

                                            } */}
                                             {window.innerWidth > 991 ? <HelperScript argLarge={37} /> : ''}
                                    {/* <InjectScript script={firstright?.[0]?.script} /> */}
                                </div>
                                {/* } */}

                            </div>
                            {window.innerWidth > 991 ? <div className="shadow mt-3 p-x2 mb-2 bg-white rounded">
                                <div className="featured ">
                                    <a href="javascript:void(0)" className="title">Top 10 News  <i className="fas fa-chevron-right"></i></a>
                                    <div className="scroll s-story">
                                        {url.includes('celeb-duniya/') ?
                                            homeEntertainment?.length > 0 && homeEntertainment?.map((els, idx) => (
                                                <>
                                                    <Link target="_parent" to={`/${els?.url}`}>
                                                        <img loading="lazy" src={`${assetbaseUrl?.value}${els?.media}`} alt="" />
                                                        <div>
                                                            <p className='color-red'>{els?.category}</p>
                                                            <p className="font-size-video ellipsis">{els?.title}</p>
                                                        </div>
                                                    </Link>
                                                    <hr className='border-bt' />
                                                </>
                                            ))
                                            :
                                            homePrimary?.Trending?.length > 0 && homePrimary?.Trending?.map((els, idx) => (
                                                <>
                                                    <Link target="_parent" to={`/${els?.url}`}>
                                                        <img loading="lazy" src={`${assetbaseUrl?.value}${els?.media}`} alt="" />
                                                        <div>
                                                            <p className='color-red'>{els?.category}</p>
                                                            <p className="font-size-video ellipsis">{els?.title}</p>
                                                        </div>
                                                    </Link>
                                                    <hr className='border-bt' />
                                                </>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div> : ''}
                            <div className="ad-side mt-0">
                                {/* {secondright?.[0]?.script && */}
                                <div className="ad-section">
                       
                                    <HelperScript argSmall={185} argLarge={38} />
                                    {/* <InjectScript script={secondright?.[0]?.script} /> */}
                                </div>
                                {/* } */}
                            </div>
                            <div className="single-blog shared mt-3">
                                <div className="row px-3">
                                    <span className=" col badge badge-danger colortitle ">Most Viewed</span>
                                    {/* <span className="col badge badge-danger colortitle">Most Shared</span> */}
                                </div>
                                <div className="shadow text-center mb-3 bg-white rounded">
                                    <Link target="_parent" to={`/${mostviews?.url}`} className="main-blogs">
                                        <img loading="lazy" src={`${assetbaseUrl?.value}${mostviews?.media}`} className="img-responsive" />
                                        <span className="button-hover colorspan-botton">{mostviews?.tagline}</span>
                                        <div className="blog-details">
                                            <p className="ellipsis">{mostviews?.title}</p>
                                            <span className="blog-time"><i className="far fa-clock"></i>{mostviews?.publish_date}</span>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                            <div className="ad-side mt-2">
                                <div className="ad-section">
                                    <HelperScript argSmall={205} argLarge={100} />
                                </div>
                            </div>

                            <div className='my-4 sidebar-taboola' key={window.location.href}>
                                <InjectScript type="taboola" script={taboolacontent} key={window.location.href} />
                                <HelperScript argLarge={261} />
                            </div>
                        </div>
                    </div>
                </div>


                {
                    (window.innerWidth < 991) ?
                        <div className='my-4 footer-taboola taboola' key={window.location.href}>
                            <InjectScript type="taboola" script={taboolafootercontent} key={window.location.href} />
                        </div>
                        : ''
                }

            </div>
            {localStorage.getItem('access_token') ? (
                <>
                    {postCommentModalView && <Comment postCommentModalchk={showPostComment} postCommentcount={setCountCom} postId={innerData?.id} innerDataProps={innerData && innerData} />}
                </>
            ) : (
                <>
                    {postCommentModalView && <Login />}
                </>
            )}
        </>
    )
}

export default VideosInnerComponent
