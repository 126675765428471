/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from 'react'
import 'react-multi-carousel/lib/styles.css';
import LocationSelect from './LocationSelect';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { GujaratSamagraCityFetchData } from '../../redux/actions/gujaratSamagraCityAction';
import { CityResultFetchData } from '../../redux/actions/cityResult';
import sandeshske from '../../assets/sandeshske.png';
import Share from '../component/Share';
import HttpService from '../../utils/httpService';
import { Get } from '../../services/api.service';
import ReactGA from "react-ga4";
import CommonContent from '../CommonContent'
import CommonHeadCardContent from '../CommonHeadCardContent';
import InjectScript from '../CustomScript';


const afterPrimary = "" +
    // '<script async src="https://cdn.unibotscdn.com/ubplayer/player.js"></script>' +
    // '<div id="div-ub-sandesh_home">' +
    // '<script>' +
    // 'window.unibots = window.unibots || { cmd: [] };' +
    // 'unibots.cmd.push(()=>{ unibotsPlayer("sandesh_home"); });' +
    // '</script>' +
    // '</div>' +
    ""

const Gujarat = () => {
    const { 21: assetbaseUrl, 23: defaultActiveSamagraTab } = useSelector((state) => state?.SettingReducer?.data);
    const Gujarat = useSelector((state) => state?.HomeSecondaryReducer?.data?.Gujarat);
    const GujaratMetro = useSelector((state) => state?.HomeSecondaryReducer?.data?.Gujaratmetro);
    const { data: samagraAPIData } = useSelector((state) => state?.CityResultReducer?.data);
    let dispatch = useDispatch();
    let navigate = useNavigate();


    React.useEffect(() => {
        dispatch(GujaratSamagraCityFetchData())
    }, [])

    const [icon, seticon] = useState();
    const [activeTab, setActiveTab] = useState('Metro');
    const [myLocation, setmyLocation] = useState(localStorage.getItem('SandeshLocation'));
    const [activeTabSam, setActiveTabSam] = useState('samagra');
    const [apiData, setapiData] = useState([]);
    const [activeCity, setActiveCity] = useState(JSON.parse((localStorage.getItem('activeCity') ? localStorage.getItem('activeCity') : '[{"name":"Other Cities","url":"samagra"}]')));
    // useEffect(() => {
    //     if (localStorage.getItem('activeCity')) {
    //         const activeCitiesName =  JSON.parse(localStorage.getItem('activeCity')) || []
    //          setActiveTabSam(activeCitiesName);
    //     }
    // }, []);
    useEffect(() => {
        if (!activeTabSam && defaultActiveSamagraTab?.value) {
            setActiveTabSam(defaultActiveSamagraTab?.value);
        }
    }, [defaultActiveSamagraTab?.value, activeTabSam]);

    useEffect(() => {
        const timer = setTimeout(() => {
            if (activeTabSam) {
                let customLocation = '';
                if (myLocation) {
                    const parseData = JSON.parse(myLocation);
                    customLocation = '?lat=' + parseData.lat + '&long=' + parseData.long;
                }
                dispatch(CityResultFetchData(activeTabSam + customLocation))
            }
        }, 300);
        return () => {
            clearTimeout(timer);
        };
    }, [activeTabSam, myLocation]);

    useEffect(() => {
        const timerLocation = setInterval(() => {
            if (myLocation !== localStorage.getItem('SandeshLocation')) {
                setmyLocation(localStorage.getItem('SandeshLocation'));
            }
        }, 500);
        return () => {
            clearInterval(timerLocation);
        }
    }, []);

    const visibleCityHandler = (citiesURL) => {
        citiesURL.push({ "name": "Other Cities", "url": "samagra" });
        citiesURL.reverse();
        localStorage.setItem('activeCity', JSON.stringify(citiesURL));
        setActiveCity(citiesURL)
        setActiveTabSam(citiesURL?.[0]?.url)
        dispatch(CityResultFetchData(citiesURL?.[0]?.url))
    }

    const onShareClick = (e) => {
        document.getElementById('metro-dropdown').classList.remove('d-none');
        e.preventDefault();
        e.stopPropagation();
        return false;
    }

    const onShareClickMet = (e) => {
        document.getElementById('metro-dropdown-inner').classList.remove('d-none');
        e.stopPropagation();
        e.preventDefault();
        return false;
    }

    const onShareClickSam = (e) => {
        document.getElementById('samagra-dropdown').classList.remove('d-none');
        e.preventDefault();
        e.stopPropagation();
        return false;
    }

    const leftScroll = (event) => {
        const conent = document.querySelector('#content-sam-home');
        conent.scrollLeft -= 100;
        event.preventDefault();
    }

    const rightScroll = (event) => {
        const conent = document.querySelector('#content-sam-home');
        conent.scrollLeft += 100;
        event.preventDefault();
    }
    // useEffect(() => {
    //     if (localStorage.getItem('activeCity')) {
    //         const activeCitiesName = JSON.parse(localStorage.getItem('activeCity')) || []
    //         setActiveCity(activeCitiesName);
    //     }
    // }, []);
    useEffect(() => {
        async function fetchData() {
            const myMetroData = await Get(HttpService.metro);
            // console.log('asdasdasdasd myMetroData', Object.entries(myMetroData?.data));
            setapiData(Object.entries(myMetroData?.data));
        };
        fetchData();
    }, []);
    useEffect(() => {
        const asd = document.getElementById('content-sam-home').scrollWidth
        seticon(asd);
        // console.log('asd', asd);
    })

    return (
        <>
            <section className="gujarat bg-gray samgraMetro">
                <div className="container">
                    <div className='text-center'><InjectScript script={afterPrimary} key={Math.random()} />
                    </div>
                    <div className="row">
                        <div className="col-md-12 col-lg-12 col-xl-12">
                            <div className="row">
                                <div className="col-md-6 col-lg-6 col-xl-6 mobile-margin-bottom removePaddingHover removePaddingLeft">
                                    <div className="single-blog" onClickCapture={() => ReactGA.event({ category: 'Homepage', action: 'Gujarat Metro', label: 'Read More' })}>
                                        <Link target="_parent" to={'/gujarat'} >
                                            <span className="badge badge-warning">
                                                Gujarat Metro
                                            </span>
                                        </Link>
                                        <Link target="_parent" to={'/gujarat/metro'} className="readmore">Read More</Link>
                                    </div>

                                    {/* <ul className="center" id="content">
                                                {console.log('asdasdasdasd', apiData.length)}
                                                {console.log('asdasdasdasd 1', (typeof apiData.length === "undefined"))}
                                            {(apiData.length > 0) && apiData?.map((tl, index) => {
                                                {console.log('asdasdasdasd', index)}
                                                return (<li className="nav-item internal" style={{ width: 'auto' }} key={index}>
                                                <a className={`nav-link guj-metro-carousel-tabs ${activeTab === 'Metro' ? 'active-guj-metro-carousel' : ''}`} data-toggle="tab" onClick={() => setActiveTab(() => 'Metro')}>Metro</a>
                                                </li>)
                                            })}
                                        </ul> */}
                                    <div className='d-flex align-items-center guj-metro-carousel'>
                                        <ul className=" d-flex center-metro">
                                            <div className="nav-item" onClickCapture={() => ReactGA.event({ category: 'Homepage', action: 'Gujarat Metro', label: 'Metro' })}>
                                                <a className={`nav-link guj-metro-carousel-tabs ${activeTab === 'Metro' ? 'active-guj-metro-carousel' : ''}`} data-toggle="tab" onClick={() => setActiveTab(() => 'Metro')}>Metro</a>
                                            </div>
                                            {apiData?.map((els, index) => {
                                                return (<li className="nav-item internal" style={{ width: 'auto' }} key={index} onClickCapture={() => ReactGA.event({ category: 'Homepage', action: 'Gujarat Metro', label: els[1]?.name })}>
                                                    <a className={`nav-link guj-metro-carousel-tabs ${activeTab === els[1]?.name ? 'active-guj-metro-carousel' : ''}`} data-toggle="tab" onClick={() => setActiveTab(() => els[1]?.name)}>{els[1]?.name}</a>
                                                </li>)
                                            })}
                                        </ul>
                                    </div>
                                    {/* 
                                    <Carousel
                                        swipeable={true}
                                        key={Math.random()}
                                        draggable={true}
                                        infinite={false}
                                        showDots={false}
                                        responsive={responsive}
                                        removeArrowOnDeviceType={["tablet", "mobile"]}
                                        className="guj-metro-carousel"
                                    >
                                        <div className="nav-item">
                                            <a className={`nav-link guj-metro-carousel-tabs ${activeTab === 'Metro' ? 'active-guj-metro-carousel' : ''}`} data-toggle="tab" onClick={() => setActiveTab(() => 'Metro')}>Metro</a>
                                        </div>
                                        {
                                            (apiData?.length > 0) && apiData?.map((tl) => (
                                                <div className="nav-item">
                                                    <a className={`nav-link guj-metro-carousel-tabs ${activeTab === tl[1]?.name ? 'active-guj-metro-carousel' : ''}`} data-toggle="tab" onClick={() => setActiveTab(() => tl[1]?.name)}>{tl[1]?.name}</a>
                                                </div>
                                            ))
                                        }
                                        {/* <div className="nav-item">
                                            <a className={`nav-link guj-metro-carousel-tabs ${activeTab === 'Ahmedabad' ? 'active-guj-metro-carousel' : ''}`} data-toggle="tab" onClick={() => setActiveTab(() => 'Ahmedabad')}>Ahmedabad</a>
                                        </div>
                                        <div className="nav-item">
                                            <a className={`nav-link guj-metro-carousel-tabs ${activeTab === 'Vadodara' ? 'active-guj-metro-carousel' : ''}`} data-toggle="tab" onClick={() => setActiveTab(() => 'Vadodara')}>Vadodara</a>
                                        </div>
                                        <div className="nav-item">
                                            <a className={`nav-link guj-metro-carousel-tabs ${activeTab === 'Surat' ? 'active-guj-metro-carousel' : ''}`} data-toggle="tab" onClick={() => setActiveTab(() => 'Surat')}>Surat</a>
                                        </div>
                                        <div className="nav-item">
                                            <a className={`nav-link guj-metro-carousel-tabs ${activeTab === 'Rajkot' ? 'active-guj-metro-carousel' : ''}`} data-toggle="tab" onClick={() => setActiveTab(() => 'Rajkot')}>Rajkot</a>
                                        </div>
                                        <div className="nav-item">
                                            <a className={`nav-link guj-metro-carousel-tabs ${activeTab === 'Bhavnagar' ? 'active-guj-metro-carousel' : ''}`} data-toggle="tab" onClick={() => setActiveTab(() => 'Bhavnagar')}>Bhavnagar</a>
                                        </div>
                                        <div className="nav-item">
                                            <a className={`nav-link guj-metro-carousel-tabs ${activeTab === 'Bhuj' ? 'active-guj-metro-carousel' : ''}`} data-toggle="tab" onClick={() => setActiveTab(() => 'Bhuj')}>Bhuj</a>
                                        </div> 
                                    </Carousel> */}

                                    {
                                        (GujaratMetro && GujaratMetro.length > 0) && GujaratMetro?.map((el) => (<>
                                            {(activeTab === el?.name && el?.data?.length > 0) ? el?.data?.map((ele, idx) => (
                                                <>
                                                    {idx === 0 && (
                                                        <CommonHeadCardContent dataClass="margin-bottom-guj" url={ele?.url} sandeshske={sandeshske} media={ele?.media} mediaimg={`${assetbaseUrl?.value}${ele?.media}`} post_type={ele?.post_type} time={ele?.time} title={ele?.title} id={ele?.id} publish_date={ele?.publish_date} />

                                                    )}
                                                    {(idx > 0 && idx < 4) && (
                                                        <CommonContent url={ele?.url} src={`${assetbaseUrl?.value}${ele?.media}`} post_type={ele?.post_type} time={ele?.time} publish_date={ele?.publish_date} category={ele?.tagline} title={ele?.title} />

                                                        // <div className='row col-12 p-0 m-0'>
                                                        //     <Link target="_parent" to={`/${ele?.url}`} className="related-blogs w-100">
                                                        //         <p className='img-16-9-blogs'>
                                                        //             <div className='col padding-mobile'>
                                                        //                 <img loading="lazy" src={!ele?.media ? sandeshske : `${assetbaseUrl?.value}${ele?.media}`} alt="" className="img-responsive" />
                                                        //                 {ele?.post_type === 3 || ele?.post_type === 4 ? <p className="button-hover timer_ subvideo-tagline">{ele?.time}</p> : ""}
                                                        //                 <p className="blog-time mt-1 m-0"><i className="far fa-clock mr-2"></i>{ele?.publish_date}</p>
                                                        //             </div>
                                                        //         </p>
                                                        //         <div className='col-9 p-0'>
                                                        //             <div className="blog-details">
                                                        //                 <span><b className="color-red">{ele?.tagline}</b></span>
                                                        //                 <p className="ellipsis">{ele?.title}</p>
                                                        //             </div>
                                                        //         </div>
                                                        //     </Link>
                                                        // </div>
                                                    )}
                                                </>
                                            )) : ''}
                                        </>
                                        ))
                                    }
                                    {Gujarat === undefined ? '' : Gujarat.length > 0 && Gujarat?.map((el, idx) => (
                                        <>
                                            {(activeTab === '' && idx === 0) && (
                                                <CommonHeadCardContent dataClass="margin-bottom-guj" url={el?.url} sandeshske={sandeshske} media={el?.media} mediaimg={`${assetbaseUrl?.value}${el?.media}`} post_type={el?.post_type} time={el?.time} title={el?.title} id={el?.id} publish_date={el?.publish_date} />
                                            )}

                                            {(activeTab === '' && idx > 0 && idx < 4) && (
                                                <CommonContent url={el?.url} src={`${assetbaseUrl?.value}${el?.media}`} post_type={el?.post_type} time={el?.time} publish_date={el?.publish_date} category={el?.tagline} title={el?.title} />

                                                //    <div className='row col-12 p-0 m-0'>
                                                //         <Link target="_parent" to={`/${el?.url}`} className="related-blogs w-100">
                                                //             <p className='img-16-9-blogs'>
                                                //                 <div className='col padding-mobile'>
                                                //                     <img loading="lazy" src={!el?.media ? sandeshske : `${assetbaseUrl?.value}${el?.media}`} alt="" className="img-responsive" />
                                                //                     {el?.post_type === 3 || el?.post_type === 4 ? <p className="button-hover timer_ subvideo-tagline">{el?.time}</p> : ""}
                                                //                     <p className="blog-time mt-1 m-0"><i className="far fa-clock mr-2"></i>{el?.publish_date}</p>
                                                //                 </div>
                                                //             </p>
                                                //             <div className='col-9 p-0'>

                                                //                 <div className="blog-details">
                                                //                     <span><b className="color-red">{el?.tagline}</b></span>
                                                //                     <p className="ellipsis">{el?.title}</p>
                                                //                 </div>
                                                //             </div>
                                                //         </Link>
                                                //     </div>
                                            )}
                                        </>
                                    ))}
                                </div>

                                <div className="col-md-6 col-lg-6 col-xl-6 removePaddingHover">
                                    <div className="single-blog" onClickCapture={() => ReactGA.event({ category: 'Homepage', action: 'Gujarat Samagra', label: 'Read More' })}>
                                        <Link target="_parent" to={'/gujarat'} >
                                            <span className="badge badge-warning">
                                                Khabar Gujarat
                                            </span>
                                        </Link>
                                        {/* <span className="badge badge-warning">Gujarat Samagra</span> */}
                                        <Link target="_parent" to={'/gujarat/samagra'} className="readmore">Read More</Link>
                                    </div>
                                    <div className='d-flex align-items-center'>
                                        <a className="nav-link tab-bar-padding" data-toggle="tab" href="#food1"> <LocationSelect visibleCity={visibleCityHandler} /></a>
                                        {icon > 611 ? <div className="float-left">
                                            <button className="buttonGujSamagra" onClick={() => leftScroll()} ><i className="fa fa-angle-left" aria-hidden="true" /></button>
                                        </div> : ''}
                                        <ul className="center asd" id="content-sam-home">
                                            {activeCity?.map((els, index) => {
                                                return (<li className="nav-item internal" style={{ width: 'auto' }} key={index} onClickCapture={() => ReactGA.event({ category: 'Homepage', action: 'Gujarat Samagra', label: els?.name })}>
                                                    <a className={`nav-link ${activeTabSam === els?.url ? 'active-guj-samagra w-100' : ''}`} data-toggle="tab" onClick={() => setActiveTabSam(els?.url)} href={`#${els?.name}`}>{els?.name}</a>
                                                </li>)
                                            })}
                                        </ul>
                                        {icon > 611 ? <div className="float-right">
                                            <button className="buttonGujSamagra" onClick={() => rightScroll()}><i className="fa fa-angle-right" aria-hidden="true" /></button>
                                        </div> : ''}
                                    </div>
                                    {
                                        (samagraAPIData && samagraAPIData.length > 0) && samagraAPIData?.map((ele, idx) => (<>
                                            {/* {(activeTabSam === el?.name && el?.data?.length > 0) ? el?.data?.map((ele, idx) => ( */}
                                            <>
                                                {idx === 0 && (
                                                    <CommonHeadCardContent dataClass="margin-bottom-guj" url={ele?.url} sandeshske={sandeshske} media={ele?.media} mediaimg={`${assetbaseUrl?.value}${ele?.media}`} post_type={ele?.post_type} time={ele?.time} title={ele?.title} id={ele?.id} publish_date={ele?.publish_date} />
                                                )}
                                                {(idx > 0 && idx < 4) && (
                                                    <CommonContent url={ele?.url} src={`${assetbaseUrl?.value}${ele?.media}`} post_type={ele?.post_type} time={ele?.time} publish_date={ele?.publish_date} category={ele?.tagline} title={ele?.title} />

                                                    // <div className='row col-12 p-0 m-0'>
                                                    //     <Link target="_parent" to={`/${ele?.url}`} className="related-blogs w-100">
                                                    //         <p className='img-16-9-blogs'>
                                                    //             <div className='col padding-mobile'>
                                                    //                 <img loading="lazy" src={!ele?.media ? sandeshske : `${assetbaseUrl?.value}${ele?.media}`} className="img-responsive" />
                                                    //                 {ele?.post_type === 3 || ele?.post_type === 4 ? <p className="button-hover timer_ subvideo-tagline">{ele?.time}</p> : ""}
                                                    //                 <p className="blog-time mt-1 m-0"><i className="far fa-clock mr-2"></i>{ele?.publish_date}</p>
                                                    //             </div>
                                                    //         </p>
                                                    //         <div className='col-8 p-0'>

                                                    //             <div className="blog-details">
                                                    //                 <span><b className="color-red">{ele?.tagline}</b></span>
                                                    //                 <p className="ellipsis">{ele?.title}</p>
                                                    //             </div>
                                                    //         </div>
                                                    //     </Link>
                                                    // </div>
                                                )}

                                            </>
                                            {/* // )) : ''} */}
                                        </>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Gujarat