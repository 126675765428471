import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

export default function configureStore(initialState) {

    const composeEnhancers =
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
            window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
                // options like actionSanitizer, stateSanitizer
            }) : compose;

    const enhancer = composeEnhancers(
        applyMiddleware(thunk)
    );

    // const enhancer = applyMiddleware(thunk)
    const persistConfig = {
        key: 'root:sandesh',
        storage,
        blacklist: ["LogingReducer", "PostReducer", "CategoryReducer", "EPaperCategory"],
    }
    const persistedReducer = persistReducer(persistConfig, rootReducer)

    // return createStore(
    //     persistedReducer,
    //     initialState,
    //     enhancer
    // );

    let store = createStore(persistedReducer, initialState, enhancer);
    let persistor = persistStore(store);
    return { store, persistor }
}
