import { LOGIN_CITY, MARQUEE_FETCH_DATA_SUCCESS, MARQUEE_HAVE_ERROR, MARQUEE_ARE_LOADING } from '../actionType';
import HttpService from '../../utils/httpService';
import { Get } from '../../services/api.service'

export function marqueeAction(action, payload) {
    switch (action) {
        case MARQUEE_HAVE_ERROR:
            return {
                type: action,
                hasError: payload
            };

        case MARQUEE_ARE_LOADING:
            return {
                type: action,
                isLoading: payload
            };

        case MARQUEE_FETCH_DATA_SUCCESS:
            return {
                type: action,
                marquee: payload,
            };

        case LOGIN_CITY:
            return {
                type: action,
                city: payload,
            };

        default:
            return;
    }
}

export function marqueeFetchData() {
    return (dispatch) => {
        try {
            return new Promise((resolve, reject) => {
                dispatch(marqueeAction(MARQUEE_ARE_LOADING, true));
                Get(HttpService.country_v2).then(result => dispatch(marqueeAction(LOGIN_CITY, result.data)));
                Get(HttpService.marquee).then((result) => {
                    dispatch(marqueeAction(MARQUEE_FETCH_DATA_SUCCESS, result.data));
                    resolve(result);
                }, (error) => {
                    reject(error);
                });
            });
        } catch (error) {
            dispatch(marqueeAction(MARQUEE_HAVE_ERROR, true));
        }
    }
}
